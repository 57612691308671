import React from 'react';
import ReactDOM from 'react-dom';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import B9App from './B9App';
import * as serviceWorker from './serviceWorker';

import store from "./store";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../src/services/authProvider";
import { MsalProvider } from "@azure/msal-react"


export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <React.StrictMode>
        {(window.screen.height >= '1080' && (window.screen.width >= '5760' || window.screen.width >= '3840'))  ? <B9App /> : <App />}
        {/* {(window.screen.height >= '1080' && window.screen.width >= '5760') ? <B9App /> : <App />} */}
      </React.StrictMode>
    </Provider>
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
