export const BASE_URL = window.msalConfig.apiurl + "/api/V1/";
export const clientId = "efe8ae24-b9ef-4683-8de6-a25219ab5c5a";
export const tenantId = "e0793d39-0939-496d-b129-198edd916feb";
export const scopes = ['8b3b5348-9a76-465b-978f-fa03e6ba64b9/hub_access.read']; 
export const pbiScopes = ['https://analysis.windows.net/powerbi/api/Report.Read.All'];
export const SVM_URL = "https://synopsvaluemeter.accenture.com/api/"

export const REDIRECT_URL=window.msalConfig.appurl;

export const MAC_ID = "89D56DCC-1F98-11B2-A85C-D6C1758750DD";
export const USER = "User";
export const ADMIN = "Admin";
export const ENVIRONMENT = "uat";
export const ENTERPRISE_ID = "tejinder.a.singh"
export const APP_VERSION="Version 5.6.0";
