import React from "react";
import { Modal } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from "react-redux";
import { toggleMenuDisplay, setFilters } from "../../actions/AppActions";
import { ENVIRONMENT } from "../../StringConstants";
import i18n from "i18next";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { confirmAlert } from 'react-confirm-alert';
import Connecting from "../../Connecting";
import ReactTooltip from "react-tooltip";
import { iwGphExclude, gph4Exclude, allGphExclude, gph4Widgets, iwGPHwidgets, tenantIdDashboards, drillToDashWithParentFilter, tenantIdWithClient } from "../../config/SisenseDashboards"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../Images/Connecting.PNG";
import { buttonFilters } from "./FilterForReconHR";


class IntOps extends React.Component {
  state = {
    showDefault: true,
    tabParentId: 0,
    mainTabs: [],
    subTabs: [],
    allSubTabs: [],
    urlButtons: [],
    activeSubTab: "",
    activeSubTabId:null,
    activeUrlButtons: [],
    currentDashboardUrl: "",
    showModal: false,
    sisenseFrame: "",
    filterArray: [],
    filterArrayGPH: [],
    sisenseStyle: [],
    sisenseFrames: [],
    isRendered: false,
    applyFiltersLater: false,
    filterApplied: false,
    showLoader: false,
    dbList: [],
    showDashModal: false,
    preLoadfoundationOverview: null,
    preLoadAutomatedOverview: null,
    preLoadInsightOverview: null,
    preLoadIntelligentOverview: null,
    showSisenseModal: false,
    selectedURL: "",
    showDashFilt: false,
    filterText: "«",
    showDashFiltModal: false,
    filterTextModal: "«",
    isSisenseSubtab: false,
    // powerBiUrl: "",
    // powerBiUrl2: "",
    showPowerBi: false,
    powerBiEmbedUrl: "",
    powerBiReportId: "",
    powerBiFilterObj: {},
    showPowerBiModal: false,
    powerBiEmbedUrlModal: "",
    powerBiReportIdModal: "",
    showPowerBiWithoutToken: false,
    powerBidirectUrl: "",
    showPowerBiWithoutTokenBtn: false,
    powerBidirectUrlBtn: "",
    maximizeIOJDashboard: false,
    maximizeIOJPopup: false,
    gphVersion: null,
    filterArrayTenantId: [],
    filterArrayTenantIdAndClient: [],
    visibilityTT: "hidden",
    showReconFilter: false,
    showHRManagerFilter: false,
    deploymentType: buttonFilters[0].data[0].value,
    outputVariance: buttonFilters[1].data[0].value,
    showMaskedUnmasked: false,
    clientType: [
      // {label:"Client ID", value:"tenantId"},
      { label: "Pseudo", value: "pseudo" }, { label: "Client", value: "client" }],
    selectedClientType: "client",
    clientPseudoClientidList: [],
    clientNamesList: [],
    pseudoNamesList: [],
    tenantIdsList: [],
    seletedClientName: "all",
    selectedPseudoName: "all",
    selectedTenantId: "all",
    activeSubTabDashObj: {
      activeDahObj: null,
      activeWidgets: null,
      activeDivId: null
    },
    activeButtonDashObj: {
      activeDahObj: null,
      activeWidgets: null,
      activeDivId: null
    },
    activeDrillDashObj: {
      activeDahObj: null,
      activeWidgets: null,
      activeDivId: null
    },
    defaultSubTab: 0,
    bankWgt :["66daf010173ed70033832d77","66daf010173ed70033832d7f", "66daf010173ed70033832d80"],
    subTabOpriaUrl:"", //URL to use after connecting to sisense
    subTabDashId:"",  //DashId to load after connecting to sisense
    buttonOpriaUrl:"", //Url for sisense popup after sisense connection
    parentFilters:[]
  };
  handleMaxMin = () => {
    this.setState({ maximizeIOJDashboard: !this.state.maximizeIOJDashboard }, () => {
      if (this.state.showDashModal) {
        if(this.state.activeDrillDashObj.activeDahObj){
          this.loadWgt(this.state.activeDrillDashObj.activeDahObj, this.state.activeDrillDashObj.activeWidgets, this.state.activeDrillDashObj.activeDivId);
        }
      } else if (this.state.showSisenseModal) {
        if(this.state.activeButtonDashObj.activeDahObj){
          this.loadWgt(this.state.activeButtonDashObj.activeDahObj, this.state.activeButtonDashObj.activeWidgets, this.state.activeButtonDashObj.activeDivId);
        }
        } else if (this.state.isSisenseSubtab) {
        if(this.state.activeSubTabDashObj.activeDahObj){
          this.loadWgt(this.state.activeSubTabDashObj.activeDahObj, this.state.activeSubTabDashObj.activeWidgets, this.state.activeSubTabDashObj.activeDivId);
        }
        }
    })
  }
  handleMaxMinPopup = () => {
    this.setState({ maximizeIOJPopup: !this.state.maximizeIOJPopup }, () => {
      if (this.state.showDashModal) {
        if(this.state.activeDrillDashObj.activeDahObj){
        this.loadWgt(this.state.activeDrillDashObj.activeDahObj, this.state.activeDrillDashObj.activeWidgets, this.state.activeDrillDashObj.activeDivId);
        }
      } else if (this.state.showSisenseModal) {
        if(this.state.activeButtonDashObj.activeDahObj){
        this.loadWgt(this.state.activeButtonDashObj.activeDahObj, this.state.activeButtonDashObj.activeWidgets, this.state.activeButtonDashObj.activeDivId);
        }
      } else if (this.state.isSisenseSubtab) {
        if(this.state.activeSubTabDashObj.activeDahObj){
        this.loadWgt(this.state.activeSubTabDashObj.activeDahObj, this.state.activeSubTabDashObj.activeWidgets, this.state.activeSubTabDashObj.activeDivId);
        }
      }
    })
  }
  handleFilterChange =(db)=>{
    this.setState({parentFilters:db.filters.$$items})
  }
  preLoadDash = (url) => {
    //Get dashbord IDs
    this.setState({ showLoader: true });
    if (url) {
      this.props.currentApp.dashboards
        .load(url)
        .then((dash) => this.afterLoadDash(dash, url, url));
    }

  };
  preLoadProcess = (oDash) => {
    oDash.widgets.$$widgets.forEach((oWgt) => {
      const div = document.getElementById(oWgt.id);
      oWgt.refresh();
      const recurse = () => {
        if (oWgt.refreshing)
          setTimeout(recurse, 1000);
        else {
          if (oWgt.queryResult) {
            let wTxt;
            if (oWgt.queryResult.length) {
              wTxt = oWgt.queryResult[0][0].Value;
            }
            else {
              wTxt = oWgt.queryResult.value ? oWgt.queryResult.value.text : "";
            }

            switch (oDash.id) {
              case "606c97d5ecbae9002d951b71": //Foundation-Overview
                // if (oDash.$$filters.$$filters[0].$$model.levels[1].filter.all) { //Pseudo client filter
                if (oDash.$$filters.$$filters[1].$$model.jaql.filter.all) {  //tenant id filter
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) > 6)
                    div.parentElement.parentElement.style.display = "flex";
                }
                else {
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) > 6)
                    div.parentElement.parentElement.style.display = "none";
                }
                break;
              case "5f7c12fd3965112230dbf2cd"://5f6e3bf6575c7c247c68f045 //automated overview
                if (wTxt === "0") {
                  div.parentElement.parentElement.style.display = "none";
                }
                break;
              case "5f92c8f85fd2f53f38600333": //Intelligent Overview
                if (wTxt === "0") {
                  div.parentElement.parentElement.style.display = "none";
                }
                break
              default: break;
            }
          }
          if (oWgt.$$model.options.drillTarget) {
            const drillDB = oWgt.$$model.options.drillTarget.oid ? oWgt.$$model.options.drillTarget.oid : oWgt.$$model.options.drillTarget
            if (drillDB !== "dummy") {
              div.parentElement.onclick = () => this.handleModal(drillDB);
              div.parentElement.style.cursor = "pointer";
            }
          }
          setTimeout(() => { if (div.style.height === "auto") div.style.height = "100%"; }, 2000)

        }
      }
      recurse();

    })
  }
  loadDash = (divId, url, props, dbType) => {
    //main dashboard
    if (divId != 'drillDash') {
      //Get dashbord IDs
      if(dbType == "subtab"){
        this.setState({ showLoader: true, subTabDashId:"", subTabOpriaUrl:""}); //clear url
      }else if(dbType == "button"){
        this.setState({ showLoader: true, buttonOpriaUrl:"" }); //clear urls
      }else{
        this.setState({ showLoader: true }); //clear urls
      }
      if (url) {
        let dashboardId = url.split("/");
        let dashId = dashboardId[dashboardId.length - 1];
        if (divId == "sisenseModal" && url.includes('filter=')) {
          props.currentApp.dashboards
            .load(dashboardId[dashboardId.length - 1])
            .then((dash) => this.afterLoadDash(dash, divId, url));
        } else {
          props.currentApp.dashboards
            .load(dashboardId[dashboardId.length - 1])
            .then((dash) => this.afterLoadDash(dash, divId, dashId));
        }

      }
    } else {
      //detail dashboard(widget modal)
      props.currentApp.dashboards
        .load(url)
        .then((dash) => this.afterLoadDash(dash, divId, url));
    }

  };


  afterLoadDash = (db, divId, dashId) => {
    let clients;
    let tenantIds;
    if (this.props.app.filterObject.tenant.length > 0) {
      clients = [...this.props.app.filterObject.tenant];
      tenantIds = [...this.props.app.filterObject.tenantId]
    } else {
      let tenants = [];
      let ids = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
        ids.push(item.tenantID);
      });
      clients = [...tenants];
      tenantIds = [...ids]
    }
    if(divId != 'drillDash'){
      db.on("filterschanged",this.handleFilterChange);
      this.setState({parentFilters:db.filters.$$items});
    if (divId != 'sisenseModal') {
      document.getElementById('filter' + divId).innerHTML = "";
      let oldFilterPencilList = document.getElementsByClassName("ew-i-fx ew-i-act btn--dark f-act");
      if (oldFilterPencilList.length > 0) {
        for (let p = 0; p < oldFilterPencilList.length; p++) {
          oldFilterPencilList[p].innerHTML = "";
        }
      }
      db.renderFilters(document.getElementById('filter' + divId));
    } else {
      document.getElementById('SisenseModalFilter').innerHTML = "";
      db.renderFilters(document.getElementById('SisenseModalFilter'));
    }
    } else {
      document.getElementById('Drill2ModalFilter').innerHTML = "";
      db.renderFilters(document.getElementById('Drill2ModalFilter'));
    }
    //check for preload dashboards
    switch (db.id) {
      //Foundation overview
      case '606c97d5ecbae9002d951b71':
        this.setState({ preLoadfoundationOverview: db });
        break;
      //Automated overview
      case '5f7c12fd3965112230dbf2cd':
        this.setState({ preLoadAutomatedOverview: db });
        break;
      //Insight overview
      case '5f702a953965112230dbd29d':
        this.setState({ preLoadInsightOverview: db });
        break;
      //Intelligent overview
      case '5f92c8f85fd2f53f38600333':
        this.setState({ preLoadIntelligentOverview: db });
        break;
      default:
        break;
    }
    // if (dashId == '5f19722e48414a2300b179c7' || dashId == '5f37ba1d4ad12e24f8afb127' || dashId == '5f17e79000e5352f388da2d2' || dashId == '5f702a953965112230dbd29d' || dashId == '5fa1714c4be9354e24e4327c' || dashId == '5edde53a1b0bea07501ae340' || dashId == '6288a2097ba9ce00366023c4' || dashId == 'drillDash') { // dashId == '5f17f33100e5352f388da331'  --Need to add this ID post Sisense localization testing
    if (dashId == '6288a2097ba9ce00366023c4' || divId == 'drillDash'  || dashId == "5fb8ddabbc46fb0af4f8eadf" || dashId == '5edde53a1b0bea07501ae340' ||dashId == '5fa1714c4be9354e24e4327c' ) { // dashId == '5f17f33100e5352f388da331'  --Need to add this ID post Sisense localization testing
      let value = drillToDashWithParentFilter.some(element => {
        return dashId.includes(element.dashboardId);
      })
      if (value) {
        this.applyDbFilters(db, dashId);
      }
    } else {
      if (divId == "sisenseModal" && dashId.includes('filter=')) {
        let jaqlArray = dashId.split("filter=");
        this.applyDbFiltersFixed(db, dashId, jaqlArray[1]);
      } else {
        this.applyDbFilters(db, dashId);
      }
    }
    let widgets = [];
    const layout = db.$$model.layout.columns[0].cells;
    const wRoot = db.$$model.title.startsWith("Drill") ? document.getElementById('dbModal') : document.getElementById(divId)
    if (wRoot) wRoot.innerHTML = "";
    let i = 0;
    layout.forEach(col => {
      i++;
      const row = this.createRow(i);
      col.subcells.forEach(cell => {
        const width = this.state.bankWgt.includes(cell.elements[0].widgetid)? 50:(cell.width ? cell.width : 100);
        const hidden = cell.elements[0].widgetid === "66daf010173ed70033832d80" ? true : false;
        row.appendChild(this.createLayout(i, cell.elements[0], width, dashId, hidden));
        if (this.state.gphVersion == "gph4.0") {
          let value = iwGPHwidgets.some(element => {
            return cell.elements[0].widgetid.includes(element.dashboardId);
          })
          if (!value) {
            widgets.push(cell.elements[0].widgetid);
          }
        } else if ((this.state.gphVersion == 'iwgph' || this.state.gphVersion == null) && clients.length > 0) {
          let value = gph4Widgets.some(element => {
            return cell.elements[0].widgetid.includes(element.dashboardId);
          })
          if (!value) {
            widgets.push(cell.elements[0].widgetid);
          }
        } else {
          widgets.push(cell.elements[0].widgetid);
        }
      })
      if (wRoot) {
        wRoot.appendChild(row);
      }
      if (i === layout.length) setTimeout(() => this.loadWgt(db, widgets, divId), 1000);
    });

  }

  createRow = (i) => {
    const row = document.createElement('div');
    row.className = "row";
    row.style.marginLeft = "0px";
    row.style.marginRight = "0px";
    row.id = "row" + i;
    return row;
  }

  createLayout = (i, el, width, dashId, hidden) => {
    const headerDiv = document.createElement("div");
    headerDiv.id = "head" + el.widgetid;
    headerDiv.style.padding = "5px 20px";
    headerDiv.style.color = "white";
    headerDiv.style.borderTopRightRadius = '10px';
    headerDiv.style.borderTopLeftRadius = '10px';
    if (dashId == '606c97d5ecbae9002d951b71' || dashId == '5f7c12fd3965112230dbf2cd' || dashId == '5f702a953965112230dbd29d' || dashId == '5f92c8f85fd2f53f38600333') {
    } else {
      headerDiv.style.backgroundColor = "#0242C6";
    }

    const height = el.height === "" || el.height === 512 ? "100%" : el.height.toString().replace("px", "") + "px";
    const div = document.createElement('div');
    div.id = el.widgetid;
    div.style.height = height;
    if (dashId == '606c97d5ecbae9002d951b71' || dashId == '5f7c12fd3965112230dbf2cd' || dashId == '5f702a953965112230dbd29d' || dashId == '5f92c8f85fd2f53f38600333') {
      div.style.backgroundColor = "rgb(0, 0, 68)";
    } else if(el.widgetid == '62bc54255e8ec600365dc57a'){
      div.style.backgroundColor = "#000441";
    } else if(el.widgetid == '62bc54255e8ec600365dc578'){
      div.style.backgroundColor = "transparent";
    }else {
      div.style.backgroundColor = "#2291FF";
    }

    const pdiv = document.createElement('div');
    pdiv.className = "layout row" + i
    pdiv.style.width = "calc(" + width + "% - 4px)";
    pdiv.style.margin = "2px";
    pdiv.style.display= hidden?"none":"block";

    pdiv.appendChild(headerDiv);
    pdiv.appendChild(div);

    return pdiv;
  }
  loadWgt = (db, widgets, divId) => {
    let clients;
    let tenantIds;
    if (this.props.app.filterObject.tenant.length > 0) {
      clients = [...this.props.app.filterObject.tenant];
      tenantIds = [...this.props.app.filterObject.tenantId]
    } else {
      let tenants = [];
      let ids = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
        ids.push(item.tenantID);
      });
      clients = [...tenants];
      tenantIds = [...ids]
    }

    let i = 0;
    const listVal = [];
    widgets.forEach(wgt => {
      i++;
      const div = document.getElementById(wgt);
      if(div){
        div.innerHTML = "";
        div.style.width = "100%";
      }
      const oWgt = db.widgets.get(wgt);
      if (oWgt) {
        const head = document.getElementById("head" + wgt);
        if (oWgt.title.length > 0 && oWgt.title !== "RICHTEXT_MAIN.TITLE")
          head.innerHTML = oWgt.title;
        else
          if (head) head.style.display = "none";

        if (!oWgt.container)
          oWgt.container = div;
        else {
          const clone = Object.assign(oWgt);
          clone.$$container = null;
          clone.container = div;
        }
        oWgt.refresh();
        const recurse = () => {
          if (oWgt.refreshing)
            setTimeout(recurse, 1000);
          else {
            if (oWgt.queryResult) {
              let wTxt;
              if (oWgt.queryResult.length) {
                wTxt = oWgt.queryResult[0][0].Value;
              }
              else {
                wTxt = oWgt.queryResult.value ? oWgt.queryResult.value.text : "";
              }

              switch (db.id) {
                case "5eb925e2bb2a332c80394dd8": //F&A
                  {
                    const val = (wTxt === "#N/A" || wTxt === "N\\A" || wTxt === "0" || wTxt === 0) ? 0 : 1;
                    const Class = div.parentElement.className.replace("layout ", "");
                    listVal.push({ "class": Class, "val": val });
                    if (i === widgets.length && i === listVal.length) {
                      let result = [];
                      let j = 0;
                      listVal.forEach((v) => {
                        j++;
                        if (!result[v.class]) result[v.class] = 0;
                        result[v.class] += v.val;
                        if (j === listVal.length) {
                          const wCont = document.getElementById(divId);
                          wCont.childNodes.forEach(el => {
                            if (result[el.id] === 0) el.style.display = 'none';
                          })
                        }
                      })
                    }
                  }
                  break;
                case "606c97d5ecbae9002d951b71": //Foundation-Overview
                  // if (db.$$filters.$$filters[0].$$model.levels[1].filter.all) { } //Pseudo client filter
                  if (db.$$filters.$$filters[1].$$model.jaql.filter.all) { }  //Tenant id filter
                  else {
                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) > 6) {
                      //// check if it is AIG client. if yes show ACF row
                      // if(parseInt(div.parentElement.parentElement.id.replace("row", "")) == 8 && this.props.app.filterObject.tenantId[0] == window.tenantAIG.id){
                      // //// Don't hide ACF row
                      // }else{
                      div.parentElement.parentElement.style.display = "none";
                      // }
                    }
                  }
                  if (this.state.gphVersion == "gph4.0") {
                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 2) {
                      document.getElementById("row2").style.display = "none";
                    }
                    iwGPHwidgets.some(element => {
                      document.getElementById(element.dashboardId).style.display = "none";
                      document.getElementById(element.dashboardId).parentElement.parentElement.style.display = "none";
                    })
                  } else if (this.state.gphVersion == 'iwgph' || this.state.gphVersion == null) {
                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 3) {
                      document.getElementById("row3").style.display = "none";
                    }
                    gph4Widgets.some(element => {
                      document.getElementById(element.dashboardId).style.display = "none";
                      document.getElementById(element.dashboardId).parentElement.parentElement.style.display = "none";
                    })

                  }
                  if (wTxt === "0") {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  break;
                case "5f7c12fd3965112230dbf2cd"://5f6e3bf6575c7c247c68f045 //automated overview
                  if (wTxt === "0") {
                    div.parentElement.parentElement.style.display = "none";
                  }
                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 9) {
                     div.parentElement.parentElement.style.display = "none";
                   }
                  break;
                case "5f92c8f85fd2f53f38600333": //Intelligent Overview
                  if (wTxt === "0") {
                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 18) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                }
                // IOJ -> Insights-Driven -> Overview -> Supplier Delivery Assurance, Talent Demand Forecaster,Work Force Manager 
               
                  break;
                  case "5f19722e48414a2300b179c7":
                  // IOJ -> Insights-Driven -> IFO -> Period End Accelerator, Balance Sheet Analyzer 
                 if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 3) {
                  div.parentElement.parentElement.style.display = "none";
                  } 
                 if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 4) {
                  div.parentElement.parentElement.style.display = "none";
                  }
                break;
                case "5f702a953965112230dbd29d": //Insight-driven
                // if (db.$$filters.$$filters[0].$$model.levels[1].filter.all) { } //Pseudo client filter
                if (db.$$filters.$$filters[1].$$model.jaql.filter.all) { }  //Tenant id filter
                  else {
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 18) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                }
                // IOJ -> Insights-Driven -> Overview -> Supplier Delivery Assurance, Talent Demand Forecaster,Workforce Manager, Task Manager
                if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 7) {
                  div.parentElement.parentElement.style.display = "none";
                }
                if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 8) {
                  div.parentElement.parentElement.style.display = "none";
                }
                if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 10) {
                  div.parentElement.parentElement.style.display = "none";
                }
                if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 12) {
                  div.parentElement.parentElement.style.display = "none";
                }
                break;
                case "5f17f33100e5352f388da331": //Insight-driven cross offering
                // if (db.$$filters.$$filters[0].$$model.levels[1].filter.all) { } //Pseudo client filter
                if (db.$$filters.$$filters[1].$$model.jaql.filter.all) { }  //Tenant id filter
                  else {
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 2) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                }
                // IOJ ->	Insights-Driven -> Cross Offering -> Work Force Manager 
                if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 1) {
                  div.parentElement.parentElement.style.display = "none";
                }
                break;
                case "5f17e79000e5352f388da2d2": // IOJ -> Insights-Driven
                  // Talent & HR Services -> Recruitment Funnel Analyzer Weekly
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 3) {
                   div.parentElement.parentElement.style.display = "none";
                  }
                  // Talent & HR Services ->  Talent Demand Forecaster
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 1) {
                   div.parentElement.parentElement.style.display = "none";
                  } 
                  // Talent & HR Services -> IRF Insights
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 7) {
                   div.parentElement.parentElement.style.display = "none";
                  } 
                  break;
                case "5f16ad3248414a2300b17438": // IOJ -> Intelligent 
                  // IFO -> Intelligent Touchless Accounts Payable
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 9) {
                   div.parentElement.parentElement.style.display = "none";
                  }
                  // IFO -> Invoice Blox
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 4) {
                    div.parentElement.parentElement.style.display = "none";
                }
                break;
                // case "5f16ad3248414a2300b17438": //Intelligent F&A
                //   {

                //     const val = (wTxt === "#N/A" || wTxt === "N\\A" || wTxt === "0" || wTxt === 0) ? 0 : 1;
                //     const Class = div.parentElement.className.replace("layout ", "");
                //     listVal.push({ "class": Class, "val": val });
                //     if (i === widgets.length && i === listVal.length) {
                //       let result = [];
                //       let j = 0;
                //       listVal.forEach((v) => {
                //         j++;
                //         if (!result[v.class]) result[v.class] = 0;
                //         result[v.class] += v.val;
                //         if (j === listVal.length) {
                //           const wCont = document.getElementById(divId);
                //           wCont.childNodes.forEach(el => {
                //             if (result[el.id] === 0) el.style.display = 'none';
                //           })
                //         }
                //       })
                //     }

                //   }
                //   break;
                case "5f36a130bedb412db43f6898": //Intelligent Cross Offering
                  {

                    const val = (wTxt === "#N/A" || wTxt === "N\\A" || wTxt === "0" || wTxt === 0) ? 0 : 1;
                    const Class = div.parentElement.className.replace("layout ", "");
                    listVal.push({ "class": Class, "val": val });
                    if (i === widgets.length && i === listVal.length) {
                      let result = [];
                      let j = 0;
                      listVal.forEach((v) => {
                        j++;
                        if (!result[v.class]) result[v.class] = 0;
                        result[v.class] += v.val;
                        if (j === listVal.length) {
                          const wCont = document.getElementById(divId);
                          wCont.childNodes.forEach(el => {
                            if (result[el.id] === 0) el.style.display = 'none';
                          })
                        }
                      })

                    }
                  }
                  break;
                case "5f33bfa34ad12e24f8afa6b7": //Intelligent Network
                  {

                    const val = (wTxt === "#N/A" || wTxt === "N\\A" || wTxt === "0" || wTxt === 0) ? 0 : 1;
                    const Class = div.parentElement.className.replace("layout ", "");
                    listVal.push({ "class": Class, "val": val });
                    if (i === widgets.length && i === listVal.length) {
                      let result = [];
                      let j = 0;
                      listVal.forEach((v) => {
                        j++;
                        if (!result[v.class]) result[v.class] = 0;
                        result[v.class] += v.val;
                        if (j === listVal.length) {
                          const wCont = document.getElementById(divId);
                          wCont.childNodes.forEach(el => {
                            if (result[el.id] === 0) el.style.display = 'none';
                          })
                        }
                      })

                    }
                  }
                  break;
                default: break;
              }
            }
            //Line chart toltip related fix
            // if(oWgt.type === "chart/line" || oWgt.type === "chart/column" || oWgt.type === "chart/bar" || oWgt.type === "chart/pie"  || oWgt.type === "chart/area" || oWgt.type === "treeMapByQbeeQ" || oWgt.type === "sunburst"){
            //   const div=document.getElementById(wgt);
            //   if(div) div.style.zoom = "132.5%"; 
            //   const oWgt = db.widgets.get(wgt);
            //   oWgt.refresh(); 
            // } 
            //Banking dashboard
            oWgt.on('ready',()=>{
              if(oWgt.type === "TabberByQbeeQ"){
                  //console.log(div);
                  const tabber = document.getElementsByClassName("listDefaultCSS");
                  tabber[0].childNodes.forEach(el=> {
                      if(el.className ==='listItemDefaultCSS')
                          el.onclick = (e) => {
                              clearstyle();
                              e.currentTarget.classList.add("tabberAct")
                              if(e.currentTarget.textContent.toLowerCase()==="user"){
                                  hideWgt("66daf010173ed70033832d7f");
                                  showWgt("66daf010173ed70033832d80");
                              }
                              else{
                                  showWgt("66daf010173ed70033832d7f");
                                  hideWgt("66daf010173ed70033832d80");
                              }
                          }
                  })
              }
          })
          const hideWgt = (wgt) =>{
            const div=document.getElementById(wgt);
            if(div) div.parentElement.style.display = "none";   
        }
        const showWgt = (wgt) =>{
            const div=document.getElementById(wgt);
            if(div) div.parentElement.style.display = "block";
            const oWgt = db.widgets.get(wgt);
            oWgt.refresh();
        }
    
        const clearstyle = () =>{
            const tabber = document.getElementsByClassName("listDefaultCSS");
            tabber[0].childNodes.forEach(el=> {
                el.removeAttribute('style');
                el.classList.remove("tabberAct");
            })    
        }
          //   oWgt.on('ready', () => {
          //     // if(oWgt.type === "BloX"){
          //     //   const drillDB = oWgt.$$model.options.drillTarget.oid ? oWgt.$$model.options.drillTarget.oid : oWgt.$$model.options.drillTarget
          //     //     if (drillDB !== "dummy") {
          //     //       div.onclick = () => this.handleModal(drillDB);
          //     //       div.style.cursor = "pointer";
          //     //     }
          //     // }
          //     db.on('filterschanged', (dd) => {

          //         let id = oWgt.$$model.options.drillTarget ? oWgt.$$model.options.drillTarget.oid : null;
          //         var oMod;
          //         let xAxis = oWgt.metadata.panels[0].items.map((data) => {
          //             if (data.jaql && data.jaql.level)
          //                 return data.jaql.level;
          //             else
          //                 return data.jaql.column;
          //         });
          //         var pay = [this.state.filterArrayTenantId[0]];
          //         dd.filters.$$items.forEach(filt => {
          //             if (filt.jaql) {
          //                 if (xAxis.includes(filt.jaql.level) || xAxis.includes(filt.jaql.title)
          //                     || xAxis.includes(filt.jaql.column)) {
          //                     var val = "All";
          //                     if (xAxis.includes(filt.jaql.level))
          //                         val = new Date(filt.jaql.filter.members[0].toString().replace("+0530 (India Standard Time)", "")).toJSON();
          //                     else if (xAxis.includes(filt.jaql.title) || xAxis.includes(filt.jaql.column))
          //                         val = filt.jaql.filter.members[0]; oMod = val === "All" ? false : true;
          //                     if (val && val !== "All") {
          //                         pay = [...pay, {
          //                             jaql: {
          //                                 title: filt.jaql.title,
          //                                 level: filt.jaql.level,
          //                                 dim: filt.jaql.dim,
          //                                 datatype: filt.jaql.datatype,
          //                                 filter: {
          //                                     members: [val],
          //                                 }
          //                             }
          //                         }]
          //                     }
          //                 }
          //             }
          //         })
          //         if (id && oMod && (pay.length>1)) {
          //                 // let paynew = dd.filters.$$items;
          //                 this.setState({filterArrayTenantId:pay},()=>this.handleModal(id))
          //                 return;
          //         }
          //     });

          // })
            if (oWgt.$$model.options.drillTarget) {
              const drillDB = oWgt.$$model.options.drillTarget.oid ? oWgt.$$model.options.drillTarget.oid : oWgt.$$model.options.drillTarget
              if (drillDB !== "dummy") {
                div.parentElement.onclick = () => this.handleModal(drillDB);
                div.parentElement.style.cursor = "pointer";
              }
            }
            setTimeout(() => { if (div.style.height === "auto") div.style.height = "100%"; }, 2000)

          }
        }
        recurse();
      }
      if (i == widgets.length) {
        //   let str = `<svg id="general-edit" viewBox="0 0 24 24">	
        //   <path fill="currentColor" d="M16.05 11.343l1.529-1.528-3.483-3.418-1.518 1.474 3.472 3.472zm-.707.707l-3.482-3.482L5 15.231v3.566l3.587.01 6.756-6.757zM19 9.808l-10 10-5-.013v-4.987L14.1 5 19 9.808zM8.642 15.24l3.857-3.804-.702-.712-3.858 3.804.703.712z"></path>	
        // </svg>`;	
        //  let divs = document.getElementsByClassName("ew-i-fx ew-i-act btn--dark f-act");	
        //  let totalDivs = divs.length;	
        //  for(let j=0; j<totalDivs; j++){	
        //   divs[j].insertAdjacentHTML( 'beforeend', str );	
        //  }	
        if (this.state.showDashModal) {
          let activeDrillDashObj = {
            activeDahObj: db, activeWidgets: widgets, activeDivId: divId
          }
          this.setState({ activeDrillDashObj });
        } else if (this.state.showSisenseModal) {
          let activeButtonDashObj = {
            activeDahObj: db, activeWidgets: widgets, activeDivId: divId
          }
          this.setState({ activeButtonDashObj });
        } else {
          let activeSubTabDashObj = {
            activeDahObj: db, activeWidgets: widgets, activeDivId: divId
          }
          this.setState({ activeSubTabDashObj });
        }
        db.refresh();
      }
    })
    this.setState({ showLoader: false });

  }

  componentWillMount() {
    let gphVersion = this.props.config[0].gphVersion ? this.props.config[0].gphVersion : null;
    let filteredConfig = [];

    let clients;
    let tenantIds;
    if (this.props.app.filterObject.tenant.length > 0) {
      clients = [...this.props.app.filterObject.tenant];
      tenantIds = [...this.props.app.filterObject.tenantId]
    } else {
      let tenants = [];
      let ids = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
        ids.push(item.tenantID);
      });
      clients = [...tenants];
      tenantIds = [...ids]
    }
    if (clients.length > 0 && gphVersion == null) {
      gphVersion = "iwgph"
    }
    switch (gphVersion) {
      case null: {
        this.props.config.map((item) => {
          if (item.url) {
            let value = allGphExclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
      }
        break;
      case "iwgph": {
        this.props.config.map((item) => {
          if (item.url) {
            let value = iwGphExclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
      }
        break;
      case "gph4.0": {
        this.props.config.map((item) => {
          if (item.url) {
            let value = gph4Exclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (item.url.includes("61e6ac4169f6ec003698d2fb") && !value) {
              item.url = item.url.replace("61e6ac4169f6ec003698d2fb", "5ecbbd7792aefc1cf806be4f");
              let oldElementId = document.getElementById("61e6ac4169f6ec003698d2fb");
              if (oldElementId) {
                oldElementId.id = "5ecbbd7792aefc1cf806be4f";
              }
            }
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
      }
        break;
      default:
        break;
    }
    let mainTabs = this.props.config.filter((item) => item.type == "Tab");
    let subTabs = filteredConfig.filter((item) => item.type == "SubTab");
    let urlButtons = filteredConfig.filter((item) => item.type == "Button");

    //sisense dashboards
    let sisenseStyle = [];
    subTabs.forEach((db, i) => {
      if (db.url) {
        let isSisenseUrl = true;
        isSisenseUrl = db.visualizationType == "sisense" || db.visualizationType == null;
        if (isSisenseUrl) {
          let dashboardId = db.url.split("/");
          let dashId = dashboardId[dashboardId.length - 1];
          sisenseStyle.push({ id: dashId, url: db.url, style: { display: "none" } });
        } else {
          sisenseStyle.push({ id: db.id, url: db.url, style: { display: "none" } });
        }
      }
    });
    let clientsFilterObj;
    let tenantIdFilterObj;
    let gphFilterArray;
    if (clients.length > 0) {
      clientsFilterObj = {
        explicit: true,
        multiSelection: true,
        members: clients.map((a) => a.toUpperCase()),
      }
      tenantIdFilterObj = {
        explicit: true,
        multiSelection: true,
        members: this.props.app.demoClient ? [this.props.app.parentTenantID] : tenantIds.map((a) => a), //hardcoded
      }
      gphFilterArray = [
        { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { "all": true } } },
        { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { "all": true } } },
        { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { "all": true } } }
      ]
    } else {
      clientsFilterObj = {
        all: true,
      }
      tenantIdFilterObj = {
        all: true,
      }
      gphFilterArray = [
        { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { members: ["BPO"] } } },
        { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { members: ["Financial Services"] } } },
        { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { members: ["Communications, Media and Technology"] } } }
      ]
    }
    this.setState({
      mainTabs,
      subTabs,
      allSubTabs: subTabs,
      urlButtons,
      sisenseStyle,
      gphVersion: gphVersion,
      filterArray: [
        {

          levels: [
            {
              dim: "[Client.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Pseudo"],
              },
            },
            {
              dim: "[Client.Client]",
              datatype: "text",
              title: "Client",
              filter: clientsFilterObj,
            },
          ],
        },
      ],
      filterArrayGPH: gphFilterArray,
      filterArrayTenantId: [{ "jaql": { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": tenantIdFilterObj } }],
      filterArrayTenantIdAndClient:[
        {

          levels: [
            {
              dim: "[Client.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Client"],
              },
            },
            {
              dim: "[Client.Client]",
              datatype: "text",
              title: "Client",
              filter: {"all": true},
            },
            { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": tenantIdFilterObj }
          ],
        },
      ]
    });
  }
  // getPowerBiEmbedUrl = () => {
  //   fetch('https://api.powerbi.com/v1.0/myorg/reports/6ee868c8-a72e-4fb9-a3f7-43350a4ea314', {
  //     Authorization: "Bearer " + localStorage.getItem("access_token_powerbi"),
  //   })
  //     .then(
  //       (result) => {
  //         this.setState({ powerBiUrl: result.embedUrl })
  //       },
  //       (error) => {
  //         console.log("fetcherror", error);
  //       }
  //     )
  // }
  // getPowerBiEmbedUrl2 = () => {
  //   fetch('https://api.powerbi.com/v1.0/myorg/reports/320fd7a7-a622-459b-b09a-b345e1011bcb', {
  //     Authorization: "Bearer " + localStorage.getItem("access_token_powerbi"),
  //   })
  //     .then(
  //       (result) => {
  //         this.setState({ powerBiUrl2: result.embedUrl })
  //       },
  //       (error) => {
  //         console.log("fetcherror", error);
  //       }
  //     )
  // }
  componentDidMount() {
    //Uncomment this before building - LOCAL
    //  if(ENVIRONMENT!=='local' && this.props.userDetails.isSisenseEnabled){
    // this.preLoadDash('606c97d5ecbae9002d951b71');
    // this.preLoadDash('5f7c12fd3965112230dbf2cd');
    // this.preLoadDash('5f702a953965112230dbd29d');
    // this.preLoadDash('5f92c8f85fd2f53f38600333');
    // this.preLoadDash('5f16ad3248414a2300b17438');
    //  }
    // this.getPowerBiEmbedUrl();
    // this.getPowerBiEmbedUrl2();

  }
  applyDbFilters = (db, dashId) => {
    const filterOptions = {
      save: true,
      refresh: true,
      unionIfSameDimensionAndSameType: false,
    };
    let filterArrayLanguage = [{ "jaql": { "dim": "[Translation.Language]", "datatype": "text", "title": "Language", "filter": { "members": [this.props.app.language] } } }]

    let filters = [...this.state.filterArray];
    let isTenantIdFilter = tenantIdDashboards.some(element => {
      return dashId == element.dashboardId
    })

    let isDrillToDash = drillToDashWithParentFilter.some(element => {
      return dashId == element.dashboardId
    })
    let isTenantIdClient = tenantIdWithClient.some(element => {
      return dashId == element.dashboardId
    })
    // db.$$model.filters.clear();
    const tenantPayload = [...this.state.filterArrayTenantId];
    const tenantIdClientpayload = [...this.state.filterArrayTenantIdAndClient];

    if(isDrillToDash){
      const drillToPayload = [...this.state.parentFilters];
    db.$$model.filters.update(drillToPayload, filterOptions);
    }else{
      
    if (dashId == '5ecbbd7792aefc1cf806be4f') {
      let payload = this.state.filterArrayTenantId.concat(this.state.filterArrayGPH);
      db.$$model.filters.update(payload, filterOptions);
    // } else if (dashId == '5f17f33100e5352f388da331') {
    //   let payload = this.state.filterArrayTenantId.concat(filterArrayLanguage); // Removed this after creation of process mining dashboard
    //   db.$$model.filters.update(payload, filterOptions);
    } else if(isTenantIdClient){
      db.$$model.filters.update(tenantIdClientpayload, filterOptions);
    } else {
      db.$$model.filters.update(tenantPayload, filterOptions);
    }
    
  }
    // if (isTenantIdFilter) {
    //   const tenantPayload = [...this.state.filterArrayTenantId];
    //   db.$$model.filters.update(tenantPayload, filterOptions);
    // } else {
    //   if (dashId == '5ecbbd7792aefc1cf806be4f') {
    //     let payload = this.state.filterArray.concat(this.state.filterArrayGPH);
    //     db.$$model.filters.update(payload, filterOptions);
    //   } else if (dashId == '5f17f33100e5352f388da331') {
    //     let payload = this.state.filterArray.concat(filterArrayLanguage);
    //     db.$$model.filters.update(payload, filterOptions);
    //   } else {
    //     db.$$model.filters.update(filters[0], filterOptions);
    //   }
    // }

  };
  applyDbFiltersFixed = (db, dashId, payload) => {
    const filterOptions = {
      save: true,
      refresh: true,
      unionIfSameDimensionAndSameType: false,
    };
    let clients;
    if (this.props.app.filterObject.tenantId.length > 0) {
      clients = [...this.props.app.filterObject.tenantId];
    } else {
      let tenants = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.tenantID);
      });
      clients = [...tenants];
    }
    let updatedPayload = ""
    let selectedClientType = "Client";
    switch (this.state.selectedClientType) {
      // case "tenantId":
      //   selectedClientType = "Client";
      //   break;
      case "pseudo":
        selectedClientType = "Pseudo";
        break;
      case "client":
        selectedClientType = "Client";
        break;
    }
    if (clients.length > 0 || (this.state.showMaskedUnmasked && this.state.selectedTenantId != "all")) {
      let client_name = (this.state.selectedTenantId != "all" && this.state.showMaskedUnmasked)
        ? this.state.selectedTenantId
        : clients.map((a) => a)[0];

      if (this.state.showReconFilter) {
        updatedPayload = payload.replace("_CLIENT_", client_name).replace("_FILTER_", this.state.deploymentType).replace("_ClientSelection_", selectedClientType)
      } else if (this.state.showHRManagerFilter) {
        updatedPayload = payload.replace("_CLIENT_", client_name).replace("_FILTER_", this.state.outputVariance).replace("_ClientSelection_", selectedClientType)
      } else {
        updatedPayload = payload.replace("_CLIENT_", client_name).replace("_ClientSelection_", selectedClientType)
      }
    } else {
      if (this.state.showReconFilter) {
        updatedPayload = payload.replace(`"members":["_CLIENT_"]`, `"all": true`).replace("_FILTER_", this.state.deploymentType).replace("_ClientSelection_", selectedClientType)
      } else if (this.state.showHRManagerFilter) {
        updatedPayload = payload.replace(`"members":["_CLIENT_"]`, `"all": true`).replace("_FILTER_", this.state.outputVariance).replace("_ClientSelection_", selectedClientType)
      } else {
        updatedPayload = payload.replace(`"members":["_CLIENT_"]`, `"all": true`).replace("_ClientSelection_", selectedClientType)
      }
    }

    db.$$model.filters.update(JSON.parse(updatedPayload), filterOptions);

  };
  applyFilters = (filters) => {

  };
  reMountComponents = (props) => {
    let gphVersion = props.config[0].gphVersion ? props.config[0].gphVersion : null;
    let filteredConfig = [];
    let allsubtabsArray = this.state.allSubTabs;
    let clients;
    if (props.app.filterObject.tenant.length > 0) {
      clients = [...props.app.filterObject.tenant];
    } else {
      let tenants = [];
      props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
      });
      clients = [...tenants];
    }
    if (clients.length > 0 && gphVersion == null) {
      gphVersion = "iwgph"
    }
    switch (gphVersion) {
      case null: {
        allsubtabsArray = [];
        props.config.map((item) => {
          if (item.url) {
            let value = allGphExclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (item.type == "SubTab" && item.url.includes("5ecbbd7792aefc1cf806be4f") && !value) {
              item.url = item.url.replace("5ecbbd7792aefc1cf806be4f", "61e6ac4169f6ec003698d2fb");
              let oldElementId = document.getElementById("5ecbbd7792aefc1cf806be4f");
              if (oldElementId) {
                oldElementId.id = "61e6ac4169f6ec003698d2fb";
              }
            }
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
        //replace url in allsubtabs
        this.state.allSubTabs.map((item) => {
          if (item.url) {
            if (item.url.includes("5ecbbd7792aefc1cf806be4f")) {
              item.url = item.url.replace("5ecbbd7792aefc1cf806be4f", "61e6ac4169f6ec003698d2fb");
            }
            allsubtabsArray.push(item);
          }
        })
      }
        break;
      case "iwgph": {
        allsubtabsArray = [];
        props.config.map((item) => {
          if (item.url) {
            let value = iwGphExclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (item.type == "SubTab" && item.url.includes("5ecbbd7792aefc1cf806be4f") && !value) {
              item.url = item.url.replace("5ecbbd7792aefc1cf806be4f", "61e6ac4169f6ec003698d2fb");
              let oldElementId = document.getElementById("5ecbbd7792aefc1cf806be4f");
              if (oldElementId) {
                oldElementId.id = "61e6ac4169f6ec003698d2fb";
              }
            }
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
        //replace url in allsubtabs
        this.state.allSubTabs.map((item) => {
          if (item.url) {
            if (item.url.includes("5ecbbd7792aefc1cf806be4f")) {
              item.url = item.url.replace("5ecbbd7792aefc1cf806be4f", "61e6ac4169f6ec003698d2fb");
            }
            allsubtabsArray.push(item);
          }
        })
      }
        break;
      case "gph4.0": {
        allsubtabsArray = [];
        props.config.map((item) => {
          if (item.url) {
            let value = gph4Exclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (item.url.includes("61e6ac4169f6ec003698d2fb") && !value) {
              item.url = item.url.replace("61e6ac4169f6ec003698d2fb", "5ecbbd7792aefc1cf806be4f");
              let oldElementId = document.getElementById("61e6ac4169f6ec003698d2fb");
              if (oldElementId) {
                oldElementId.id = "5ecbbd7792aefc1cf806be4f";
              }
            }
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
        //replace url in allsubtabs
        this.state.allSubTabs.map((item) => {
          if (item.url) {
            if (item.url.includes("61e6ac4169f6ec003698d2fb")) {
              item.url = item.url.replace("61e6ac4169f6ec003698d2fb", "5ecbbd7792aefc1cf806be4f");
            }
            allsubtabsArray.push(item);
          }
        })
      }
        break;
      default:
        break;
    }
    let mainTabs = props.config.filter((item) => item.type == "Tab");
    let subTabs = filteredConfig.filter((item) => item.type == "SubTab");
    let urlButtons = filteredConfig.filter((item) => item.type == "Button");
    //sisense dashboards
    let sisenseStyle = [];
    subTabs.forEach((db, i) => {
      if (db.url) {
        let isSisenseUrl = true;
        isSisenseUrl = db.visualizationType == "sisense" || db.visualizationType == null;
        if (isSisenseUrl) {
          let dashboardId = db.url.split("/");
          let dashId = dashboardId[dashboardId.length - 1];
          sisenseStyle.push({ id: dashId, url: db.url, style: { display: "none" } });
        } else {
          sisenseStyle.push({ id: db.id, url: db.url, style: { display: "none" } });
        }
      }
    });
    // let clients = [...props.app.filterObject.tenantId];
    let tabParentId = (Object.keys(props.alertObject).length != 0) ? props.alertObject.configParentId : this.state.tabParentId;
    let defaultSubTab = (Object.keys(props.alertObject).length != 0) ? props.alertObject.configId : this.state.defaultSubTab;
    
    const mergedArray = [...allsubtabsArray, ...subTabs];
    const map = {};
    for (const element of mergedArray) {
      map[element.id] = element;
    }
    const newArray = Object.values(map);

    this.setState({
      mainTabs,
      subTabs,
      allSubTabs: [...newArray],
      urlButtons,
      sisenseStyle,
      gphVersion: gphVersion,
      tabParentId

    }, () => this.handleTabChange(tabParentId, defaultSubTab));
  };
  componentWillReceiveProps(nextProps) {
    if ((this.props.config !== nextProps.config) || (this.props.app.language != nextProps.app.language)) {
      this.reMountComponents(nextProps);
    }
    else if (nextProps.isConnected !== this.props.isConnected) {
      if (nextProps.isConnected && ENVIRONMENT !== 'local' && this.props.userDetails.isSisenseEnabled) {
        // this.reMountComponents(nextProps);
        //opria Subtab 
        if(this.state.subTabOpriaUrl){
          this.loadDash(this.state.subTabDashId, this.state.subTabOpriaUrl, nextProps, "subtab");
        }
        //opria button
        if(this.state.buttonOpriaUrl){
          this.loadDash('sisenseModal', this.state.buttonOpriaUrl, nextProps, "button");
        }

      }
    } 
    else if (nextProps.alertObject !== this.props.alertObject) {
      this.reMountComponents(nextProps);
    }
    if (
      this.props.app.filterObject.tenant !== nextProps.app.filterObject.tenant
    ) {
      let filterArray = [];
      let gphFilterArray = [];
      let filterArrayTenantId = [];
      let filterArrayTenantIdAndClient = [];
      if (
        nextProps.app.filterObject.tenant.length == 0 &&
        this.props.userDetails.tenant.length == 0
      ) {
        filterArray = [
          {
            levels: [
              {
                dim: "[Client.Type]",
                datatype: "text",
                title: "Type",
                filter: {
                  explicit: true,
                  userMultiSelect: false,
                  multiSelection: false,
                  members: ["Pseudo"],
                },
              },
              {
                title: "Client",
                dim: "[Client.Client]",
                datatype: "text",
                filter: {
                  all: true,
                },
              },
            ],
          },
        ];
        gphFilterArray = [
          { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { members: ["BPO"] } } },
          { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { members: ["Financial Services"] } } },
          { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { members: ["Communications, Media and Technology"] } } }
        ]
        filterArrayTenantId = [{ "jaql": { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": { all: true } } }]
        filterArrayTenantIdAndClient = [
          {
  
            levels: [
              {
                dim: "[Client.Type]",
                datatype: "text",
                title: "Type",
                filter: {
                  explicit: true,
                  userMultiSelect: false,
                  multiSelection: false,
                  members: ["Client"],
                },
              },
              {
                dim: "[Client.Client]",
                datatype: "text",
                title: "Client",
                filter: {"all": true},
              },
              { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": { "all": true } }
            ],
          },
        ]
      } else if (
        nextProps.app.filterObject.tenant.length == 0 &&
        this.props.userDetails.tenant.length != 0
      ) {
        let tenants = [];
        this.props.userDetails.tenant.map((item) => {
          tenants.push(item.pseudoName);
        });
        let tenantIds = [];
        this.props.userDetails.tenant.map((item) => {
          tenantIds.push(item.tenantID);
        });
        filterArray = [
          {

            levels: [
              {
                dim: "[Client.Type]",
                datatype: "text",
                title: "Type",
                filter: {
                  explicit: true,
                  userMultiSelect: false,
                  multiSelection: false,
                  members: ["Pseudo"],
                },
              },
              {
                dim: "[Client.Client]",
                datatype: "text",
                title: "Client",
                filter: {
                  explicit: true,
                  multiSelection: true,
                  members: tenants.map((a) => a.toUpperCase()),
                },
              },
            ],
          },
        ];
        gphFilterArray = [
          { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { "all": true } } },
          { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { "all": true } } },
          { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { "all": true } } }
        ];
        filterArrayTenantId = [{
          "jaql": {
            "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": {
              explicit: true,
              multiSelection: true,
              members: this.props.app.demoClient ? [this.props.app.parentTenantID] : tenantIds.map((a) => a), //hardcoded 
            }
          }
        }]
        filterArrayTenantIdAndClient =[
          {
  
            levels: [
              {
                dim: "[Client.Type]",
                datatype: "text",
                title: "Type",
                filter: {
                  explicit: true,
                  userMultiSelect: false,
                  multiSelection: false,
                  members: ["Client"],
                },
              },
              {
                dim: "[Client.Client]",
                datatype: "text",
                title: "Client",
                filter: {"all": true},
              },
              {
                "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": {
                  explicit: true,
                  multiSelection: true,
                  members: this.props.app.demoClient ? [this.props.app.parentTenantID] : tenantIds.map((a) => a), //hardcoded 
                }
              }
            ],
          },
        ]
      } else {
        filterArray = [
          {

            levels: [
              {
                dim: "[Client.Type]",
                datatype: "text",
                title: "Type",
                filter: {
                  explicit: true,
                  userMultiSelect: false,
                  multiSelection: false,
                  members: ["Pseudo"],
                },
              },
              {
                dim: "[Client.Client]",
                datatype: "text",
                title: "Client",
                filter: {
                  explicit: true,
                  multiSelection: true,
                  members:
                    nextProps.app.filterObject.tenant.map((a) =>
                      a.toUpperCase()
                    ),

                },
              },
            ],
          },
        ];
        gphFilterArray = [
          { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { "all": true } } },
          { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { "all": true } } },
          { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { "all": true } } }
        ];
        filterArrayTenantId = [{
          "jaql": {
            "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": {
              explicit: true,
              multiSelection: true,
              members: nextProps.app.demoClient ? [nextProps.app.parentTenantID]
                : nextProps.app.filterObject.tenantId.map((a) =>
                  a
                ), //hardcoded

            },
          }
        }];
        filterArrayTenantIdAndClient = [
          {
  
            levels: [
              {
                dim: "[Client.Type]",
                datatype: "text",
                title: "Type",
                filter: {
                  explicit: true,
                  userMultiSelect: false,
                  multiSelection: false,
                  members: ["Client"],
                },
              },
              {
                dim: "[Client.Client]",
                datatype: "text",
                title: "Client",
                filter: {"all": true},
              },
              {
                "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": {
                  explicit: true,
                  multiSelection: true,
                  members: nextProps.app.demoClient ? [nextProps.app.parentTenantID]
                    : nextProps.app.filterObject.tenantId.map((a) =>
                      a
                    ), //hardcoded
    
                },
              }
            ],
          },
        ]
      }
      this.setState({ filterArray, filterArrayGPH: gphFilterArray, filterArrayTenantId, filterArrayTenantIdAndClient });
      this.handleClick('id', this.state.selectedURL, false, "sisense");
    }


  }
  handleClick = (id, url, openInNewWindow, visualizationType) => {
    this.setState({
      showLoader: false
    })
    let isSisenseUrl = true;
    let PowerBiReportId;
    let isPowerBiUrl = true;
    if (url) {
      isSisenseUrl = visualizationType == "sisense" || visualizationType == null;
      isPowerBiUrl = visualizationType == "powerbi"; //change_report
      //powerBI api
      if (isPowerBiUrl && this.props.userDetails.isPowerBIEnabled && !openInNewWindow) {
        let isPowerBiService = false;
        isPowerBiService = url.includes('app.powerbi.com');
        let clients = [];
        if (isPowerBiService) {
          PowerBiReportId = url.substring(
            url.lastIndexOf("reportId") + 9,
            url.lastIndexOf("reportId") + 45)
          fetch(`https://api.powerbi.com/v1.0/myorg/reports/${PowerBiReportId}`, {
            method: "GET",
            headers: { "Authorization": "Bearer " + localStorage.getItem("access_token_powerbi"), }
          }).then(response => response.json())
            .then(
              (res) => {
                clients = [];
                let powerFilter = {};
                if (this.props.app.filterObject.tenant.length > 0) {
                  clients = [...this.props.app.filterObject.tenant];
                } else {
                  let tenants = [];
                  this.props.userDetails.tenant.map((item) => {
                    tenants.push(item.pseudoName);
                  });
                  clients = [...tenants];
                }
                if (clients.length > 0) {
                  powerFilter = {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                      table: "Tenant",
                      column: "PseudoName"
                    },
                    operator: "Is",
                    values: clients.map((a) => a.toUpperCase())
                  }
                }

                this.setState({ showPowerBi: true, powerBiReportId: PowerBiReportId, powerBiEmbedUrl: res.embedUrl, powerBiFilterObj: powerFilter })
              },
              (error) => {
           //     console.log("PowerBi fetcherror", error);
              }
            )
        } else {
          let urlWithFilter = url;
          if (this.props.app.filterObject.tenant.length > 0) {
            clients = [...this.props.app.filterObject.tenant];
          } else {
            let tenants = [];
            this.props.userDetails.tenant.map((item) => {
              tenants.push(item.pseudoName);
            });
            clients = [...tenants];
          }
          if (clients.length > 0) {
            let addClientFilter = this.props.config.filter((y) => y.id == id)[0]
              .addClientFilter;
            let filterParams = this.props.config.filter((y) => y.id == id)[0]
              .filterParams;
            let replaceString = this.props.config.filter((y) => y.id == id)[0]
              .replaceString;
            clients = clients.join();
            let n = url.indexOf("?");
            if (addClientFilter == true) {
              urlWithFilter = this.ReplaceUrlString(
                url,
                filterParams,
                replaceString,
                clients,
                n
              );
            }
          }
          this.setState({ showPowerBiWithoutToken: true, powerBidirectUrl: urlWithFilter })

        }

      } else {
        this.setState({ selectedURL: url });
        let dashboardId = url.split("/");
        let dashId = dashboardId[dashboardId.length - 1];
        let sisenseStyle = [];
        this.state.subTabs.forEach((db, i) => {
          if (db.url) {
            let isSisence = false;
            isSisence = visualizationType == "sisense" || visualizationType == null;
            if (isSisence) {
              this.setState({ showDashFilt: false, filterText: "«" })
              let dbId = db.url.split("/");
              let ID = dbId[dbId.length - 1];
              sisenseStyle.push({
                id: ID,
                url: db.url,
                style: ID === dashId ? { display: "block" } : { display: "none" },
              });
            } else {
              let tempUrl = db.url;
              let filterParams = this.props.config.filter((y) => y.id == db.id)[0]
                .filterParams;
              let replaceString = this.props.config.filter((y) => y.id == db.id)[0]
                .replaceString;
              let urlType = this.props.config.filter((y) => y.id == db.id)[0].urlType;

              if (this.props.app.filterObject.tenant.length > 0 && db.addClientFilter == true) {
                let tenants = this.props.app.filterObject.tenant;
                tenants = tenants.join();
                let n = db.url.indexOf("?");
                if (urlType == 'APPEND') {
                  tempUrl = this.ReplaceUrlString(
                    db.url,
                    null,
                    replaceString,
                    tenants,
                    n
                  );
                } else if (urlType == 'REPLACE' && filterParams) {
                  tempUrl = this.ReplaceUrlString(
                    db.url,
                    filterParams,
                    replaceString,
                    tenants,
                    n
                  );
                }
              }
              sisenseStyle.push({
                id: db.id,
                url: tempUrl,
                style: db.id === id ? { display: "block" } : { display: "none" },
              });
              if (db.isOpenInNewWindow && db.id === id) {
                this.showConfirmAlert(db.url);
              }
            }

          }
        });
        this.setState({ sisenseStyle, filterApplied: true });
        setTimeout(() => {
          this.setState({ filterApplied: false });
        }, 2000);
        if (this.props.currentApp && this.props.currentApp.dashboards && isSisenseUrl && this.props.isConnected) {
          this.loadDash(dashId, url, this.props, "");

        }else{
          this.setState({subTabDashId:dashId, subTabOpriaUrl:url})
          
        }
      }
    }

  };


  handleTabChange = (value, defaultSubTab) => {
    this.setState({
      defaultSubTab
    })
    let subTab;
    if (this.state.subTabs.filter((item) => item.parentID == this.state.tabParentId).length > 0) {
      this.setState({
        showLoader: false
      })
    }
    switch (value) {
      case 0:
        this.setState({
          showDefault: true,
          tabParentId: 0,
        });
        break;
      case 1:
        {
          
          if (defaultSubTab == 0) {
            subTab = this.state.subTabs
              .filter((item) => item.parentID == 1)
              .sort((a, b) => {
                return a.position - b.position;
              })[0];
          } else {
            subTab = this.state.subTabs
              .filter((item) => (item.parentID == 1 && item.id == defaultSubTab))[0];
          }
          this.setSubTab(subTab);
          this.setState({
            showDefault: false,
            tabParentId: 1,
          });
        }
        break;
      case 2:
        {
         
          if (defaultSubTab == 0) {
            subTab = this.state.subTabs
              .filter((item) => item.parentID == 2)
              .sort((a, b) => {
                return a.position - b.position;
              })[0];
          } else {
            subTab = this.state.subTabs
              .filter((item) => (item.parentID == 2 && item.id == defaultSubTab))[0];
          }
          this.setSubTab(subTab);
          this.setState({
            showDefault: false,
            tabParentId: 2,
          });
        }
        break;
      case 3:
        {
          
          if (defaultSubTab == 0) {
            subTab = this.state.subTabs
              .filter((item) => item.parentID == 3)
              .sort((a, b) => {
                return a.position - b.position;
              })[0];
          } else {
            subTab = this.state.subTabs
              .filter((item) => (item.parentID == 3 && item.id == defaultSubTab))[0];
          }
          this.setSubTab(subTab);
          this.setState({
            showDefault: false,
            tabParentId: 3,
          });
        }
        break;
      case 4:
        {
         
          if (defaultSubTab == 0) {
            subTab = this.state.subTabs
              .filter((item) => item.parentID == 4)
              .sort((a, b) => {
                return a.position - b.position;
              })[0];
          } else {
            subTab = this.state.subTabs
              .filter((item) => (item.parentID == 4 && item.id == defaultSubTab))[0];
          }
          this.setSubTab(subTab);
          this.setState({
            showDefault: false,
            tabParentId: 4,
          });
        }
        break;
      default:
        break;
    }
  };
  callUserEngagementAPI = (id, btnId) => {
    this.props.callUserEnagementSessionAPI('IOJ', id, 0, true, "", "", btnId?btnId:null)
  }
  setSubTab = (element) => {
    if (element) {
      let eventcode = "";
      switch(element.parentID){
        case 1:
          eventcode="SH-IOJ-F";
          break;
        case 2:
          eventcode="SH-IOJ-A";
          break;
        case 3:
          eventcode="SH-IOJ-ID";
          break;
        case 4:
          eventcode="SH-IOJ-I";
          break; 
      }
     
      //Clear previous sisense frame
      let activeUrlButtons = [];
      this.state.urlButtons
        .filter((item) => item.parentID == element.id)
        .sort((a, b) => a - b)
        .map((btn) => {
          activeUrlButtons.push(btn);
        });

      this.setState(
        { activeUrlButtons, activeSubTab: element.displayName, activeSubTabId: element.id, showPowerBi: false, showPowerBiWithoutToken: false },
        () => {
          this.callUserEngagementAPI(element.id)
          let isSisenseUrl = false;
          let isPowerBiUrl = false;
          if (element.url) {
            isSisenseUrl = element.visualizationType == "sisense" || element.visualizationType == null;
            isPowerBiUrl = element.visualizationType == "powerbi"; //change_report
            if (isSisenseUrl) {
              this.setState({ isSisenseSubtab: true });
            } else {
              this.setState({ isSisenseSubtab: false });
            }
          }
          // if (ENVIRONMENT !== 'local' || !isSisenseUrl || !isPowerBiUrl) {
          if (ENVIRONMENT !== 'local' || !isSisenseUrl) {
            this.handleClick(element.id, element.url, element.isOpenInNewWindow, element.visualizationType);  //Uncomment this before building - LOCAL
          }
        }
      );
    }
  };


  ReplaceUrlString = (url, filterParams, replaceString, tenants, n) => {
    if (n > -1) {
      return url + "&" + (filterParams ? filterParams.replace(replaceString, tenants) : (replaceString + "=" + tenants));
    } else {
      return url + "?" + (filterParams ? filterParams.replace(replaceString, tenants) : (replaceString + "=" + tenants));
    }
  };

  AppendUrlString = (url, replaceString, tenants, n) => {
    if (n > -1) return url + "&" + replaceString + "=" + tenants;
    else return url + "?" + replaceString + "=" + tenants;
  };

  handleUrlButton = (btn) => {
    this.callUserEngagementAPI(this.state.activeSubTabId, btn.id)
    this.setState({
      showLoader: false
    })
    let currentUrl = "";
    let isSisenseUrl = false;
    let isPowerBiUrl = false;
    let PowerBiReportId = ""
    let clientNamesList = [];
    let pseudoNamesList = [];
    let tenantIdsList = [];
    if (btn.iandIApplicationClientDetails.length > 0) {
      //add All client
      clientNamesList = [{ label: "All", value: "all" }];
      pseudoNamesList = [{ label: "All", value: "all" }];
      tenantIdsList = [{ label: "All", value: "all" }];
      btn.iandIApplicationClientDetails.map(item => {
        clientNamesList.push({ label: item.client, value: item.client });
        pseudoNamesList.push({ label: item.pseudoName, value: item.pseudoName });
        tenantIdsList.push({ label: item.tenantIdentifier, value: item.tenantIdentifier });
      })
    }
    this.setState({ clientPseudoClientidList: btn.iandIApplicationClientDetails, clientNamesList, pseudoNamesList, tenantIdsList })
    if (btn.url) {
      isSisenseUrl = btn.visualizationType == "sisense" || btn.visualizationType == null;
      isPowerBiUrl = btn.visualizationType == "powerbi";
    }
    if (isPowerBiUrl && this.props.userDetails.isPowerBIEnabled && !btn.isOpenInNewWindow) {
      let isPowerBiService = false;
      isPowerBiService = btn.url.includes('app.powerbi.com');
      let clients = [];
      if (isPowerBiService) {
        PowerBiReportId = btn.url.substring(
          btn.url.lastIndexOf("reportId") + 9,
          btn.url.lastIndexOf("reportId") + 45)
        fetch(`https://api.powerbi.com/v1.0/myorg/reports/${PowerBiReportId}`, {
          method: "GET",
          headers: { "Authorization": "Bearer " + localStorage.getItem("access_token_powerbi"), }
        }).then(response => response.json())
          .then(
            (res) => {
              let powerFilter = {};
              if (this.props.app.filterObject.tenant.length > 0) {
                clients = [...this.props.app.filterObject.tenant];
              } else {
                let tenants = [];
                this.props.userDetails.tenant.map((item) => {
                  tenants.push(item.pseudoName);
                });
                clients = [...tenants];
              }
              if (clients.length > 0) {
                powerFilter = {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "Tenant",
                    column: "PseudoName"
                  },
                  operator: "Is",
                  values: clients.map((a) => a.toUpperCase())
                }
              }

              this.setState({ showPowerBiModal: true, powerBiReportIdModal: PowerBiReportId, powerBiEmbedUrlModal: res.embedUrl, powerBiFilterObj: powerFilter })
            },
            (error) => {
        //      console.log("PowerBi fetcherror", error);
            }
          )
      } else {

        let urlWithFilter = btn.url;
        if (this.props.app.filterObject.tenant.length > 0) {
          clients = [...this.props.app.filterObject.tenant];
        } else {
          let tenants = [];
          this.props.userDetails.tenant.map((item) => {
            tenants.push(item.pseudoName);
          });
          clients = [...tenants];
        }
        if (clients.length > 0) {
          let filterParams = this.props.config.filter((y) => y.id == btn.id)[0]
            .filterParams;
          let replaceString = this.props.config.filter((y) => y.id == btn.id)[0]
            .replaceString;
          clients = clients.join();
          let n = btn.url.indexOf("?");
          if (btn.addClientFilter == true) {
            urlWithFilter = this.ReplaceUrlString(
              btn.url,
              filterParams,
              replaceString,
              clients,
              n
            );
          }

        }
        this.setState({ showPowerBiWithoutTokenBtn: true, powerBidirectUrlBtn: urlWithFilter })

      }
    } else {

      let urlType = this.props.config.filter((y) => y.id == btn.id)[0].urlType;
      let urlUpper = '';
      if (urlType) {
        urlUpper = urlType.toUpperCase();
      }
      let addClientFilter = this.props.config.filter((y) => y.id == btn.id)[0]
        .addClientFilter;
      let filterParams = this.props.config.filter((y) => y.id == btn.id)[0]
        .filterParams;
      let replaceString = this.props.config.filter((y) => y.id == btn.id)[0]
        .replaceString;

      if (
        this.props.app.filterObject.tenant.length > 0 &&
        btn.addClientFilter == true
      ) {
        let tenants = this.props.app.filterObject.tenant;

        tenants = tenants.join();
        let n = btn.url.indexOf("?");
        switch (urlType.toUpperCase()) {
          case "APPEND":
            this.currentUrl = this.AppendUrlString(
              btn.url,
              replaceString,
              tenants,
              n
            );
            break;
          case "REPLACE": //sisense

            isSisenseUrl = btn.visualizationType == "sisense" || btn.visualizationType == null;
            if (isSisenseUrl) {
              this.currentUrl = btn.url;
            } else {
              this.currentUrl = this.ReplaceUrlString(
                btn.url,
                filterParams,
                replaceString,
                tenants,
                n
              );
            }
            break;
          default:
            this.currentUrl = btn.url;
            break;
        }
        if (urlUpper == 'REPLACE' && isSisenseUrl && !btn.isOpenInNewWindow) { //sisense
          // if (btn.iandIApplicationClientDetails.length > 0) {
          //   this.setState({ showMaskedUnmasked: true })
          // }
          this.setState({ showSisenseModal: true, currentDashboardUrl: btn.url });
          // if (btn.url.includes("61ae1763b0c597003755a9b9")) { //Recon Advisor
          //   this.setState({ showReconFilter: true, currentDashboardUrl: btn.url });
          // } else if (btn.url.includes("60fecbbcac1320002d6d3a3a")) { //HR Manager
          //   this.setState({ showHRManagerFilter: true, currentDashboardUrl: btn.url });
          // }
          if(this.props.isConnected){
            this.loadDash('sisenseModal', btn.url, this.props, "");
          }else{
            this.setState({buttonOpriaUrl:btn.url})  //save url for rendering after connection
          }
        } else if (btn.isOpenInNewWindow) {
          this.showConfirmAlert(btn.url);
        } else {
          this.setState({ currentDashboardUrl: this.currentUrl, showModal: true });
        }
      } else {
        if (urlUpper == 'REPLACE' && isSisenseUrl && !btn.isOpenInNewWindow) {
          // if (btn.iandIApplicationClientDetails.length > 0) {
          //   this.setState({ showMaskedUnmasked: true, currentDashboardUrl: btn.url })
          // }
          this.setState({ showSisenseModal: true, currentDashboardUrl: btn.url });
          // if (btn.url.includes("61ae1763b0c597003755a9b9")) { //Recon Advisor
          //   this.setState({ showReconFilter: true, currentDashboardUrl: btn.url });
          // } else if (btn.url.includes("60fecbbcac1320002d6d3a3a")) { //HR Manager
          //   this.setState({ showHRManagerFilter: true, currentDashboardUrl: btn.url });
          // }
          if(this.props.isConnected){
            this.loadDash('sisenseModal', btn.url, this.props, "");
          }else{
            this.setState({buttonOpriaUrl:btn.url})  //save url for rendering after connection
          }
        } else if (btn.isOpenInNewWindow) {
          this.showConfirmAlert(btn.url);
        }  else if (urlUpper == 'REPLACE' && isSisenseUrl && !this.props.isConnected) {
          this.setState({currentDashboardUrl: btn.url, showSisenseModal: true });
        }else {
          this.setState({ currentDashboardUrl: btn.url, showModal: true });
        }
      }
    }
  };
  handleModal = (drillDB) => {
    this.setState({ showDashModal: true })
    this.loadDash("drillDash", drillDB, this.props, "");
  }
  handleNewTab = (url) => {

    let win = window.open(url, '_blank', 'top=50,left=250,location=no,status=no,menubar=no,toolbar=no,resizable=no,scrollbars=no,width=800,height=500');
    win.focus();
  }
  showConfirmAlert = (url) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <div className="card-appstore">

              <h4>{i18n.t("This app opens in new window")}</h4>
              <div >{i18n.t("Do you want to continue ?")}</div>
              <div className="card-appstore-btns" style={{ position: 'relative', left: '70%' }}>
                <button type="button" className="btn-remove" style={{ width: '4rem', marginRight: '5px' }} onClick={() => {
                  this.handleNewTab(url);
                  onClose();
                }}>{i18n.t("Ok")}</button>
                <button type="button" className="btn-remove" style={{ width: '4rem' }} onClick={onClose}>{i18n.t("Cancel")}</button>

              </div>
            </div>
          </div>
        );
      }
    });
  }
  onClientTypeChange(e, data) {
    this.setState({ selectedClientType: data.value });
  }

  onClientPseudoTenantIdChange(data, index) {
    switch (this.state.selectedClientType) {
      case "tenantId":
        this.setState({ selectedTenantId: data.value, selectedPseudoName: this.state.pseudoNamesList[index].value, seletedClientName: this.state.clientNamesList[index].value });
        break;
      case "pseudo":
        this.setState({ selectedTenantId: this.state.tenantIdsList[index].value, selectedPseudoName: data.value, seletedClientName: this.state.clientNamesList[index].value });
        break;
      case "client":
        this.setState({ selectedTenantId: this.state.tenantIdsList[index].value, selectedPseudoName: this.state.pseudoNamesList[index].value, seletedClientName: data.value });
        break;
    }
  }
  onDeploymentTypeChange(e, data) {
    var val = data.value;
    this.setState({ deploymentType: val })
  }
  onOutputVarianceChange(e, data) {
    var val = data.value;
    this.setState({ outputVariance: val })
  }
  onApply() {
    this.loadDash('sisenseModal', this.state.currentDashboardUrl, this.props, "");
  }

  onReset() {
    this.setState({
      deploymentType: buttonFilters[0].data[0].value,
      outputVariance: buttonFilters[1].data[0].value,
      selectedClientType: "pseudo",
      seletedClientName: "all",
      selectedPseudoName: "all",
      selectedTenantId: "all"
    })
    this.loadDash('sisenseModal', this.state.currentDashboardUrl, this.props, "");
  }

  render() {

    const handleClose = () => this.setState({ showDashModal: false, maximizeIOJPopup: false, filterArrayTenantId:[this.state.filterArrayTenantId[0]] });
    const handleSisenseClose = () => this.setState({
      showSisenseModal: false, showReconFilter: false, showHRManagerFilter: false, showMaskedUnmasked: false,
      selectedClientType: "pseudo",
      seletedClientName: "all",
      selectedPseudoName: "all",
      selectedTenantId: "all", maximizeIOJPopup: false,
      showDashFiltModal: false, filterTextModal: "«"
    });
    const handlePowerBiClose = () => this.setState({ showPowerBiModal: false, maximizeIOJPopup: false });
    const handlePowerBiWithoutTokenClose = () => this.setState({ showPowerBiWithoutTokenBtn: false, maximizeIOJPopup: false });

    let showModal = () => {
      return (
        <Modal show={this.state.showDashModal} onHide={handleClose} dialogClassName={this.state.maximizeIOJPopup ? "maximizedIOJPopup" : "modal-90w"}>
          <Modal.Body className={this.state.maximizeIOJPopup ? "iframe-modal-increasedheight" : ""}>
            <FontAwesomeIcon className='maximizeIcondbContainer'
              style={{ cursor: "pointer" }}
              onClick={() => this.handleMaxMinPopup()}
              color={"#fff"}
              title={!this.state.maximizeIOJPopup ? "Maximize" : "Minimize"}
              icon={this.state.maximizeIOJPopup ? faCompressAlt : faExpandAlt} />
            <div id="dbModal" className={this.state.maximizeIOJPopup ? "db-container-maximized" : "db-container"}>
            </div>
            <div
              // style={{visibility:"hidden"}}
              className={this.state.showDashFiltModal ? "filtPaneShow" : "filtPane"}
              onClick={handleFiltModal}
            >
              {this.state.filterTextModal}
            </div>
            <div className={this.state.showDashFiltModal ? "filterPanel" : "filterPanelHide"}>
              <span>Filters</span><hr style={{ marginTop: "5px", marginBottom: "0px" }} />
              <div id="Drill2ModalFilter" className="filtContainer"></div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
    let showSisenseModal = () => {
      return (

        <Modal
          show={this.state.showSisenseModal}
          onHide={handleSisenseClose}
          backdrop="static"
          dialogClassName={this.state.maximizeIOJPopup ? "maximizedIOJPopup" : "modal-90w"}
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header
            closeButton={true}
            className="dashboard-modal-header"
          >
            {(this.state.showReconFilter || this.state.showHRManagerFilter || this.state.showMaskedUnmasked) && (<div className="css-filter-buttons align-right mt-20 mb-20 ioj-filter-buttons">
              {this.state.showMaskedUnmasked && (
                <div style={{ marginLeft: "5px" }}>
                  <h7 style={{ fontWeight: 600, color: "white" }}>Masked/Unmasked:&nbsp;&nbsp;</h7>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.selectedClientType}
                    onChange={(e) => this.setState({ selectedClientType: e.target.value })}
                  >
                    {
                      this.state.clientType.map((item) => {
                        return <MenuItem value={item.value}
                          onClick={(e) => this.onClientTypeChange(e, item)}>{item.label}</MenuItem>
                      })
                    }
                  </Select>
                </div>
              )}
              {/* {this.state.showMaskedUnmasked && this.state.selectedClientType =="tenantId" && (
              <div style={{ marginLeft: "5px" }}>
                <h7 style={{fontWeight: 600,color: "white"}}>Client:&nbsp;&nbsp;</h7>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.selectedTenantId}
                  onChange={(e) => this.setState({ selectedTenantId: e.target.value })}
                >
                  {
                    this.state.tenantIdsList.map((item, index) => {
                      return <MenuItem value={item.value}
                        onClick={(e) => this.onClientPseudoTenantIdChange(item, index)}>{item.label}</MenuItem>
                    })
                  }
                </Select>
              </div>
              )}  */}
              {this.state.showMaskedUnmasked && this.state.selectedClientType == "pseudo" && (
                <div style={{ marginLeft: "5px" }}>
                  <h7 style={{ fontWeight: 600, color: "white" }}>Client:&nbsp;&nbsp;</h7>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.selectedPseudoName}
                    onChange={(e) => this.setState({ selectedPseudoName: e.target.value })}
                  >
                    {
                      this.state.pseudoNamesList.map((item, index) => {
                        return <MenuItem value={item.value}
                          onClick={(e) => this.onClientPseudoTenantIdChange(item, index)}>{item.label}</MenuItem>
                      })
                    }
                  </Select>
                </div>
              )}
              {this.state.showMaskedUnmasked && this.state.selectedClientType == "client" && (
                <div style={{ marginLeft: "5px" }}>
                  <h7 style={{ fontWeight: 600, color: "white" }}>Client:&nbsp;&nbsp;</h7>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.seletedClientName}
                    onChange={(e) => this.setState({ seletedClientName: e.target.value })}
                  >
                    {
                      this.state.clientNamesList.map((item, index) => {
                        return <MenuItem value={item.value}
                          onClick={(e) => this.onClientPseudoTenantIdChange(item, index)}>{item.label}</MenuItem>
                      })
                    }
                  </Select>
                </div>
              )}

              {this.state.showReconFilter && (
                <div style={{ marginLeft: "5px" }}>
                  <h7 style={{ fontWeight: 600, color: "white" }}>Deployment Type:&nbsp;&nbsp;</h7>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.deploymentType}
                    onChange={(e) => this.setState({ deploymentType: e.target.value })}
                  >
                    {
                      buttonFilters[0].data.map((item) => {
                        return <MenuItem value={item.value}
                          onClick={(e) => this.onDeploymentTypeChange(e, item)}>{item.label}</MenuItem>
                      })
                    }
                  </Select>
                </div>
              )}
              {this.state.showHRManagerFilter && (<div style={{ marginLeft: "5px" }}>
                <h7 style={{ fontWeight: 600, color: "white" }}>Output Variance:&nbsp;&nbsp;</h7>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.outputVariance}
                  onChange={(e) => this.setState({ outputVariance: e.target.value })}
                >
                  {
                    buttonFilters[1].data.map((item) => {
                      return <MenuItem value={item.value}
                        onClick={(e) => this.onOutputVarianceChange(e, item)}>{item.label}</MenuItem>
                    })
                  }
                </Select>
              </div>
              )}
              <div style={{ marginLeft: "5px" }}>
                <button style={{ cursor: "pointer", borderStyle: "none" }} className="hmcc-drop-btn applyBtn" onClick={() => this.onApply()}>{i18n.t("Apply")}</button>
              </div>
              <div >
                <button style={{ cursor: "pointer", borderStyle: "none" }} className="hmcc-drop-btn applyBtn" onClick={() => this.onReset()} >{i18n.t("Reset")}</button>
              </div>

            </div>
            )}
            <FontAwesomeIcon className='maximizeIconIOJPopup'
              style={{ cursor: "pointer" }}
              onClick={() => this.handleMaxMinPopup()}
              color={"#fff"}
              title={!this.state.maximizeIOJPopup ? "Maximize" : "Minimize"}
              icon={this.state.maximizeIOJPopup ? faCompressAlt : faExpandAlt} />
          </Modal.Header>
          <Modal.Body className={this.state.maximizeIOJPopup ? "iframe-modal-increasedheight" : "iframe-modal-body"}>
            <div>
              {this.props.isConnected ?
                <div id="sisenseModal" className="db-container">
                </div>
                :
                <div className='d-flex flex-column'>
                  <img

                    src={Logo}
                    alt="logo"
                    className="rotate align-self-center"
                    style={{ width: "137px" }}
                  />
                  <div className="ml-10 align-self-center">Connecting...</div>
                </div>
              }
              <div
                // style={{visibility:"hidden"}}
                className={this.state.showDashFiltModal ? "filtPaneShow" : "filtPane"}
                onClick={handleFiltModal}>{this.state.filterTextModal}</div>
              <div 
                className={this.state.showDashFiltModal ? "filterPanel" : "filterPanelHide"}
              >
                <span>Filters</span><hr style={{ marginTop: "5px", marginBottom: "0px" }} />
                <div id="SisenseModalFilter" className="filtContainer"></div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
    let showPowerBiModal = () => {
      return (

        <Modal
          show={this.state.showPowerBiModal}
          onHide={handlePowerBiClose}
          backdrop="static"
          dialogClassName={this.state.maximizeIOJPopup ? "maximizedIOJPopup" : "modal-90w"}
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header
            closeButton={true}
            className="dashboard-modal-header"
          >
            <FontAwesomeIcon className='maximizeIconIOJPopup' style={{ cursor: "pointer" }}
              onClick={() => this.handleMaxMinPopup()}
              color={"#fff"}
              title={!this.state.maximizeIOJPopup ? "Maximize" : "Minimize"}
              icon={this.state.maximizeIOJPopup ? faCompressAlt : faExpandAlt} />
          </Modal.Header>
          <Modal.Body className={this.state.maximizeIOJPopup ? "iframe-modal-increasedheight" : "iframe-modal-body"}>
            <div className={this.state.maximizeIOJPopup ? "db-container-powerbi-maximized" : "db-container-powerbi"}><PowerBIEmbed
              embedConfig={{
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: this.state.powerBiReportIdModal,
                embedUrl: this.state.powerBiEmbedUrlModal,
                accessToken: null,    // Keep as empty string, null or undefined
                tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                filters: [this.state.powerBiFilterObj],
                settings: {
                  panes: {
                    bookmarks: {
                      visible: false
                    },
                    fields: {
                      expanded: false
                    },
                    filters: {
                      expanded: false,
                      visible: true
                    },
                    pageNavigation: {
                      visible: false
                    },
                    selection: {
                      visible: true
                    },
                    syncSlicers: {
                      visible: true
                    },
                    visualizations: {
                      expanded: false
                    }
                  }
                }
              }}
              cssClassName={`${this.state.maximizeIOJDashboard ? "subtab-iframe-maximized " : "subtab-iframe "}  power-iframe power-bi-frame`}
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
                this.props.updatePBToken();
              }}
            />
            </div>
          </Modal.Body>
        </Modal>
      );
    }
    let showPowerBiWithoutTokenModal = () => {
      return (

        <Modal
          show={this.state.showPowerBiWithoutTokenBtn}
          onHide={handlePowerBiWithoutTokenClose}
          backdrop="static"
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header
            closeButton={true}
            className="dashboard-modal-header"
          ></Modal.Header>
          <Modal.Body className="iframe-modal-body">
            <div className="db-container-powerbi"><PowerBIEmbed
              embedConfig={{
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: null,
                embedUrl: this.state.powerBidirectUrlBtn,
                accessToken: null,    // Keep as empty string, null or undefined
                tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                settings: {
                  panes: {
                    bookmarks: {
                      visible: false
                    },
                    fields: {
                      expanded: false
                    },
                    filters: {
                      expanded: false,
                      visible: true
                    },
                    pageNavigation: {
                      visible: false
                    },
                    selection: {
                      visible: true
                    },
                    syncSlicers: {
                      visible: true
                    },
                    visualizations: {
                      expanded: false
                    }
                  }
                }
              }}
              cssClassName={"subtab-iframe power-iframe power-bi-frame"}
            />
            </div>
          </Modal.Body>
        </Modal>
      );
    }
    let compare = (a, b) => {
      return a.position - b.position;
    };
    //Common url buttons component for all the sub tabs
    let activeUrlButtons = this.state.activeUrlButtons
      .sort(compare)
      .map((btn) => (
        <button
          className="hmcc-drop-btn btn-center position-relative"
          style={{ cursor: "pointer" }}
          onClick={() => this.handleUrlButton(btn)}
        >
          {i18n.t(btn.displayName)}
        </button>
      ));



    const handleFilt = () => {
      if (this.state.showDashFilt) {
        this.setState({ showDashFilt: false, filterText: "«" })
      } else {
        this.setState({ showDashFilt: true, filterText: "»" })
      }
    }
    const handleFiltModal = () => {
      if (this.state.showDashFiltModal) {
        this.setState({ showDashFiltModal: false, filterTextModal: "«" })
      } else {
        this.setState({ showDashFiltModal: true, filterTextModal: "»" })
      }
    }
    const sisenseDashboard = () => {
      return this.state.allSubTabs.map((db, i) => {
        if (db.url) {
          let isSisenseUrl = true;
          let isPowerBiUrl = true;
          isSisenseUrl = db.visualizationType == "sisense" || db.visualizationType == null;
          isPowerBiUrl = db.visualizationType == "powerbi";

          if (db.isOpenInNewWindow) {
            return (
              <div
                data-test="testDashboardContainer"
                id={db.id}
                key={db.id}
                style={
                  this.state.sisenseStyle.length > 0
                    ? this.state.sisenseStyle.filter((p) => p.id === db.id)[0]
                      ? this.state.sisenseStyle.filter((q) => q.id === db.id)[0]
                        .style
                      : { display: "none" }
                    : { display: "none" }
                }
                className={activeUrlButtons.length > 0 ?
                  `hide-overflow ${!this.state.maximizeIOJDashboard ? "subtab-height" : "subtab-height-maxmizeIOJDashboard"}  ${this.state.filterApplied ? "change-width" : " "}` :
                  `hide-overflow ${!this.state.maximizeIOJDashboard ? "subtab-height1" : "subtab-height1-maxmizeIOJDashboard"}  ${this.state.filterApplied ? "change-width" : " "
                  }`}
              >
                <span className="new-tab-message">{i18n.t("This App opens in separate window")}</span>
              </div>
            );

          } else if (isSisenseUrl) {
            let dashboardId = db.url.split("/");
            let dashId = dashboardId[dashboardId.length - 1];
            let filterId = "filter" + dashId;

            return (
              <div>
                <div
                  data-test="testDashboardContainer"
                  id={dashId}
                  key={db.id}
                  style={
                    this.state.sisenseStyle.length > 0
                      ? this.state.sisenseStyle.filter((o) => o.id === dashId)[0]
                        ? this.state.sisenseStyle.filter((o) => o.id === dashId)[0]
                          .style
                        : { display: "none" }
                      : { display: "none" }
                  }
                  className={activeUrlButtons.length > 0 ?
                    `${!this.state.maximizeIOJDashboard ? "subtab-height " : "subtab-height-maxmizeIOJDashboard "} ${this.state.filterApplied ? "change-width " : " "}` :
                    `${!this.state.maximizeIOJDashboard ? "subtab-height1 " : "subtab-height1-maxmizeIOJDashboard "} ${this.state.filterApplied ? "change-width " : " "}`}
                >
                </div>
                <div
                    // style={{visibility:"hidden"}} 
                    style={{ visibility: this.state.isSisenseSubtab ? "visible" : "hidden" }}
                    className={this.state.showDashFilt ? "filtPaneShow" : "filtPane"} onClick={handleFilt}>{this.state.filterText}</div>
                  <div 
                    className={this.state.showDashFilt ? `${activeUrlButtons.length > 0 ?
                      `${!this.state.maximizeIOJDashboard ? "filterPanel" : "filtPanMax filterPanel"}` :
                      `${!this.state.maximizeIOJDashboard ? "withoutButton filterPanel" : "withoutButton filtPanMax1 filterPanel"}`}` : "filterPanelHide"}
                  >
                    <span>Filters</span><hr style={{ marginTop: "5px", marginBottom: "0px" }} />
                    <div id={filterId} 
                      className="filtContainer"
                    style={
                      this.state.sisenseStyle.length > 0
                        ? this.state.sisenseStyle.filter((o) => o.id === dashId)[0]
                          ? this.state.sisenseStyle.filter((o) => o.id === dashId)[0]
                            .style
                          : { display: "none" }
                        : { display: "none" }
                    }></div>
                  </div>
              </div>
            );
          } else if (!isPowerBiUrl || !this.props.userDetails.isPowerBIEnabled) {
            return (
              <div
                data-test="testDashboardContainer"
                id={db.id}
                key={db.id}
                style={
                  this.state.sisenseStyle.length > 0
                    ? this.state.sisenseStyle.filter((p) => p.id === db.id)[0]
                      ? this.state.sisenseStyle.filter((q) => q.id === db.id)[0]
                        .style
                      : { display: "none" }
                    : { display: "none" }
                }
                className={activeUrlButtons.length > 0 ?
                  `${!this.state.maximizeIOJDashboard ? "subtab-height " : "subtab-height-maxmizeIOJDashboard "} ${this.state.filterApplied ? "change-width" : " "}` :
                  `${!this.state.maximizeIOJDashboard ? "subtab-height1 " : "subtab-height1-maxmizeIOJDashboard "} ${this.state.filterApplied ? "change-width" : " "
                  }`}
              >
                {
                  (this.state.sisenseStyle.length > 0 && this.state.activeSubTab == db.displayName)
                    ? this.state.sisenseStyle.filter((p) => p.id === db.id)[0]
                      ? 
                      (this.state.sisenseStyle.filter((q) => q.id === db.id)[0].style.display != "none" && <iframe
                        className={this.state.maximizeIOJDashboard ? "subtab-iframe-maximized" : "subtab-iframe"}
                        src={this.state.sisenseStyle.length > 0
                          ? this.state.sisenseStyle.filter((o) => o.id === db.id)[0]
                            ? this.state.sisenseStyle.filter((o) => o.id === db.id)[0]
                              .url
                            : db.url
                          : db.url}
                      ></iframe>)
                      : null
                    : null
                }
              </div>
            );
          }

        }
      });
    };

    let hideModal = () => {
      this.setState({ showModal: false, maximizeIOJPopup: false });
    };
    return (
      <div data-test="intOpsComponent">
        {/*   TileSection1 Start  */}
        <section className="py-2 ">
          {/*   screen 1  */}
          <div className="container-fluid px-4">
            <div className="row ">
              <div className="col-lg-12">
                {/*   TileSection1 Start  */}
                <ul id="tabs" className="nav" style={{ marginTop: (this.props.app.clients.length > 0) ? '0px' : '.5rem', cursor: "pointer" }}>
                  <li
                    className={
                      this.state.tabParentId == 1
                        ? "tab-button-m highlight"
                        : "tab-button-m"
                    }
                  >
                    <a data-test="testFoundation" onClick={() => this.handleTabChange(1, 0)}>
                      <div className="tab-button intops-tab-button">
                        <div className="hm-d-flex align-items-center new-intops-button-tabs">
                          <img
                            className="mr-2"
                            src={require("../../Images/foundation.svg")}
                          />
                          <h6>
                            {
                              i18n.t(this.state.mainTabs.find(
                                (element) => element.position == 1
                              ).displayName)
                            }
                          </h6>
                        </div>
                        <div>
                          <img
                            className="down-arrow"
                            src={require("../../Images/down-arrow.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </li>

                  <li
                    className={
                      this.state.tabParentId == 2
                        ? "nav-item tab-button-m highlight"
                        : "nav-item tab-button-m"
                    }
                  >
                    <a onClick={() => this.handleTabChange(2, 0)}>
                      <div className="tab-button intops-tab-button">
                        <div className="hm-d-flex align-items-center new-intops-button-tabs">
                          <img
                            className="mr-2"
                            src={require("../../Images/automated-icon.svg")}
                          />
                          <h6>
                            {
                              i18n.t(this.state.mainTabs.find(
                                (element) => element.position == 2
                              ).displayName)
                            }
                          </h6>
                        </div>
                        <div>
                          <img
                            className="down-arrow"
                            src={require("../../Images/down-arrow.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.tabParentId == 3
                        ? "nav-item tab-button-m highlight"
                        : "nav-item tab-button-m"
                    }
                  >
                    <a onClick={() => this.handleTabChange(3, 0)}>
                      <div className="tab-button intops-tab-button">
                        <div className="hm-d-flex align-items-center new-intops-button-tabs">
                          <img
                            className="mr-2"
                            src={require("../../Images/insightdriven-icon.svg")}
                          />
                          <h6>
                            {" "}
                            {
                              i18n.t(this.state.mainTabs.find(
                                (element) => element.position == 3
                              ).displayName)
                            }
                          </h6>
                        </div>
                        <div>
                          <img
                            className="down-arrow"
                            src={require("../../Images/down-arrow.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.tabParentId == 4
                        ? "nav-item tab-button-m highlight"
                        : "nav-item tab-button-m"
                    }
                  >
                    <a onClick={() => this.handleTabChange(4, 0)}>
                      <div className="tab-button intops-tab-button">
                        <div className="hm-d-flex align-items-center new-intops-button-tabs">
                          <img
                            className="mr-2"
                            src={require("../../Images/intelligent-icon.svg")}
                          />
                          <h6>
                            {" "}
                            {
                              i18n.t(this.state.mainTabs.find(
                                (element) => element.position == 4
                              ).displayName)
                            }
                          </h6>
                        </div>
                        <div>
                          <img
                            className="down-arrow"
                            src={require("../../Images/down-arrow.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <section
            id="tabsContent"
            className="tab-content"
            style={{ display: this.state.showDefault ? "block" : "" }}
          >
            {/*   Default tab  */}
            {this.state.showDefault && (
              <section className="tab-pane fade show active">
                <div className="container" style={{ maxWidth: "97vw" }}>
                  <div className="row ">
                    <div className="col-sm-12 ">
                      <div className="text-center py-4">
                        <img
                          src={require("../../Images/Flower.png")}
                          className="hmcc-w"
                          alt=""
                        />
                        <h2 className="py-2 text-white">
                          {i18n.t('Synops Hub')}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/*   ALL TABS   */}

            <section
              id="foundation"
              className="tab-pane fade show active"
              style={{ display: this.state.showDefault ? "none" : "block" }}
            >
              <div className="container" style={{ maxWidth: "97vw" }}>
                <div className="new-subTablist">
                  <nav id="newSubTablist" className="">
                    <div className="row">
                      {this.state.subTabs ?
                        <Tabs
                          id='intOpsTabs'
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                        >
                          {this.state.subTabs
                            .filter((item) => item.parentID == this.state.tabParentId)
                            .sort(compare)
                            .map((subTab) => (
                              <div className="position-relative">
                                <div
                                  data-tip
                                  data-for={subTab.displayName}
                                  //title={i18n.t(subTab.toolTipText)}
                                  className={
                                    this.state.activeSubTab == subTab.displayName
                                      ? "newTab-text newTab-text-active "
                                      : "newTab-text "
                                  }
                                  onClick={() => this.setSubTab(subTab)}
                                >
                                  {i18n.t(subTab.displayName)}
                                </div>
                                {/* {subTab.toolTipText ?
                            <div className="tooltiptext">{i18n.t(subTab.toolTipText)}</div>
                             : ""
                          } */}
                                <ReactTooltip
                                  id={subTab.displayName}
                                  place="bottom"
                                  type="light"
                                  effect="solid"
                                  className='reactToolTipClient overrideMe'
                                  multiline
                                  html={false}
                                  getContent={() => subTab.toolTipText}
                                />
                              </div>
                            ))}
                        </Tabs> : ""}
                    </div>
                  </nav>
                </div>
                <div className="row m-mx-0">

                  <div className="col-sm-12 px-0">
                    <div className={`data-container text-white ${this.state.maximizeIOJDashboard ? " maximizedIOJDashboard" : ""}`}>
                      <FontAwesomeIcon className={activeUrlButtons.length > 0 ? 'float-right' : 'float-right maxminIconAlignment'} style={{ cursor: "pointer" }}
                        onClick={() => this.handleMaxMin()}
                        color={"#fff"}
                        title={!this.state.maximizeIOJDashboard ? "Maximize" : "Minimize"}
                        icon={this.state.maximizeIOJDashboard ? faCompressAlt : faExpandAlt} />
                      <div className="hm2-d-flex">
                        {
                          (this.state.subTabs.filter((item) => item.parentID == this.state.tabParentId).length > 0) ?
                            <Tabs
                              id='intOpsScrollBtns'
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                              scrollButtons="auto"
                              aria-label="scrollable auto tabs example"
                            >
                              {activeUrlButtons}
                            </Tabs> : ""}
                      </div>
                      {
                        (this.state.subTabs.filter((item) => item.parentID == this.state.tabParentId).length > 0) ?

                          <div className={activeUrlButtons.length > 0 ? "row-divider" : ""}></div> : ""}
                      <div className="inner-wo-scroll" style={{ backgroundColor: !this.props.isConnected && 'rgb(2, 32, 112)' }}>

                        {!this.state.showPowerBi && !this.state.showPowerBiWithoutToken && (
                          (this.state.subTabs.filter((item) => item.parentID == this.state.tabParentId).length > 0) ?
                            (this.props.isConnected ? sisenseDashboard() :
                              <div className='d-flex flex-column'>
                                <img
                                  src={Logo}
                                  alt="logo"
                                  className="rotate align-self-center"
                                  style={{ width: "137px" }}
                                />
                                <div className="ml-10 align-self-center">Connecting...</div>
                              </div>
                            )
                            :
                            <h5 style={{ textAlign: "center", color: "white", marginTop: "8rem" }}>{i18n.t("No data available")}</h5>
                        )}

                        {this.state.showPowerBi && (
                          <div
                            id={this.state.powerBiReportId}
                            key={this.state.powerBiReportId}
                            className={activeUrlButtons.length > 0 ?
                              `${!this.state.maximizeIOJDashboard ? "subtab-height " : "subtab-height-maxmizeIOJDashboard "} ${this.state.filterApplied ? "change-width" : " "}` :
                              `${!this.state.maximizeIOJDashboard ? "subtab-height1 " : "subtab-height1-maxmizeIOJDashboard "} ${this.state.filterApplied ? "change-width" : " "
                              }`}
                          >
                            <PowerBIEmbed
                              embedConfig={{
                                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                                id: this.state.powerBiReportId,
                                embedUrl: this.state.powerBiEmbedUrl,
                                accessToken: null,    // Keep as empty string, null or undefined
                                tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                                filters: [this.state.powerBiFilterObj],
                                settings: {
                                  panes: {
                                    bookmarks: {
                                      visible: false
                                    },
                                    fields: {
                                      expanded: false
                                    },
                                    filters: {
                                      expanded: false,
                                      visible: true
                                    },
                                    pageNavigation: {
                                      visible: false
                                    },
                                    selection: {
                                      visible: true
                                    },
                                    syncSlicers: {
                                      visible: true
                                    },
                                    visualizations: {
                                      expanded: false
                                    }
                                  }
                                }
                              }}
                              cssClassName={`${this.state.maximizeIOJDashboard ? "subtab-iframe-maximized " : "subtab-iframe "}  power-iframe power-bi-frame`}
                              getEmbeddedComponent={(embeddedReport) => {
                                window.report = embeddedReport;
                                this.props.updatePBToken();
                              }}
                            />
                          </div>
                        )}

                        {this.state.showPowerBiWithoutToken && (
                          <div
                            key={this.state.powerBidirectUrl}
                            className={activeUrlButtons.length > 0 ?
                                `${!this.state.maximizeIOJDashboard ? "subtab-height " : "subtab-height-maxmizeIOJDashboard "} ${this.state.filterApplied ? "change-width" : " "}` :
                                `${!this.state.maximizeIOJDashboard ? "subtab-height1 " : "subtab-height1-maxmizeIOJDashboard "} ${this.state.filterApplied ? "change-width" : " "
                                }`} 
                          >
                            <PowerBIEmbed
                              embedConfig={{
                                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                                id: null,
                                embedUrl: this.state.powerBidirectUrl,
                                accessToken: null,    // Keep as empty string, null or undefined
                                tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                                settings: {
                                  panes: {
                                    bookmarks: {
                                      visible: false
                                    },
                                    fields: {
                                      expanded: false
                                    },
                                    filters: {
                                      expanded: false,
                                      visible: true
                                    },
                                    pageNavigation: {
                                      visible: false
                                    },
                                    selection: {
                                      visible: true
                                    },
                                    syncSlicers: {
                                      visible: true
                                    },
                                    visualizations: {
                                      expanded: false
                                    }
                                  }
                                }
                              }}
                              cssClassName={"subtab-iframe power-iframe power-bi-frame"}
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={this.state.showLoader ? "loading" : "hidden"}
                      >
                        <img
                          className="loader-size"
                          src={require("../../Images/WO-background.gif")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </section>
          {/*   TileSection1 End  */}
        </section>


        <Modal
          show={this.state.showModal}
          onHide={() => hideModal()}
          backdrop="static"
          dialogClassName={this.state.maximizeIOJPopup ? "maximizedIOJPopup" : "modal-90w"}
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header
            closeButton={true}
            className="dashboard-modal-header"
          >
            <FontAwesomeIcon className='maximizeIconIOJPopup' style={{ cursor: "pointer" }}
              onClick={() => this.handleMaxMinPopup()}
              color={"#fff"}
              title={!this.state.maximizeIOJPopup ? "Maximize" : "Minimize"}
              icon={this.state.maximizeIOJPopup ? faCompressAlt : faExpandAlt} />
          </Modal.Header>
          <Modal.Body className={this.state.maximizeIOJPopup ? "iframe-modal-increasedheight" : "iframe-modal-body"}>
            <iframe
              className={this.state.maximizeIOJPopup ? "modal-iframe-maximized" : "modal-iframe"}
              src={this.state.currentDashboardUrl}
            ></iframe>
          </Modal.Body>
        </Modal>
        {showModal()}
        {showSisenseModal()}
        {showPowerBiModal()}
        {showPowerBiWithoutTokenModal()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.appReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenuDisplay: (boolean) => {
      dispatch(toggleMenuDisplay(boolean));
    },
    setFilters: (obj) => {
      dispatch(setFilters(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntOps);
