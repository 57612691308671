import React, { Suspense } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import jwt from 'jsonwebtoken';
import Select from "react-select";
import axios from "axios";
import { connect } from "react-redux";
import { Card, Button, Form, Tabs, Tab , OverlayTrigger, Tooltip } from "react-bootstrap";
import feedback1 from '../src/Images/Feedback1.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faFileDownload,faPlusCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { toggleMenuDisplay, setFilters, setClients, setUserPreferedLanguage, setLanguageTranslations, setDemoClient } from "./actions/AppActions";
import logo from "./logo.svg";
import "./App.css";
import "./css/styles.css";
import "./css/bootstrap.min.css";
import "./css/fonts/fonts.css";
import HumanAndMachine from "./components/HumanAndMachine/HumanAndMachine";
import IntOps from "./components/IntOps/IntOps";
import Operations from "./components/Operations/Operations";
import BusinessOutcome from "./components/BusinessOutcome/BusinessOutcome";
import AppStore from "./components/AppStore/AppStore";
import ClientFilter from './components/Header/ClientFilter';
import signal from '../src/Images/signal.svg';
import signalSelected from '../src/Images/signal_selected.svg';
import general from '../src/Images/General.svg';
import generalSelected from '../src/Images/General_Selected.svg';
import newIdea from '../src/Images/FeedbackSupport.svg';
import newIdeaSelected from '../src/Images/feedselected.svg';
import feedbackSaved from '../src/Images/check-circle-regular.svg';
import { BASE_URL, MAC_ID, ADMIN, ENVIRONMENT, HOMEPAGE_URL, ENTERPRISE_ID, APP_VERSION } from "./StringConstants";
//import * as api from "./services/API";
import * as api from '../src/services/apiAction'
//import AuthContext from "./services/Auth";
import { Modal } from "react-bootstrap";
//import {BootstrapTable,TableHeaderColumn} from "react-bootstrap-table-next";
import sisenseSettings from "./config/Sisense";
import {
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import IdleTimer from 'react-idle-timer'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Connecting from "./Connecting";
import feedback from '../src/Images/Feedback.svg';
import dateFormat from 'dateformat';
import { loginRequest, loginRequestPowerBi } from '../src/services/authProvider'
import TextareaAutosize from "react-textarea-autosize";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { withMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, BrowserUtils } from '@azure/msal-browser'
// import * as TFOPing from './TFO_Ping';
import { msalInstance } from '../src/index';

// const HumanAndMachine = React.lazy(() => import('./components/HumanAndMachine/HumanAndMachine'));
// const IntOps = React.lazy(() => import('./components/IntOps/IntOps'));
// const Operations = React.lazy(() => import('./components/Operations/Operations'));
// const BusinessOutcome = React.lazy(() => import('./components/BusinessOutcome/BusinessOutcome'));
// const AppStore = React.lazy(() => import('./components/AppStore/AppStore'));
// const Connecting = React.lazy(() => import('./Connecting'));
// const ClientFilter = React.lazy(() => import('./components/Header/ClientFilter'));

const { ExportCSVButton } = CSVExport;

const guid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}

const sisenseIsLoaded = () => {
  return ((typeof window.Sisense !== "undefined") && (typeof window.prism !== "undefined"));
}
//var fileSize = 0;
// var ReactBsTable  = require('react-bootstrap-table');
// var BootstrapTable = ReactBsTable.BootstrapTable;
// var TableHeaderColumn = ReactBsTable.TableHeaderColumn;


class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tab1Config: [],
      tab2Config: [],
      tab3Config: [],
      tab4Config: [],
      tab5Config: [],
      showMenu: true,
      isValidLicense: false,
      userDetails: {},
      defaultRoute: "",
      invalidUser: false,
      licenseChecked: false,
      showHumanAndMachine: false,
      showIntOpsJourney: false,
      showOperations: false,
      drillDownID:"",
      showBusinessOutcome: false,
      showAppStore: false,
      showModal: false,
      currentApp: null,
      isConnected: ENVIRONMENT === 'local', //Change this to false before building - LOCAL
      count: 0,
      showAppStoreHome: true,
      selectClientHolderDiv: true,
      hideProfileHolderDiv: true,
      hideAnnouncementHolderDiv: true,
      selectedClient: {},
      isLoggingEnabled: false,
      showAnnouncements: true,
      prevLoggedInEnabled: false, //for prev tenant selected
      activeSubTabId: 0,
      activeSectionView: '',
      activeAppId: 0,
      lastUserEngagementRecord: {},
      tabSelected: '',
      isCheckedTheme: true,
      languageList: [],
      languageListFiltered: [],
      searchedLanguageText: '',
      selectedLanguage: 'Select Language',
      profileName: "",
      showProfile: false,
      isSisenseConnectCalled: false,
      showHMtab: true,
      announcements: [],
      showFeedbackIcon: true,
      showFeedbackModal: false,
      type: 'general',
      feedbackSelected: null,
      popupAnnouncement: false,
      openedDropdown: "",
      newIdeaSuggestions: [{ label: 'Command Center', value: 'Command Center' }],
      LoggedInUser: {},
      sisenceConnectionError: false,
      hideFilterHolderDiv: false,
      showConfPopup: true,
      feedbackSubmitted: false,
      showConfidentialModal: true,
      openSubmit: false,
      feedbackSend: false,
      alertsData: [],
      dismissedData: [],
      dismissedAllData: [],
      allowAnnouncementAutoPopup: localStorage.getItem('announcementChecked') === 'true' ? true : false,
      popupChecked: false,
      alertObject:{},
      selectedTab:"Alert",
      redirectedFromAlert:0,
      messageType: "reportanissue",
      popUpText: "issue report",
      submittedIssue: "Issue Report",
      fileCount: 0,
      totalfileCountError: false,
      commentText: "Please provide the issue details here.",
      selectedFile: [],
      fileSizeError: false,
      fileExtensionError: false, 
      duplicateFileErrorMsg: false,
      feedbackBody2:"",
      totalfileCountError2: false,
      showRequestAccessModal:false,
      requestAccessCommentText:"Please provide the User E-mail, Client name & Purpose of access.",
      selectedFile2: [],
      fileSizeError2: false,
      fileExtensionError2: false, 
      duplicateFileErrorMsg2: false,
      showSuccessPopup:false
					
    };
    this.idleTimer = null

  }

  clearDrillDownID =() =>{
    this.setState({drillDownID:""});
  }
  parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };
  delay = () => {
    // `delay` returns a promise
    return new Promise(function (resolve, reject) {
      // Only `delay` is able to resolve or reject the promise
      setTimeout(function () {
        resolve(42); // After 3 seconds, resolve the promise with value 42
      }, 2000);
    });
  }
  updatePBToken = async () => {
    await this.delay();
    // Set the new access token
    if (window.report) {
      await window.report.setAccessToken(localStorage.getItem("access_token_powerbi"));
      await window.report.reload();
      let reportList = document.getElementsByClassName("power-bi-frame");
      if (reportList.length > 0) {
        for (let p = 0; p < reportList.length; p++) {
          reportList[p].querySelector("iframe").src = reportList[p].querySelector("iframe").src;
        }
      }
    }

  }

  refreshPowerBi = () => {

    const msalInstance = this.props.msalContext.instance;

    //powerbi access token
    if (this.props.msalContext.accounts.length > 0) {
      const account = this.props.msalContext.accounts[0];
      msalInstance.acquireTokenSilent({
        ...loginRequestPowerBi,
        account: account
      }).then((response) => {
      //  console.log("PowerBi token renewed with popup:" + JSON.stringify(response));
        localStorage.removeItem("access_token_powerbi");
        localStorage.setItem("access_token_powerbi", response.accessToken);
        this.updatePBToken();
      }).catch(err => {
        //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
        //always check if the error is something that requires auth login interaction 
        if (err instanceof InteractionRequiredAuthError) {
          return msalInstance.acquireTokenPopup({
            ...loginRequestPowerBi,
            account: account
          }).then((response) => {
         //   console.log("PowerBi token renewed with popup:" + JSON.stringify(response));
            localStorage.removeItem("access_token_powerbi");
            localStorage.setItem("access_token_powerbi", response.accessToken);
            this.updatePBToken();
          }).catch(err => {
        //    console.log('Error getting popup token: ' + JSON.stringify(err))
          });
        }
      });
    }
  }



  getAnnouncements = () => {
    api.get("announcements/1").then((resp) => {
      if (resp.length > 0) {
        this.setState({ announcements: resp });
      } else {
        this.setState({ showHMtab: false, announcements: [] });
      }
    });
  }

  getAlertData = (tenantId) => {
     tenantId = this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0 ;
    api.get(`notificationalerts/${this.props.app.demoClient?this.props.app.parentTenantID:tenantId}`).then((res) => {
      if (res.length > 0) {
        this.setState({ alertsData: res });
      }
      else {
        this.setState({ alertsData: [] });
      }
    });
  }
  
   getDismissedData = (rowId) =>{
    let tenantId = this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0 ;
    api.get(`dismissalerts/${rowId}/${tenantId}/`).then((res) => {
      if (res.length > 0) {
       this.setState({alertsData: res});
      }
      else {
        this.setState({alertsData: []});
      }
    });
   }
   getDismissedAllData = () =>{
    let tenantid = this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0 ;
    api.get(`dismissallalerts/`+ tenantid).then((res) => {
      if (res.length > 0) {
        this.setState({ alertsData: res});
       }
       else {
        if(this.state.announcements.length != 0){
          this.setState({ alertsData: [], selectedTab:"Announcement"});
        }else{
          this.setState({ alertsData: [], 
                          selectedTab:"",
                          hideAnnouncementHolderDiv: true, 
                          showAnnouncements: false});
        }
       }
     });
   }
   sendFeedback = (desc, messageType, file) => {
    let formData = new FormData();
    if (file.length > 0) {
    
       this.state.selectedFile.map((item, i) => {
         formData.append("file" + i, item,item.name);
       })
    }
    formData.append("messageType",messageType);
    formData.append("description",desc);
    api.post(`emailmessages`,formData,true).then((resp) => {
      if (resp.length > 0) {
        this.setState({ feedbackSend: resp });
      }
    });
  }
  sendFeedback2 = (desc, messageType, file) => {
    let formData = new FormData();
    if (file.length > 0) {
    
       this.state.selectedFile2.map((item, i) => {
         formData.append("file" + i, item,item.name);
       })
    }
    formData.append("messageType",messageType);
    formData.append("description",desc);
    api.post(`emailmessages`,formData,true).then((resp) => {
      if (resp > 0 || resp.length > 0) {
        this.setState({ feedbackSend: resp, showSuccessPopup:true});
      }
    });
  }

  showAnnouncement = (id, checked) => {
    api.get(`announcements/allowpopup/${id}/CommandCenter/${checked}`).then((resp) => {
      if (resp.length > 0) {
        this.setState({ popupAnnouncement: !checked , popupChecked: true})
      }
    });
  }

  handleFile = (e) => {
    e.preventDefault();
    let images = e.target.files;
    var i;
    this.setState({
      fileSizeError: false,
      fileExtensionError: false,
      totalfileCountError: false,
      duplicateFileErrorMsg: false
    });
    if (images) {
      if(this.state.selectedFile.length + e.target.files.length > 3){
        this.setState({totalfileCountError: true});
        return;
      }
      var allFileSizes = 0;
     
      for (i = 0; i < e.target.files.length; i++) {
        var repeat = this.state.selectedFile.filter(a => {
          if (a.name === images[i].name) {
            return a;
          }
        })
        this.state.selectedFile.map(a => {
          let size = (a.size / 1048576);
          allFileSizes = allFileSizes + size;
  
        });
        var fileSize = (images[i].size / 1048576);
        if (!images[i].name.match(/.(jpg|png|pdf|docx)$/i)) {
          this.setState({ fileExtensionError: true });
        }
        else if (fileSize + allFileSizes > 15) {
          this.setState({ fileSizeError: true });
        }
        else if(repeat.length > 0){
          this.setState({
            duplicateFileErrorMsg: true
          })
        }
        else {
          this.state.selectedFile.push(images[i]);

          //  this.state.fileContent.push(images[i]);
          
        }

      }
    }
    if (!this.state.fileExtensionError && !this.state.fileSizeError && !this.state.totalfileCountError && !this.state.duplicateFileErrorMsg) {
      this.setState({
        selectedFile: this.state.selectedFile,

      })
    }
    e.target.value = '';
  };

  deleteIcon = (e) => {
    var index = e;
    if (index !== -1) {
      this.state.selectedFile.splice(index, 1);
      this.setState({ selectedFile: this.state.selectedFile });
    }
    if(this.state.selectedFile.length <= 3){
      this.setState({
        totalfileCountError: false
      })
    }
    if(this.state.selectedFile.length == 0){
      this.setState({
        fileSizeError: false,
        fileExtensionError: false
      })
    }
  };
  handleRequestFile = (e) => {
    e.preventDefault();
    let images = e.target.files;
    var i;
    this.setState({
      fileSizeError2: false,
      fileExtensionError2: false,
      totalfileCountError2: false,
      duplicateFileErrorMsg2: false
    });
    if (images) {
      if(this.state.selectedFile2.length + e.target.files.length > 3){
        this.setState({totalfileCountError2: true});
        return;
      }
      var allFileSizes = 0;
     
      for (i = 0; i < e.target.files.length; i++) {
        var repeat = this.state.selectedFile2.filter(a => {
          if (a.name === images[i].name) {
            return a;
          }
        })
        this.state.selectedFile2.map(a => {
          let size = (a.size / 1048576);
          allFileSizes = allFileSizes + size;
  
        });
        var fileSize = (images[i].size / 1048576);
        if (!images[i].name.match(/.(jpg|png|pdf|docx)$/i)) {
          this.setState({ fileExtensionError2: true });
        }
        else if (fileSize + allFileSizes > 15) {
          this.setState({ fileSizeError2: true });
        }
        else if(repeat.length > 0){
          this.setState({
            duplicateFileErrorMsg2: true
          })
        }
        else {
          this.state.selectedFile2.push(images[i]);

          //  this.state.fileContent.push(images[i]);
          
        }

      }
    }
    if (!this.state.fileExtensionError2 && !this.state.fileSizeError2 && !this.state.totalfileCountError2 && !this.state.duplicateFileErrorMsg2) {
      this.setState({
        selectedFile2: this.state.selectedFile2,

      })
    }
    e.target.value = '';
  };

  deleteRequestIcon = (e) => {
    var index = e;
    if (index !== -1) {
      this.state.selectedFile2.splice(index, 1);
      this.setState({ selectedFile2: this.state.selectedFile2 });
    }
    if(this.state.selectedFile2.length <= 3){
      this.setState({
        totalfileCountError2: false
      })
    }
    if(this.state.selectedFile2.length == 0){
      this.setState({
        fileSizeError2: false,
        fileExtensionError2: false
      })
    }
  };

  confidentialPopUp = (flag, id) => {
    api.get(`setagreement/${id}/CommandCenter`).then((resp) => {
      this.setState({ showConfPopup: flag, showConfidentialModal: false });
    })
  }

  login = () => {
    const accounts = this.props.msalContext.accounts;
    const msalInstance = this.props.msalContext.instance;

    msalInstance.handleRedirectPromise().then(tokenResponse => {
      if (!tokenResponse) {
        if (accounts.length === 0) {
          msalInstance.loginRedirect(loginRequest);
        } else if (accounts.length === 1) {
          this.getLanguageList(false);
          this.getAnnouncements();
          this.handleClickOnScreen()
          this.setupBeforeUnloadListener()
        }
      } else {
        this.getLanguageList(true);
        this.getAnnouncements();
        this.handleClickOnScreen()
        this.setupBeforeUnloadListener()
      }
    }).catch(err => {
      // Handle error
    //  console.error(err);
    });

  }

  componentDidMount() {
    if (ENVIRONMENT !== "local") {
      this.login();
    } else {
      this.getLanguageList(false);
      this.getAnnouncements();
      this.handleClickOnScreen()
      this.setupBeforeUnloadListener()
    }

    setInterval(() => {
      //IF TOKEN IS IN 5 MIN BEFORE EXPIRATION THEN TRIGGER REFRESH
      //For testing purposes, 3mins token refresh is set
      try {
     //   console.log((this.parseJwt(localStorage.getItem('access_token_powerbi')).exp - (Date.now() / 1000)))
        if (ENVIRONMENT !== 'local') {
          if ((this.parseJwt(localStorage.getItem('access_token_powerbi')).exp - (Date.now() / 1000)) < window.msalConfig.exptimeCompare) {
            if (this.state.userDetails.isPowerBIEnabled) {
              this.refreshPowerBi()
            }
          }
        }
      } catch (e) {
     //    console.log(e) 
         }

    }, window.msalConfig.tokenRefreshTime);

  }

  setupListener = () => {
    //lifecycle.addEventListener('statechange', this.handleStateChange);
  }

  handleStateChange = (event) => {
    //console.log('statecheck ', event.originalEvent, event.oldState, event.newState);
   // console.log(this.state.isLoggingEnabled || this.state.prevLoggedInEnabled)
    if (event.newState === 'terminated') {
      this.callUserEnagementSessionAPI(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, false)
    }
  }
  handleNotificationChange=(value)=>{
   // console.log(value,"value");
    this.setState({allowAnnouncementAutoPopup: value? true : false})
    let allowAnnouncement= this.state.userDetails.id;
    this.showAnnouncement(allowAnnouncement,!value);
    localStorage.setItem('announcementChecked', value.toString());
}
handleMessageChange = (key, value) => {
  switch (key) {
    case "Report an Issue":
      this.setState({
        commentText: "Please provide the issue details here.",
        messageType: "reportanissue",
        popUpText: "issue report",
        submittedIssue: "Issue Report"
      });
      break;
    case "Access":
      this.setState({
        commentText: "Please provide the E-mail, Client name & Purpose of access.",
        messageType: "access",
        popUpText: "access request",
        submittedIssue: "Access Request"
      });
      break;
    case "Feedback":
      this.setState({
        commentText: "Please provide suggestions or feedback here.",
        messageType: "feedback",
        popUpText: "feedback",
        submittedIssue: "Feedback"
      });
      break;

  }

}

handleFeedbackChange = (key, value) => {
  switch (key) {
    case "type":
      this.setState({
        type: value,
        feedbackSubmitted: false,
        commentText: "Please provide the issue details here.",
        selectedFile:[],
        fileExtensionError: false,
        totalfileCountError: false,
        fileSizeError: false,
        duplicateFileErrorMsg: false
      });
      break;
    case "suggestion":
      this.setState({
        feedbackSubject: value.value,
        feedbackSelected: value
      });
      break;
    case "feedbackMessage":
      this.setState({
        feedbackBody: value,
      });
    
      break;
    default:
      break;
  }
};
handleRequestAccessChange = ( value) => {
      this.setState({
        feedbackBody2: value,
      });
};
showFeedbackModal = () => {
  this.setState({
    showFeedbackModal: true,
    feedbackSubject: '',
    feedbackBody: '',
    type: 'productInfo',
    feedbackSelected: null,
    totalfileCountError: false,
    duplicateFileErrorMsg: false,
    selectedFile : [],
    fileExtensionError: false,
    fileSizeError: false,
    messageType: "reportanissue",
    popUpText: "issue report",
    submittedIssue: "Issue Report",
  })
}
  hideFeedbackModal = () => {
    this.setState({
      showFeedbackModal: false
    })
  }
  hideSubmit = () => {
    this.setState({
      openSubmit: false
    })
  }
  feedbackSubmit = () => {
    this.setState({
      feedbackSubmitted: true,
      openSubmit: false,
      feedbackBody: '',
      feedbackSubject: ''
    })
    this.sendFeedback(this.state.feedbackBody, this.state.messageType, this.state.selectedFile);
  }
  feedbackSubmitNew = () => {
    this.setState({
      feedbackSubmitted: false,
      feedbackBody: '',
      feedbackSelected: null,
      selectedFile: [],
      commentText: "Please provide the issue details here.",
      messageType: "reportanissue",
      popUpText: "issue report",
      submittedIssue: "Issue Report",
      fileSizeError: false,
      fileExtensionError: false,
      totalfileCountError: false,
      duplicateFileErrorMsg: false
    })
  }
  showReqAccessModal = () => {
    this.setState({
      showRequestAccessModal: true,
      feedbackBody2: '',
      feedbackSelected: null,
      totalfileCountError2: false,
      duplicateFileErrorMsg2: false,
      selectedFile2 : [],
      fileExtensionError2: false,
      fileSizeError2: false,
      messageType: "reportanissue"
    })
  }
  hideRequestAccessModal = () => {
    this.setState({
      showRequestAccessModal: false
    })
  }
  hideSuccessModal = ()=>{
    this.setState({
      showSuccessPopup: false
    })
  }
  submitReqAccess = () => {
    this.sendFeedback2(this.state.feedbackBody2, "access", this.state.selectedFile2);
    this.setState({
      showRequestAccessModal: false,
      feedbackBody2: '',
    })
  }

  // generateNewIdeaScript = () => {

  //   if (this.state.feedbackBody) {
  //     let to = "synops-support@accenture.com";
  //     let subject = "SynOps Hub Feedback";//encodeURIComponent(this.state.feedbackSubject);	
  //     let msgBody1 = encodeURIComponent(this.state.feedbackBody);
  //     let msgBody2 = "Hi SynOps Hub Support Team%0D%0A%0D%0APlease refer to the below feedback.%0D%0A%0D%0A";
  //     let message = '';
  //     let msgBody3 = "Feedback: " + msgBody1;
  //     let msgBody4 = "%0D%0A%0D%0AUser email ID:%0D%0A%0D%0A";
  //     let msgBody5 = "New Idea for which application: " + "Command Center";
  //     // email.className = 'not'	
  //     message = 'mailto:' + to;
  //     if (subject || msgBody1) message += '?';
  //     if (subject) message += 'subject=' + subject;
  //     if (subject && msgBody1) message += '&body=' + msgBody2 + msgBody3 + msgBody4 + msgBody5;
  //     if (!subject && msgBody1) message += 'body=' + msgBody2 + msgBody3 + msgBody4 + msgBody5;
  //     this.setState({ showFeedbackModal: false });
  //     window.open(message);
  //   }
  // }

  generateNewIdeaScript = () => {

    if (this.state.feedbackBody) {
      this.setState({ openSubmit: true });

    }
  }

  getLanguageList = async (isFirstCall) => {
    if (ENVIRONMENT !== 'local') {
      const account = msalInstance.getAllAccounts()[0];
      if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }
  //always call the acquiretokensilent first before calling any API
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  }).catch(err => {
    //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
    //always check if the error is something that requires auth login interaction 
    if (err instanceof InteractionRequiredAuthError) {
      return msalInstance.acquireTokenPopup({
        ...loginRequest,
        account: account
      }).catch(err => {
      //  console.log('Error getting popup token: ' + JSON.stringify(err))
      });
    }
  });
    localStorage.setItem("access_token", response.accessToken);
    // if(window.performance.navigation.type != window.performance.navigation.TYPE_RELOAD){
    //   window.tfo_ping(window.TFOAppId, window, response.accessToken);
    // }
  // TFOPing.tfo_ping(clientId, response.accessToken);
  }

    api
      .get(`languages`)
      .then((res) => {
        const langList = res;
        this.setState({
          languageList: langList,
          languageListFiltered: langList
        }, () => this.getUserDetails())
      })
      .catch((error) => {

      });
  }
  // handleLanguageChange = (event) => {
  //   this.props.setUserPreferedLanguage(event.target.value, event.target.id);
  //   let selectedLanguage = event.target.value
  //   let body = {
  //     Id: this.state.userDetails.id,
  //     Language: event.target.value,
  //     ShowGlobe: true,
  //     Theme: "blue",
  //     Application: "CommandCenter"
  //   }
  //   api.post(`userpreference`, body)
  //     .then((res) => {
  //       if (res === 'Success') {
  //         this.getTranslations(selectedLanguage)
  //       }
  //     })
  //     .catch((error) => {

  //     });

  // }
  handleClickOnScreen = () => {
    window.addEventListener('click', (e) => {
      let selectClientHolderDivArr = document.getElementsByClassName('selectClientHolderDiv')
      let hmccColArr = document.getElementsByClassName('clientDropDown')
      if (selectClientHolderDivArr.length > 0 && selectClientHolderDivArr[0].contains(e.target)) {
      }
      else if (hmccColArr.length > 0 && hmccColArr[0].contains(e.target)) {
        this.handleSelectClientToggle()
      }
      else {
        if (!this.state.selectClientHolderDiv) {
          this.setState({
            selectClientHolderDiv: true
          })
        }
      }
    });
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    // localStorage.clear();
    // msalInstance.logout();
  }
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      //ev.preventDefault();
       
       ev.returnValue = "";
      return this.callUserEnagementSessionAPI(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, false)
    });
  };

  handleWindowBeforeUnload = (ev) => {
    ev.preventDefault();
    //this.callUserEnagementSessionAPI(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, false)
    //ev.returnValue="Leaving this page will reset the wizard"
    return ev.returnValue = "Leaving this page will reset the wizard" //"Leaving this page will reset the wizard"
  }


  handleSelectClientToggle = (e) => {
    this.setState({
      selectClientHolderDiv: !this.state.selectClientHolderDiv,
    })
  }
  handleProfileBtnClick = () => {
    this.setState({
      hideProfileHolderDiv: false,
      hideAnnouncementHolderDiv: true,
      openedDropdown: "",
      hideFilterHolderDiv: !this.state.hideFilterHolderDiv
    })

    // this.refs.child.handleCloseFilterClick();



  }
  handleAnnouncementBtnClick = () => {
    this.setState({
      hideAnnouncementHolderDiv: false,
      hideFilterHolderDiv: !this.state.hideFilterHolderDiv,
      selectedTab:this.state.alertsData.length>0?"Alert":"Announcement"
    })
    if(this.state.userDetails.featureIds && this.state.userDetails.featureIds.some((val) => val == 26)){
      this.getAnnouncements();
    }
   // let tenantId = this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0
    //this.getAlertData(tenantId);
  }
  handleViewMoreAnnouncementClick = () => {
    this.announcementPopUp(true)
    this.setState({ hideAnnouncementHolderDiv: true, showAnnouncements: false })
  }
  handleCloseProfileBtnClick = () => {
    this.setState({
      hideProfileHolderDiv: true
    })
  }
  handleCloseAnnouncementBtnClick = () => {
    this.setState({
      hideAnnouncementHolderDiv: true, showAnnouncements: false
    })
  }
  _handleChangeTheme = () => {
    this.setState({ isCheckedTheme: !this.state.isCheckedTheme });
  }
  loadSisensejs = () => {
    const isLoaded = sisenseIsLoaded();
    if (!isLoaded) {
      let count = this.state.count + 1;
      this.setState({ count })
      if (count < 3) {
        setTimeout(this.ConnectJS(), 5000);
      }
      else {
     //   console.log("Error- while connecting to sisenseJS server");
        this.setState({ isConnected: true, sisenceConnectionError: true });
      }
    }
    else {
      const Sisense = window.Sisense;
      Sisense.connect(sisenseSettings.server).then(app => {
        this.setState({
          currentApp: app,
          isConnected: true,
          sisenceConnectionError: false
        });
      });
    }
  }
  ConnectJS = () => {
    this.setState({
      isSisenseConnectCalled: true
    })
    const isLoaded = sisenseIsLoaded();
    if (!isLoaded) {
        fetch(sisenseSettings.server)
        .then(
          (result) => {
            if (result.status == 200) {
          //    console.log("fetchresult", result);
              const script = document.createElement("script");
              script.type = "text/javascript";
              script.src = sisenseSettings.server + '/js/sisense.js?v=' + guid();
              script.async = true;
              script.onload = this.loadSisensejs;
              document.body.appendChild(script);
            } else {
              this.setState({ count: 3, isConnected: true, sisenceConnectionError: true })
            }
          },
          (error) => {
        //    console.log("fetcherror", error);
            this.setState({ count: 3, isConnected: true, sisenceConnectionError: true })
          }
        )
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.app.filterObject.tenant !== nextProps.app.filterObject.tenant
    ) {
      let sendClientId = nextProps.app.demoClient?nextProps.app.parentTenantID:nextProps.app.filterObject.tenantId;
      this.getConfigData(nextProps.app.filterObject.tenant, sendClientId);
    }
  }
  hideModal = () => {
    this.setState({ showModal: false });
  }

  onChangeLanguage = (e) => {
    let filtered = [...this.state.languageList]

    let searchedText = e.target.value.toLowerCase().trim()
    this.setState({
      languageListFiltered: filtered.filter(x => x.languageDescription.toLowerCase().includes(searchedText)),
      searchedLanguageText: e.target.value
    })
  }

  onSelectLanguage = (language) => {
    this.setState({
      hideLanguageDiv: true,
      openedDropdown: "",
      languageListFiltered: this.state.languageList
    })
    this.props.setUserPreferedLanguage(language.languageCode, language.languageDescription);
    let selectedLanguage = language.languageCode
    let body = {
      Id: this.state.userDetails.id,
      Language: language.languageCode,
      ShowGlobe: true,
      Theme: "blue",
      Application: "CommandCenter"
    }
    api.post(`userpreference`, body)
      .then((res) => {
        if (res === 'Success') {
          this.getTranslations(selectedLanguage)
        }
      })
      .catch((error) => {
        //Check if it is a duplicate entry


      });

  }

  getTranslations = (languageCode) => {
    api
      .get(`translatedtext/${languageCode}`)
      .then((res) => {
        let literals = {};
        literals[languageCode] = { translation: res };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: languageCode,
          fallbackLng: "en",

          interpolation: {
            escapeValue: false,
          },
        });
        this.props.setLanguageTranslations(res);
      })
      .catch((err) => {
        let literals = {};
        literals["en"] = { translation: {} };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: "en",
          fallbackLng: "en",

          interpolation: {
            escapeValue: false,
          },
        });
      })
  }
  getTranslatedText = (key) => {
    let value = "";
    value = this.props.app.languageTranslationArray[key] ? this.props.app.languageTranslationArray[key] : key;
    return value;
  }
  redirectToHomePage = () => {
    window.open('/', "_self")
  }

 

  getUserDetails = () => {
    //local
    let enterpriseId = '';
    if (ENVIRONMENT === 'local') {
      enterpriseId = ENTERPRISE_ID;
      this.setState({ profileName: enterpriseId });
    }
    else {
      let userName = this.props.msalContext.accounts.length > 0 && this.props.msalContext.accounts[0].username;
      enterpriseId = userName.split("@")[0];
      this.setState({ profileName: enterpriseId });
    }
    api
      .get(`useriddetails`)
      .then((res) => {
        //Check if it is a duplicate entry

        if (res.status == 400) {
          // this.redirectToHomePage()
          this.setState({ invalidUser: true });
          this.props.setUserPreferedLanguage('en', 'English');
        }
        else {
          const userDetails = res;
          
          this.setState({
            allowAnnouncementAutoPopup : !userDetails.userPreference.allowAnnouncementAutoPopup
          })
          localStorage.setItem('announcementChecked', !userDetails.userPreference.allowAnnouncementAutoPopup)
          
          if (userDetails.userPreference.allowAnnouncementAutoPopup == false || (userDetails.featureIds && userDetails.featureIds.some((val) => val == 26) === false && userDetails.dashboardRole != ADMIN)) {
            this.setState({ showAnnouncements: false });
          }
          else {
            this.setState({ showAnnouncements: this.state.announcements.length != 0?true:false, selectedTab: this.state.announcements.length != 0?"Announcement":"" });
          }

          if (userDetails.userPreference.agreement == true) {
            this.setState({ showConfPopup: false, showConfidentialModal: false });
          }

          let sectionView = '', activeSubTabId = 0
          if (res && res.appIds && res.appIds.includes(1)) {
            
            
            let langText = (this.state.languageList.find(obj => obj.languageCode.toUpperCase() === userDetails.userPreference.language.toUpperCase())).languageDescription;
            this.props.setUserPreferedLanguage(userDetails.userPreference.language, langText);
            this.getTranslations(userDetails.userPreference.language);
            let route = "";
            //default route
            if (
              (userDetails.featureIds && userDetails.featureIds.some((val) => val == 28)) ||
              userDetails.dashboardRole == ADMIN
            ) {
              sectionView = 'Human and Machine'
              activeSubTabId = 0
              route = "humanAndMachine";
              this.setState({ showHumanAndMachine: true, tabSelected: 'T2' });
            } else if (userDetails.featureIds && userDetails.featureIds.some((val) => val == 26)) {
              sectionView = 'IOJ'
              activeSubTabId = this.state.activeSubTabId
              route = "intOps";
              this.setState({ showIntOpsJourney: true, tabSelected: 'T1' });
            } else if (userDetails.featureIds && userDetails.featureIds.some((val) => val == 30)) {
              sectionView = 'Operations'
              activeSubTabId = 0
              route = "operations";
              this.setState({ showOperations: true, tabSelected: 'T3' });
            } else if (userDetails.featureIds && userDetails.featureIds.some((val) => val == 32)) {
              sectionView = 'Business Outcome'
              activeSubTabId = 0
              route = "businessOutcome";
              this.setState({ showBusinessOutcome: true, tabSelected: 'T4' });
            } else if (userDetails.featureIds && userDetails.featureIds.some((val) => val == 59)) { //Dummy id
              sectionView = 'SynOps Apps'
              activeSubTabId = 0
              route = "appStore";
              this.setState({ showAppStore: true, tabSelected: 'T5' });
            }
            this.setState({ userDetails: userDetails, defaultRoute: route, isConnected: !userDetails.isSisenseEnabled });
            if (ENVIRONMENT !== 'local' && !this.state.isSisenseConnectCalled && userDetails.isSisenseEnabled) {
              this.ConnectJS();
            }
            if (ENVIRONMENT !== 'local' && userDetails.isPowerBIEnabled) {
              this.refreshPowerBi();
            }
            //Validate license
            let macid = MAC_ID;
            api
              .get(`validatelicensekeys/${macid}`)
              .then((res) => {
                //Check if it is a duplicate entry
                if (res.status == 400) {
                  this.setState({ isValidLicense: false, licenseChecked: true });
                }
                else {
                  this.setState({ isValidLicense: true, licenseChecked: true, activeSectionView: sectionView }, () => {
                    if (userDetails.tenant.length == 1) {
                      this.setState({ selectedClient: userDetails.tenant[0] }, () => {
                        this.onSelectClient(userDetails.tenant[0], '');
                      });
                    }
                    else {
                      if (sectionView !== '') {
                        this.setState({
                          isLoggingEnabled: true
                        }, () => {
                          this.callUserEnagementSessionAPI(sectionView, activeSubTabId, 0, true, '', userDetails)

                        })

                        //this.checkIfTrackingEnabled(sectionView, activeSubTabId, 0, userDetails)
                      }
                    }
                  });
                  let sendClientId = this.props.app.demoClient?this.props.app.parentTenantID:this.props.app.filterObject.tenantId;
                  this.getConfigData(this.props.app.filterObject.tenant, sendClientId);

                }
              })
              .catch((error) => {

              });
          }
          else {
            this.setState({ invalidUser: true });
            // this.redirectToHomePage()
          }
        }
      })
      .catch((error) => {
        //Check if it is a duplicate entry

        this.props.setUserPreferedLanguage('en', "English");
      });
  };
  checkIfTrackingEnabled = (sectionView, subTabId, appId, prevSectionView, userDetails, tenantId) => {
    //COMMENT FOR PROD
    api
      .get(`userengagement/trackingconfigs/${this.props.app.demoClient?this.props.app.parentTenantID:tenantId}`)
      .then((res) => {
        if (res) {
          let prevLoggingEnabled = this.state.isLoggingEnabled
          this.setState({
            isLoggingEnabled: res.isLoggingEnabledCC,
            activeSectionView: sectionView,
            prevLoggedInEnabled: prevLoggingEnabled
          }, () => {
            if (res.isLoggingEnabledCC) {
              this.callUserEnagementSessionAPI(sectionView, subTabId, appId, true, prevSectionView, userDetails)

            }
            else if (prevLoggingEnabled) {
              this.callUserEnagementSessionAPI(sectionView, subTabId, appId, false)
            }
          })

        }
      })
      .catch((error) => {
        //Check if it is a duplicate entry


      });
  }
  getConfigData = (tenant, tenantId) => {
    //local
    let enterpriseId = '';
    if (ENVIRONMENT === 'local') {
      enterpriseId = ENTERPRISE_ID;
    }
    else {
      let userName = this.props.msalContext.accounts.length > 0 && this.props.msalContext.accounts[0].username;
      enterpriseId = userName.split("@")[0];
    }
    //Get all config data
    api.post(`hmccconfigurations`, [tenantId+""]).then((res) => {
      const result = res;
      let tab1Config = [];
      let tab2Config = [];
      let tab3Config = [];
      let tab4Config = [];
      let tab5Config = [];
      result.map((item) => {
        switch (item.screenSection) {
          case "T1":
            {
              tab1Config.push(item);
            }
            break;
          case "T2":
            {
              tab2Config.push(item);
            }
            break;
          case "T3":
            {
              tab3Config.push(item);
            }
            break;
          case "T4":
            {
              tab4Config.push(item);
            }
            break;
          case "T5":
            {
              tab5Config.push(item);
            }
            break;
          default:
            break;
        }
      });
      this.setState({ tab1Config, tab2Config, tab3Config, tab4Config, tab5Config });
      //Check if user has apps in app store(Configured by admin)
      let client = tenant.length == 1 ? tenant[0] : null;
      let clientId = tenantId.length == 1 ? tenantId[0] : 0;
      api.get(`appstoreconfigs/` + client + `/${this.props.app.demoClient?this.props.app.parentTenantID:clientId}`  + '/' + this.state.userDetails.id).then((resp) => {
        if (resp.length > 0) {
          this.setState({ showAppStoreHome: true });
        }
      });
				   
	  if((this.state.userDetails.featureIds && this.state.userDetails.featureIds.some((val) => val == 26)) ||
          (this.state.userDetails.featureIds && this.state.userDetails.featureIds.some((val) => val == 30)) ||
          (this.state.userDetails.dashboardRole == ADMIN)){
        let tenantId = this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0
        this.getAlertData(tenantId);
      }			   
    });
  }

  toggleDropdown = (value) => {
    this.setState({
      hideLanguageDiv: false,
      searchedLanguageText: "",

    })
    if (!this.state.loading) {
      if (this.state.openedDropdown == value) {
        this.setState({ openedDropdown: "", searchedLanguageText: "" });
      } else {
        switch (value) {
          case "Select Language": {
            // this.getLanguageList();
            this.setState({ openedDropdown: "Select Language", languageListFiltered: this.state.languageList });
          }
            break;
          default:
            break;
        }
      }

    }
  };

   logout = async () => {
    try {
      await this.callUserEnagementSessionAPI(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, true, this.state.activeSectionView)
      
      // After the first API call is successful, call the second API
      const msalInstance = this.props.msalContext.instance;
      await api.get("logout").then((resp) => {
        msalInstance.logoutRedirect({
          account: this.props.msalContext.accounts[0],
          onRedirectNavigate: () => !BrowserUtils.isInIframe()
        })
      });
      sessionStorage.clear();
      localStorage.clear();
      (() => {
  
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i];
          let eqPos = cookie.indexOf("=");
          let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      })();
    } catch (error) {
      
    }

  };
  callUserEnagementSessionAPI = (sectionView, subTabId, appId, insertRecord, previousSectionView, userDetails, buttonId) => {
    // COMMENT FOR PROD
    userDetails = userDetails && userDetails.id ? userDetails : this.state.userDetails;
    const decodedToken = jwt.decode(localStorage.getItem('access_token'));
    if (this.state.isLoggingEnabled || this.state.prevLoggedInEnabled) {
      let body = {
        SessionId: decodedToken ? decodedToken.exp+"" : '0',
        UserId: userDetails.id,
        TenantId: this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0,
        Component: 'Command Center',
        SectionView: sectionView,
        SubTabId: subTabId+"",
        ButtonId:buttonId?(buttonId+""):"",
        AppsId: appId,
        CurrentDateTime: this.getUTCISOString(),
        InsertRecord: insertRecord,
        LastRecordId: this.state.lastUserEngagementRecord && this.state.lastUserEngagementRecord.recordId ? this.state.lastUserEngagementRecord.recordId : 0
      }
      let prevlastUserEngagementRecord = this.state.lastUserEngagementRecord
      api.post(`userengagements`, body).then((res) => {
        this.setState({
          lastUserEngagementRecord: res
        })
        if (previousSectionView == 'Human and Machine' && this.props.app.filterObject.deliveryLocation.length > 0 && prevlastUserEngagementRecord.recordId != 0) {
          this.callUserEnagementLocationAPI(this.props.app.filterObject.deliveryLocation, prevlastUserEngagementRecord)
        }
        if (sectionView == 'Human and Machine' && this.props.app.filterObject.deliveryLocation.length > 0 && res.recordId != 0) {
          this.callUserEnagementLocationAPI(this.props.app.filterObject.deliveryLocation, res)

        }

      });
    }
    if (subTabId != this.state.activeSubTabId && sectionView == 'IOJ') {
      this.setState({
        activeSubTabId: subTabId
      })
    }
  }

  getUTCISOString = () => {
    const date = new Date()
    const nowUTC = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    var d2 = new Date(nowUTC);
    return d2.toISOString();

  }

  setActiveScreen = (value) => {
    if (this.state.tabSelected != value) {
      let preSectionView = this.state.activeSectionView
      switch (value) {
        case "T1":
          {
            
            this.setState({
              showHumanAndMachine: false,
              showIntOpsJourney: true,
              showOperations: false,
              showBusinessOutcome: false,
              showAppStore: false,
              tabSelected: value,
              showFeedbackIcon: true,
              activeSectionView: 'IOJ'
            }, () => {
              this.callUserEnagementSessionAPI('IOJ', this.state.activeSubTabId, 0, true, preSectionView)

            })
          }
          break;
        case "T2":
          {
            
            this.setState({
              showHumanAndMachine: true,
              showIntOpsJourney: false,
              showOperations: false,
              showBusinessOutcome: false,
              showAppStore: false,
              tabSelected: value,
              showFeedbackIcon: true,
              activeSectionView: 'Human and Machine'
            }, () => {
              this.callUserEnagementSessionAPI('Human and Machine', 0, 0, true, preSectionView)
            })
          }
          break;
        case "T3":
          {
            
            this.setState({
              showHumanAndMachine: false,
              showIntOpsJourney: false,
              showOperations: true,
              showBusinessOutcome: false,
              showAppStore: false,
              tabSelected: value,
              showFeedbackIcon: true,
              activeSectionView: 'Operations'
            }, () => {
              this.callUserEnagementSessionAPI('Operations', 0, 0, true, preSectionView)
            })
          }
          break;
        case "T4":
          {
            
            this.setState({
              showHumanAndMachine: false,
              showIntOpsJourney: false,
              showOperations: false,
              showBusinessOutcome: true,
              showAppStore: false,
              tabSelected: value,
              showFeedbackIcon: true,
              activeSectionView: 'Business Outcome'
            }, () => {
              this.callUserEnagementSessionAPI('Business Outcome', 0, 0, true, preSectionView)
            })
          }
          break;
        case "T5":
          {
            
            this.setState({
              showHumanAndMachine: false,
              showIntOpsJourney: false,
              showOperations: false,
              showBusinessOutcome: false,
              showAppStore: true,
              tabSelected: value,
              showFeedbackIcon: false,
              activeSectionView: 'SynOps Apps'
            }, () => {
              if (preSectionView == 'Human and Machine' && this.props.app.filterObject.deliveryLocation.length > 0) {
                this.callUserEnagementLocationAPI(this.props.app.filterObject.deliveryLocation, this.state.lastUserEngagementRecord)
              }
            })
          }
          break;
        default:
          break;
      }
    }
  }

  toggleTopMenu = () => {
    this.props.toggleMenuDisplay(!this.state.showMenu);
    this.setState({ showMenu: !this.state.showMenu });
  };
  showImage = (imageDetails) => {
    return imageDetails.iconName != null && imageDetails.iconName != '' && imageDetails.appIcon != null && imageDetails.appIcon != ''
  }
  getImageSrc = (client) => {
    const appIcon = client.appIcon
    const iconName = client.iconName

    if (iconName != null) {
      var n = iconName.lastIndexOf('.');
      var extn = iconName.substring(n + 1);
      var imageSrc = extn == 'svg' ? "data:image/svg+xml;base64, " + appIcon : "data:image/png;base64, " + appIcon
      return imageSrc
    }
    else {
      return 'data:image/jpeg;base64, ' + appIcon
    }
  }
  onSelectClient = (client, prevSectionView) => {

    let filterObject = { ... this.props.app.filterObject }
    let selectedClients = []
    if (client && client.tenantName) {
      filterObject.tenant = [client.pseudoName]
      selectedClients = [client.tenantName]
      filterObject.tenantId = [client.tenantID]
    }
    else {
      filterObject.tenant = []
      filterObject.tenantId = []
    }
    this.setState({
      selectedClient: client,
      selectClientHolderDiv: true,
      searchedClientText: ''
    }, () => {
      if (client && client.tenantName) {
        this.checkIfTrackingEnabled(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, prevSectionView, this.state.userDetails, client.tenantID)
      }
      else { //means user selected all clients
        this.callUserEnagementSessionAPI(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, true, this.state.activeSectionView)
      }

    })
    this.props.setFilters(filterObject);
    this.props.setClients(selectedClients);
    if (client.isDemo) {
      this.props.setDemoClient(true, client.parentTenantID)
    } else {
      this.props.setDemoClient(false, client.parentTenantID)
    }



  }
  handleOnIdle = (event) => {
    this.callUserEnagementSessionAPI(this.state.activeSectionView, this.state.activeSubTabId, this.state.activeAppId, false, this.state.activeSectionView);
  }

  callUserEnagementLocationAPI = (selectedLocations, lastUserEngagementRecord) => { //COMMENT FOR PROD
    const decodedToken = jwt.decode(localStorage.getItem('access_token'));
    let arrLocations = selectedLocations.map(element => {
      return (
        {
          UserId: this.state.userDetails.id,
          Location: element,
          SessionId: decodedToken ? decodedToken.exp+"" : '0',
          UserEngagementRecordId: lastUserEngagementRecord.recordId
        })
    });


    let tenantID = this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0
    api.post(`userlocations/${this.props.app.demoClient?this.props.app.parentTenantID:tenantID}`, [...arrLocations]).then((res) => {

    });
  }

  onChangeDeliveryLocations = (selectedLocations) => {
    this.callUserEnagementLocationAPI(selectedLocations, this.state.lastUserEngagementRecord)

  }
  switchHumanAndMachineTab = (flag) => {
    this.setState({ showHMtab: flag });
  }
  announcementPopUp = (flag) => {
    this.setState({ showHMtab: flag, showProfile: true });
  }
  //  confidentialPopUp = (flag) => {
  //    this.setState({ showConfPopup: flag });
  //  }
  handleConfidentialPopup = () => {
    let allowConfidentialInfo = this.state.userDetails.id;
    this.confidentialPopUp(false, allowConfidentialInfo);
  }
  confidentialMail = () => {
    let to = "confidentiality@accenture.com";
    let message = '';
    message = 'mailto:' + to;
    window.open(message);
  }
  handleTabSelect = (selectedTab) => {
    this.setState({ selectedTab });
  }
  sendEmail = () => {
    // window.open("mailto:support@example.com?subject=SendMail&body=Description");
        let to = "SynopsHUB-Support@accenture.com";
        let subject = "Access Request - SynOps Hub";
        let msgBody2 = "Hello SynOps Hub Support Team,%0D%0A%0D%0APlease refer to the below  details for the application access request:%0D%0A";
        let message = '';
        let msgBody3 = "Application Name: Configuration Management System";
        let msgBody4 = "%0D%0AClient Name:%0D%0A";
        let msgBody5 = "Purpose of access:%0D%0A";
        let msgBody6 = "WBS:%0D%0A";
        let msgBody7 = "Email-ID of users for whom access is required:" ;
 
       message = 'mailto:' + to;
       if (subject ) message += '?';
       if (subject) message += 'subject=' + subject;
       if (subject ) message += '&body=' + msgBody2 + msgBody3 + msgBody4 + msgBody5 + msgBody6 + msgBody7;
       if (!subject) message += 'body=' + msgBody2 + msgBody3 + msgBody4 + msgBody5 + msgBody6 + msgBody7;
 
       window.open(message);
     
   };

  render() {
   //  console.log(this.state.allowAnnouncementAutoPopup,"users");
	const rowEvents = {
      onClick: (e, row, rowIndex) => {
        let getCurrentCellIndex = e.target.cellIndex;
        let clientObj={pseudoName:"",tenantName:"", tenantID:""}
        if(getCurrentCellIndex || getCurrentCellIndex==0){
          switch(row.screenSection){
            case "T1":{
              this.state.tab1Config.map(item=>{
                if(item.id == row.configId){
                  this.setState({
                    hideAnnouncementHolderDiv: true, showAnnouncements: false
                  })
                  this.setState({alertObject:{
                      configId:row.configId,
                      configParentId:row.configParentId
                    }
                  })
                  if(row.tenantId){
                    clientObj.tenantName=row.client;
                    clientObj.pseudoName=row.pseudoName;
                    clientObj.tenantID=row.tenantId;
                    clientObj.parentTenantID=row.parentTenantID;
                    this.onSelectClient(clientObj, this.state.activeSectionView)
                  }
                  this.setActiveScreen("T1");
                }
              })
            }
              break;
            case "T3":{
                  this.setState({
                    hideAnnouncementHolderDiv: true, showAnnouncements: false,redirectedFromAlert:this.state.redirectedFromAlert+1, drillDownID:row.drillDownID
                  })
                  if(row.tenantId){
                  clientObj.tenantName=row.client;
                  clientObj.pseudoName=row.pseudoName;
                  clientObj.tenantID=row.tenantId;
                  clientObj.parentTenantID=row.parentTenantID;
                  this.onSelectClient(clientObj, this.state.activeSectionView)
                  }
                  this.setActiveScreen("T3");
            }
              break;
            case "T4":{
                  this.setState({
                    hideAnnouncementHolderDiv: true, showAnnouncements: false
                  })
                  if(row.tenantId){
                  clientObj.tenantName=row.client;
                  clientObj.pseudoName=row.pseudoName;
                  clientObj.tenantID=row.tenantId;
                  clientObj.parentTenantID=row.parentTenantID;
                  this.onSelectClient(clientObj, this.state.activeSectionView)
                  }
                  this.setActiveScreen("T4");
            }
              break;
            default:
              break;
          }
        }
      }
    }
					   
  //const rowStyle = { backgroundColor: '#c8e6c9', border: '3px solid red' };				
	const columns = [
      {
        dataField: "section",
        text: "Section",
      //  attrs: <span style={{backgroundColor:"blue"}}>title </span>
        title : true,
      //  attrs: { className: "titleChange" }
      },
      {
        dataField: "subsection",
        text: "Subsection",
        title : true,
      },
      {
        dataField: "application",
        text: "Application",
        title : true,
      },
      {
        dataField: "client",
        text: "Client",
        title : true,
      },
      {
        dataField: "kpi",
        text: "KPI",
        title : true,
      },
      {
        dataField: "kpivaluehidden",
        text: "KPI Value",
        hidden:true
      },
      {
        dataField: "kpivalue",
        text: "KPI Value",
        formatter: (cell, row) => {
          switch(row.ragStatus){
            case "G":
              return (<button className="green-alert">
                    {row.kpivalue}
                  </button>)
            case "Y":
              return (<button className="yellow-alert">
                    {row.kpivalue}
                  </button>)
            case "A":
              return (<button className="amber-alert">
                    {row.kpivalue}
                  </button>)
            case "R":
              return (<button className="red-alert">
                    {row.kpivalue}
                  </button>)
            default:
            break;
          }

        },
        csvExport: false,
      },
      // {
      //   dataField: "target",
      //   text: "Target",
      // },
      {
        dataField: "configId",
        csvExport: false,
        hidden:true
      },
      {
        dataField: "configParentId",
        csvExport: false,
        hidden:true
      },
      {
        dataField: "screenSection",
        csvExport: false,
        hidden:true
      },
      {
        dataField: "ragStatus",
        csvExport: false,
        hidden:true
      },
      // {
      //   dataField: "dismiss",
      //   //  text: "Dismiss"
      //   csvExport: false,
      // }
    ];
    let products = [];

    this.state.alertsData.map((item) => {
      products.push({
        section: item.iojStage,
        subsection: item.subsection,
        application: item.application,
        client: item.client,
        kpi: item.kpi,
        kpivaluehidden:item.kpiValue,
        kpivalue: <>{item.kpiValue} <img className="dismissClosebtn" title="Dismiss" onClick= {() => this.getDismissedData(item.id)} src={require("./Images/drafting-compass Copy.svg")} /></>,
        // target: item.target,
		    configId:item.configId,
        configParentId:item.configParentId,
        screenSection:item.screenSection,
        ragStatus:item.ragStatus,	
        pseudoName: item.pseudoName,
        tenantId: item.tenantId,	
        drillDownID:item.drillDownID			 
        // dismiss: <img className="dismissClosebtn" onClick= {() => this.getDismissedData(item.id)} src={require("./Images/drafting-compass Copy.svg")} />
      });
    });
    const columnHover = (cell, row, enumObject, rowIndex) => {
      return cell;
    }
    const optionsPag = {
      sizePerPageList: [
        {
          text: "6",
          value: 6,
        },
        {
          text: "12",
          value: 12,
        },
        {
          text: "18",
          value: 18,
        },
      ],
    paginationSize: 4, // the pagination bar size.
    //  lastPageText: <img className="arrowHeight" src={require('./Images/icon_exnext_white.svg')}/>,
    // firstPageText: <img  className="arrowHeight" src={require('./Images/icon_expre_white.svg')}/>,
    // nextPageText: <img  className="arrowHeight" src={require('./Images/icon_exnex_gray.svg')}/>,
    //  prePageText: <img  className="arrowHeight" src={require('./Images/icon_pre_white.svg')}/>,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    pageStartIndex: 1, // where to start counting the pages
    // withFirstAndLast: true,
    alwaysShowAllBtns: true,
    hideSizePerPage: true,
    };

    return (
      <div data-test='app-component'>
        <BrowserRouter>
        {this.state.invalidUser && (
          <div>
            <Card style={{border: "none"}}>
              <Card.Body className="access-denied">
                <img style={{marginTop: "4rem"}} src={require("./images/Access_denied.svg")}/>
                <div style={{fontFamily: "GraphicMedium !important",fontSize: "1.2rem", color: "#9400D3",textShadow:"0px 0px, 0px 0px, 0px 0px"}}>Access Denied</div>
                <div style={{marginTop: "1rem", marginLeft: "1rem"}}>
                  You do not have access to the Synops Hub application.
                </div>
                <div>
                  If you need access to Synops Hub, please{" "}
                  <span className="getAccess" onClick={() => this.showReqAccessModal()}>
                    click here
                  </span>.
                </div>
                {/* <div  onClick={this.sendEmail} style={{marginRight:"12rem"}}>  */}
                <div style={{marginRight: "-4rem"}}>
                The support team would reach out to you with the next steps.{" "}
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
          {/* {this.state.invalidUser && (
            <div>
              <Card>
                <Card.Body style={{ textAlign: "center", background: "#F0B8B8" }}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                  <span style={{ fontWeight: "bold" }}>User does not have access.</span>
                </Card.Body>
              </Card>
            </div>
          )} */}
          {this.state.sisenceConnectionError && (
               <span style={{ fontWeight: "bold" }}>There was something wrong when downloading sisense components. Kindly refresh the page. If issue still persists, contact SynOps Hub support</span>
            )}
          {!this.state.isValidLicense && !this.state.invalidUser && this.state.licenseChecked && (

            <div>
              <Card>
                <Card.Body style={{ textAlign: "center", background: "#F0B8B8" }}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                  <span style={{ fontWeight: "bold" }}> Licence has expired. Please contact Administrator.</span>
                </Card.Body>
              </Card>
            </div>
          )}
          {this.state.showConfidentialModal &&
            ((this.state.userDetails.featureIds
            ) || this.state.userDetails.dashboardRole == ADMIN) && (<Modal show={this.state.showConfPopup} onHide={this.handleConfidentialPopup} backdrop="static"
              dialogClassName="announcement-modal confidentialCCModal"
              backdropClassName="header-modal"
              aria-labelledby="example-custom-modal-styling-title"
            //   className="background-blur-Confidential"
            >
              <Modal.Header
                // closeButton={true}
                className="dashboard-modal-header"
              ></Modal.Header>
              <Modal.Body>
                <div className="row mb-3">
                  <div className="col-sm-12 graphikmedium confidentiallabel">{i18n.t("Confidential Information - Reminder")}</div>

                </div>
                <div className="announcementCntnrPopupDiv confidentialCCPopup" style={{ maxHeight: "46vh" }}>
                  <div className={"mt-20"}>
                    <span style={{ opacity: 0.7, marginRight: "3px" }}> You are using an Accenture proprietary application (“SynOps Hub - Command Centre”).
                      You understand that this application may contain</span>
                    ACCENTURE, CLIENT, OR VENDOR / PARTNER CONFIDENTIAL INFORMATION.
                    <span style={{ opacity: 0.7, margin: "3px" }}> You are obligated to adhere to applicable confidentiality obligations and
                      Accenture's policies, including Policy 69 Confidentiality,
                      when using the application and information in the application.
                      Information in the application can only be shared with those
                      authorized to receive it. If you are downloading/exporting
                      Confidential Information to another file or report,
                      you must label that file or report as Accenture Confidential.
                      If you have any questions, please email your question to</span>
                    <span style={{ cursor: "pointer", color: "deepskyblue" }} onClick={() => this.confidentialMail()} > confidentiality@accenture.com </span>
                    <span style={{ opacity: 0.7, marginRight: "3px" }}> or contact your Leadership. Thank you!</span>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ background: "rgb(33,53,110)", borderTop: "none", borderRadius: "0px 0px 16px 16px", height: "56px" }}><button style={{ borderStyle: "none", background: "rgb(1,142,255)", borderRadius: "16px", cursor: "pointer" }} className="hmcc-drop-btn" onClick={this.handleConfidentialPopup}>I understand</button></Modal.Footer>
            </Modal>)}
          <div id="sisenseApp">
          {/* <Suspense fallback={<div>Loading...</div>}> */}
            {this.state.isValidLicense &&
              //this.state.isConnected &&
              (this.state.tab2Config.length > 0) &&
              !this.state.invalidUser &&
              this.state.defaultRoute ? (
              this.state.showConfPopup ?
                <div style={{ background: "#022070", height: "100vh" }} /> :

                <div className="App">
                  <body>


                    <section className={this.state.showHumanAndMachine ? "HM-Main-BG" : "main-section"}>
                      {/*  Navigation start */}
                      {this.state.showMenu && (
                        <section className="m-p0 text-center main-bg top-menu-padding" style={{ position: "relative" }}>
                          <nav id="hiddeNav" className="tab-height">
                            <div className="row hmcc-nav new-maintab-div">
                              <div className='hmcc-header-left'>
                                {/* uichanges */}
                                <div className="hmcc-col-4 m-p0 hmcc-client-name-hldr" id='test'>
                                  <ReactTooltip

                                    id="main"

                                    place="bottom"

                                    type="light"

                                    effect="float"


                                    className='reactToolTipClient'

                                    disable={!(this.state.selectedClient && this.state.selectedClient.tenantName)}

                                    getContent={() => this.state.selectedClient.tenantName}

                                  />
                                  <div
                                    data-test='toggle-client'
                                    className="new-maintab-span hmcc-nav-text tab-height-responsive hmcc-nav-text-active">
                                    <div className="media">
                                      <img id="synopsLogo"
                                        src={require('./Images/synopsLogo.png')}
                                      />
                                      <span className='hmcc-logo-text media-body'>{i18n.t(window.Header.headerName)}</span>
                                    </div>
                                    <div className="clientDropDown">
                                      <div className="hmcc-client-logo-hldr">
                                        {this.state.selectedClient && this.state.selectedClient.tenantName &&
                                          <img
                                            src={this.showImage(this.state.selectedClient) ? this.getImageSrc(this.state.selectedClient) : require("./Images/hmcc-default-client.svg")}
                                          />
                                        }
                                      </div>

                                      <span data-tip data-for="main" data-test='selected-client-span' style={{ width: "6.3vw" }}>
                                        {this.state.selectedClient && this.state.selectedClient.tenantName ?
                                          this.state.selectedClient.tenantName : i18n.t('ALL')
                                        }

                                      </span>
                                      <img className="up-down-arw"
                                        src={require("./Images/hmcc-up-down-arrow.svg")}
                                      />
                                    </div>


                                  </div>

                                </div>
                                {/* changes end*/}
                              </div>
                              <div className='headerMenuContainer'>


                                {((this.state.userDetails.featureIds &&
                                  this.state.userDetails.featureIds.some(
                                    (val) => val == 26
                                  )) || this.state.userDetails.dashboardRole == ADMIN) && (
                                    <div className="hmcc-col-4 m-p0 float-left">
                                      <div
                                        onClick={() => this.setActiveScreen('T1')}
                                        className={this.state.showIntOpsJourney ? "new-maintab-span hmcc-nav-text  tab-height-responsive   hmcc-nav-text-active" : "new-maintab-span hmcc-nav-text  tab-height-responsive  "}
                                      >
                                        <img
                                          src={this.state.showIntOpsJourney ? require("./Images/robot-selected.svg") : require("./Images/robot-selected.svg")}
                                        />
                                        <span className="">
                                          {i18n.t('Intelligent Operations Journey')}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                {((this.state.userDetails.featureIds &&
                                  this.state.userDetails.featureIds.some(
                                    (val) => val == 28
                                  )) || this.state.userDetails.dashboardRole == ADMIN) && (
                                    <div className="hmcc-col-4 m-p0 float-left">
                                      <div
                                        onClick={() => this.setActiveScreen('T2')}
                                        className={this.state.showHumanAndMachine ? "new-maintab-span hmcc-nav-text  tab-height-responsive  hmcc-nav-text-active" : "new-maintab-span hmcc-nav-text  tab-height-responsive "}
                                      >
                                        <img
                                          src={require("./Images/humanPlusMachine-selected.svg")}
                                        />
                                        <span className="">
                                          {i18n.t('Human') + ' + ' + i18n.t('Machine')}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                {((this.state.userDetails.featureIds &&
                                  this.state.userDetails.featureIds.some(
                                    (val) => val == 30
                                  )) || this.state.userDetails.dashboardRole == ADMIN) && (
                                    <div className="hmcc-col-4 m-p0 float-left">
                                      <div
                                        onClick={() => this.setActiveScreen('T3')}
                                        className={this.state.showOperations ? "new-maintab-span hmcc-nav-text  tab-height-responsive  hmcc-nav-text-active" : "new-maintab-span hmcc-nav-text  tab-height-responsive "}
                                      >
                                        <img
                                          src={require("./Images/operations-selected.svg")}
                                        />
                                        <span className="">
                                          {i18n.t('Operations')}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                {((this.state.userDetails.featureIds &&
                                  this.state.userDetails.featureIds.some(
                                    (val) => val == 32
                                  )) || this.state.userDetails.dashboardRole == ADMIN) && (
                                    <div className="hmcc-col-4 m-p0 float-left">
                                      <div
                                        onClick={() => this.setActiveScreen('T4')}
                                        className={this.state.showBusinessOutcome ? "new-maintab-span hmcc-nav-text  tab-height-responsive  hmcc-nav-text-active" : "new-maintab-span hmcc-nav-text  tab-height-responsive "}
                                      >
                                        <img
                                          style={{ width: "26px" }}
                                          src={require("./Images/BO-selected.svg")}
                                        />
                                        <span className="">
                                          {i18n.t('Business Outcome')}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                {((this.state.userDetails.featureIds &&
                                  this.state.userDetails.featureIds.some(
                                    (val) => val == 59 //dummy
                                  )) || (this.state.userDetails.dashboardRole == ADMIN)) && (
                                    <div className="hmcc-col-4 m-p0 float-left">
                                      <div
                                        onClick={() => this.setActiveScreen('T5')}
                                        className={this.state.showAppStore ? "new-maintab-span hmcc-nav-text  tab-height-responsive  hmcc-nav-text-active" : "new-maintab-span hmcc-nav-text  tab-height-responsive "}
                                      >
                                        <img
                                          className="artboardIcon"
                                          src={this.state.showAppStore ? require("./Images/Artboard.svg") : require("./Images/Artboard.svg")}
                                        />

                                        <span className="">
                                          {i18n.t('SynOps Apps')}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                              </div>
                              <div className='col-1 pr-0 text-right'>
                                {((this.state.userDetails.featureIds && this.state.userDetails.featureIds.some((val) => val == 26)) || (this.state.userDetails.featureIds && this.state.userDetails.featureIds.some((val) => val == 30)) || this.state.userDetails.dashboardRole == ADMIN) && (this.state.alertsData.length>0 || ((this.state.userDetails.featureIds && this.state.userDetails.featureIds.some((val) => val == 26) && this.state.announcements.length>0))) ?
                                  <a className="bellIconHldr" onClick={() => this.handleAnnouncementBtnClick()}>
                                    <img src={require("./Images/bell.svg")} className="bellIcon"
                                    />
									  {this.state.alertsData.length>0?<button className="bell-count">{this.state.alertsData.length}</button>:""}																										  
                                  </a>
                                  : ""
                                }
                                <a className="profileIconHldr" onClick={() => this.handleProfileBtnClick()}>
                                  <img src={require("./Images/userLogin.png")} className="userProfile"
                                  />
                                </a>
                              </div>
                            </div>
                          </nav>
                          <ClientFilter onSelectClient={(client) => this.onSelectClient(client, this.state.activeSectionView)} selectClientHolderDiv={this.state.selectClientHolderDiv} selectedClient={this.props.selectedClient} userDetails={this.state.userDetails} />
                          <div role="dialog" aria-modal="true" className="fade business_modal-holder modal show" tabindex="-1" style={(this.state.hideAnnouncementHolderDiv && !this.state.showAnnouncements) ? { display: "none" } : { display: "block", backdropFilter:"brightness(0.3)" }}></div>
                          <div className="announcementCntnrDiv graphikmedium text-left" hidden={this.state.hideAnnouncementHolderDiv && !this.state.showAnnouncements}>
                            <div className="row" style={{ height: "4vh" }}>

                              <div className="col-12 alertlabel">


                                <Tabs activeKey={this.state.selectedTab} onSelect={this.handleTabSelect} id="uncontrolled-tab-example" className="mb-3 tabFont">
                                  {(((this.state.userDetails.featureIds && this.state.userDetails.featureIds.some((val) => val == 26)) ||
                                    (this.state.userDetails.featureIds && this.state.userDetails.featureIds.some((val) => val == 30)) ||
                                    (this.state.userDetails.dashboardRole == ADMIN)) && products.length > 0) &&



                                    <Tab eventKey="Alert" title={
                                      products.length > 0?
                                      (<>
                                      {i18n.t('Alerts ')}
                                      <button
                                          className="alert-count-button"
                                          >
                                          {products.length}
                                      </button>
                                      </>): <>{i18n.t('Alerts')}</>
                                      // products.length > 0
                                      // ? `${"Alerts (" + products.length + ")"}`
                                      // : i18n.t('Alerts')
                                      } className="alertsBorderBottom" >
                                      <div className="dismissAllBtn" onClick= {() => this.getDismissedAllData()} >
                                        Dismiss All
                                        <img className="dismissAllCloseBtn" src={require("./Images/drafting-compass Copy.svg")} 
                                        />
                                      </div>
                              <ToolkitProvider
                                  keyField="id"
                                  data={products}
                                  columns={columns}
                                  search
                                  exportCSV={{
                                    fileName: "Alerts.csv",
                                  }}
                                >
                                  {(props) => (
                                    <div>
                                      <div  >
                                        <ExportCSVButton
                                          {...props.csvProps}
                                          className="downloadbtn"
                                        >
                                         <span>
                                         <img className="alertDownload" src={require("./Images/icon_Download.png")} />
                                           Download
                                           {/* <FontAwesomeIcon icon={faFileDownload} /> */}
                                           </span> 
                                          
                                        </ExportCSVButton>

                                      </div>
                                      <div >

                                        <div className="row pagination-alert">
                                          <BootstrapTable
                                            id="tableBackground" 
                                          // keyField='id'
                                         //   rowStyle={rowStyle}
                                       //  bodyStyle={ {width: 500, maxWidth: 500, wordBreak: 'break-all' } }
                                            hover={true}
                                            bordered = {false}
                                            headerTitle={true}
                                            wrapperClasses="responsive"
                                            keyField="id"
                                            data={products}
                                            columns={columns}
                                            columnTitle={columnHover}
                                            {...props.baseProps}
                                            //  ref={(n) => (this.node = n)}
                                            pagination={paginationFactory(optionsPag)} 
											                      rowEvents={ rowEvents }
                                           // rowStyle={rowStyle}
                                           />
																	 

                                        </div>

                                      </div>
                                      </div>)}
                                         </ToolkitProvider>
                                    </Tab>}
                                    
                                    {(((this.state.userDetails.featureIds && this.state.userDetails.featureIds.some((val) => val == 26)) ||
                                    (this.state.userDetails.dashboardRole == ADMIN)) && this.state.announcements.length>0) &&
                                    <Tab eventKey="Announcement" title="Announcements">
                                    {this.state.announcements.length == 0 ?
                                    "No new announcements" :
																					   
                                      <div className='announcementContent'>
                                        {/* {this.state.userDetails.featureIds.includes("26") || this.state.userDetails.dashboardRole == ADMIN ? <> */}
                                        {this.state.announcements.map((item) => {
                                          return (
                                          <div className="row mb-2">
                                            <div className="col-sm-12">
                                              <div className="announcementDescCntnrMain" >
                                                <TextareaAutosize disabled id='textareaHTML' className="announcementDesc mb-0">{item.rawDescription}</TextareaAutosize>
                                                <span className="announcementDate">{dateFormat(item.validFrom, "dd mmm yyyy")}</span>
                                              </div>
                                            </div>
                                          </div>)
                                        })}
                                        {/* </> : "" } */}
                                      </div>}



                                      {/* <div className="row my-3">
                                        <div className="col-sm-12 text-center">
                                          <a className="announcementViewMore">
                                            <span onClick={() => this.handleViewMoreAnnouncementClick()}> View More </span>
                                          </a>
                                        </div>
                                      </div> */}
                                      <div>
              <Form className="row announcementPopupFooterTab mr-2 float-right">
                <Form.Group className="formCheck mt-3">
                <Form.Check
                  type="checkbox"
                  style={{color:"#ffffffba", marginTop:"26px !important"}}
                  className="checkboxAlignment graphikregular"
                  checked={this.state.allowAnnouncementAutoPopup}
                  onClick={(e) =>
                    this.handleNotificationChange(e.target.checked)
                  }
                  label="Do not show this again"
                />
              </Form.Group>
            </Form>
            </div>
                                    </Tab>}
                                </Tabs>


                              </div>

                            </div>
                            {/* <div className="dismissAllBtn" >
                              Dismiss all
                              <img className="dismissAllCloseBtn" src={require("./Images/drafting-compass Copy.svg")} />
                              </div>
                              <div className="downloadbtn" >
                              Download
                            
                              </div> */}


                            <div className="alertClose">
                              <a className="announcementCloseBtnAncr" onClick={() => this.handleCloseAnnouncementBtnClick()}>
                                <img className="alertCloseBtn" src={require("./Images/drafting-compass Copy.svg")} />
                              </a>
                            </div>




                          </div>

                          <div className="profileCntnrDiv text-left" hidden={this.state.hideProfileHolderDiv}>
                            <div className="mb-3">
                              <a className="profileCloseBtnAncr" onClick={() => this.handleCloseProfileBtnClick()}>
                                <img className="profileCloseBtn" src={require("./Images/hmcc-circle-close.svg")} />
                              </a>
                            </div>
                            <div className="row mt-2">
                              <div className="col-10"><h6 className="graphikmedium " style={{ fontSize: "16px" }}>{i18n.t('Profile')}</h6></div>
                              <div className="col-2 text-right"><img className="profileIcon hidden" src={require("./Images/edit-grey-icon.svg")} /></div>
                              <div className="col-12 mt-4">
                                <div className="media">
                                  <img className="mr-2 profile-img" src={require("./Images/profileicon .svg")} />
                                  <div className="media-body">
                                    <a target="_blank" href="https://people.accenture.com/" className="ProfileName mb-1 userName">{this.state.profileName}</a>
                                    <p className="ProfilePlace small"></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* {((this.state.userDetails.featureIds &&
                            this.state.userDetails.featureIds.some(
                              (val) => val == 28
                            )) || this.state.userDetails.dashboardRole == ADMIN) && (<div className="row mt-4 pt-1">
                              <div className="col-12" ><h6 className="graphikmedium" style={{ cursor: 'pointer',visibility:'hidden' }} onClick={() => this.announcementPopUp(true)}>{i18n.t('Announcements')}</h6></div>

                            </div>)} */}
                            {/* <div className="row mt-4 pt-1">
                              <div>
                              <div className="col-6"><h6 className="graphikmedium" style={{ color: "gray" }}>{i18n.t('Dark Theme')}</h6></div>
                              <div className="col-6" id="switch-hldr-hmcc">
                                <div className="switchContainerTheme disable">
                                  <label>
                                    <input checked={this.state.isCheckedTheme} onChange={this._handleChangeTheme} className="switchTheme DarkTheme" type="checkbox" />
                                    <div>
                                      <div>
                                       
                                      </div>
                                    </div>
                                  </label>
                                </div>

                              </div>
                              </div>
                            </div> */}
                            {/* <div className="row mt-4 pt-1 profileLangaugeHldr">
                            <div className="col-12 mb-2"><h6 className="graphikmedium">{i18n.t('Language')}</h6></div>
                            {this.state.languageList.map(languages => (
                              <div className="col-12">
                                <input type="radio" id={languages.languageDescription} checked={this.props.app.languageCode === languages.languageCode} className="mr-2" name="Language" value={languages.languageCode}
                                  onChange={(e) => this.handleLanguageChange(e)} />
                                <label for={languages.languageDescription}>{languages.languageDescription}</label>
                              </div>
                            ))}


                          </div> */}
                            <div className="row mt-5 pt-1">
                              <div className="col-12 mb-2"><h6 className="graphikmedium">{i18n.t('Language')}</h6></div>
                              <div className="dropdown" id="dropdown1">
                                <button className={"hmcc-drop-btn  language-dropDown "}
                                  style={{ marginLeft: "1rem", cursor: "pointer" }}
                                  onClick={() => this.toggleDropdown("Select Language")}>
                                  <div
                                    className='graphikmedium' style={{ color: "rgb(239, 242, 245)", fontSize: "0.8rem" }}

                                  >{
                                      this.props.app.language
                                    }
                                  </div>
                                  <img
                                    style={{ marginRight: "-1em", marginTop: "-0.6rem" }}
                                    src={require("../src/Images/drop-down-arrow.png")} />{" "}
                                  <div
                                    className='row m-0 mt-2'>
                                  </div>
                                </button>
                                <div
                                  className="languageDropdown-options"
                                  hidden={this.state.openedDropdown == "Select Language" ? this.state.hideLanguageDiv : true}

                                >
                                  <div
                                    id="searchbox"
                                    className="hmcc-searchbox"
                                    hidden={this.state.openedDropdown == "Select Language" ? this.state.hideLanguageDiv : true}
                                  //  hidden={this.state.openedDropdown == "Select Language" ? this.state.searchedLanguageText : ''}
                                  >
                                    {this.state.openedDropdown == "Select Language" ? (
                                      <input
                                        type="text"
                                        className="b9-languageSearch languageSearch graphikregular"
                                        placeholder={i18n.t('Search') + '..'}
                                        id="myInput"
                                        onChange={this.onChangeLanguage}
                                        value={this.state.searchedLanguageText}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {this.state.languageListFiltered.map(languages => {
                                    return (
                                      <div id='myDropdownB9' onClick={() => this.onSelectLanguage(languages)} className={
                                        this.state.openedDropdown == "Select Language" ? "dropdown-content graphikregular show" : "dropdown-content graphikregular"
                                      }>
                                        {i18n.t(languages.languageDescription)}
                                      </div>
                                    )
                                  })}

                                </div>
                              </div>

                            </div>

                            <div
                              className="logoutIcon"
                              onClick={() => {
                                this.logout();
                              }}
                            >
                              <FontAwesomeIcon icon={faSignOutAlt} />
                              <span className="ml-1">  Logout </span>
                            </div>

                            <div style={{ position: 'absolute', bottom: '0.2vh', width: '88%' }}>
                              <hr class="versionAndTermsOfUse" />
                              <div className="row  fontChange">
                                <div className="col-6">
                                  {APP_VERSION}
                                </div>
                                <div className="col-6 termsOfUse">
                                  <a target="_blank" href="https://in.accenture.com/protectingaccenture/terms-of-service-and-privacy-notice/" style={{ color: "white" }}>Terms of Use</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}
                      <div style={this.state.showIntOpsJourney ? { display: "block" } : { display: "none" }}>
                        <IntOps userDetails={this.state.userDetails} config={this.state.tab1Config} getTranslations={this.getTranslations} getTranslatedText={this.getTranslatedText}
                          currentApp={this.state.currentApp}
                          isConnected={this.state.isConnected}
                          callUserEnagementSessionAPI={this.callUserEnagementSessionAPI}
                          updatePBToken={this.updatePBToken}
						  alertObject={this.state.alertObject}
															  
                        />
                      </div>
                      <div style={this.state.showHumanAndMachine ? { display: "block" } : { display: "none" }}>
                        <HumanAndMachine
                          userDetails={this.state.userDetails}
                          config={this.state.tab2Config}
                          isLoggingEnabled={this.state.isLoggingEnabled}
                          onChangeDeliveryLocations={this.onChangeDeliveryLocations}
                          getTranslations={this.getTranslations}
                          getTranslatedText={this.getTranslatedText}
                          showHMtab={this.state.showHMtab}
                          switchHumanAndMachineTab={this.switchHumanAndMachineTab}
                          announcementPopUp={this.announcementPopUp}
                          announcements={this.state.announcements}
                          showPopUp={this.showAnnouncement}
                          showProfile={this.state.showProfile}
                          // showAnnouncements={this.state.showAnnouncements}
                          popupAnnouncement={this.state.popupAnnouncement}
                          hideFilterHolderDiv={this.state.hideFilterHolderDiv}
                          showConfidentialModal={this.state.showConfidentialModal}
                          showConfPopup={this.state.showConfPopup}
                          confidentialPopUp={this.confidentialPopUp}
                        />
                      </div>
                      {this.state.showOperations && (
                        <Operations
                          userDetails={this.state.userDetails}
                          config={this.state.tab3Config}
                          currentApp={this.state.currentApp}
                          isConnected={this.state.isConnected}
                          getTranslations={this.getTranslations}
                          getTranslatedText={this.getTranslatedText}
                          sisenceConnectionError={this.state.sisenceConnectionError}
                          redirectedFromAlert={this.state.redirectedFromAlert}
                          drillDownID={this.state.drillDownID}
                          clearDrillDownID={this.clearDrillDownID}
                          callUserEnagementSessionAPI={this.callUserEnagementSessionAPI}
                        />
                      )}
                      {this.state.showBusinessOutcome && (
                        <BusinessOutcome
                          userDetails={this.state.userDetails}
                          config={this.state.tab4Config}
                          currentApp={this.state.currentApp}
                          isConnected={this.state.isConnected}
                          getTranslations={this.getTranslations}
                          getTranslatedText={this.getTranslatedText}
                          callUserEnagementSessionAPI={this.callUserEnagementSessionAPI}
                        />)}
                      {/* </div> */}
                      {this.state.showAppStore && (
                        <AppStore
                          userDetails={this.state.userDetails}
                          config={this.state.tab5Config}
                          currentApp={this.state.currentApp}
                          isConnected={this.state.isConnected}
                          setActiveScreen={this.setActiveScreen}
                          callUserEnagementSessionAPI={this.callUserEnagementSessionAPI}
                          updatePBToken={this.updatePBToken}
                        />)}

                      <Modal
                        show={this.state.showModal}
                        onHide={() => this.hideModal()}
                        backdrop="static"
                        dialogClassName="modal-90w popup-width"
                        aria-labelledby="example-custom-modal-styling-title"
                      >
                        <Modal.Header
                          closeButton={true}
                          className="dashboard-modal-header"
                        ></Modal.Header>
                        <Modal.Body>
                          <div style={{ color: 'white', textAlign: 'center' }}><h6>Pop-up Blocker is enabled! Please add synopshub.accenture.com to your exception list for a seemless experience.</h6></div>
                        </Modal.Body>
                      </Modal>


                      <Modal
                        show={this.state.showFeedbackModal}
                        onHide={() => this.hideFeedbackModal()}
                        dialogClassName="feedback-modal"
                        size="lg"
                      >
                        <Modal.Header closeButton className="feedback-modal-header">
                          <Modal.Title className="feedback-modal-title"><img height='24px' width='24px' src={feedback1}></img> &nbsp;&nbsp; <span>Support and Feedback</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                          // className="feedback-modal"	
                          style={{ minHeight: "66vh" }}
                        >
                          <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              {/* <Form.Label className="feedback-label">What type of feedback would you like to provide?</Form.Label> */}
                              <div className='row mt-5'>
                                <div className='feedback-productInfo' onClick={() => this.handleFeedbackChange("type", 'productInfo')}>
                                  {this.state.type != 'productInfo' && (<img src={signal} className="feedbackNewIdea"></img>)}
                                  {this.state.type == 'productInfo' && (<img src={signalSelected} className="feedbackNewIdea"></img>)}
                                  {this.state.type != 'productInfo' && (<div className="feedbackLabel">Product Information</div>)}
                                  {this.state.type == 'productInfo' && (<div className="feedbackLabelSelect">Product Information</div>)}
                                  {this.state.type == 'productInfo' && (<div className="selectedFeedbackType"></div>)}
                                </div>
                                {/* <div className='feedback-general' onClick={() => this.handleFeedbackChange("type", 'general')}>
                                {this.state.type != 'general' && (<img src={general}></img>)}
                                {this.state.type == 'general' && (<img src={generalSelected}></img>)}
                                {this.state.type != 'general' && (<div className="feedback-type-label">Raise an issue</div>)}
                                {this.state.type == 'general' && (<div className="feedback-type-labelSelect">Raise an issue</div>)}
                                {this.state.type == 'general' && (<div className="selected-type"></div>)}
                              </div> */}
                                <div className='feedback-newidea' onClick={() => this.handleFeedbackChange("type", 'newIdea')}>
                                  {this.state.type != 'newIdea' && (<img src={newIdea} className="feedbackNewIdea"></img>)}
                                  {this.state.type == 'newIdea' && (<img src={newIdeaSelected} className="feedbackNewIdea"></img>)}
                                  {this.state.type != 'newIdea' && (<div className="feedbackLabel" >Report an Issue, Request Access & Feedback</div>)}
                                  {this.state.type == 'newIdea' && (<div className="feedbackLabelChange" >Report an Issue, Request Access & Feedback</div>)}
                                  {this.state.type == 'newIdea' && (<div className="selectedFeedbackType" ></div>)}                  </div>
                                {/* <Button variant="outline-dark" onClick={()=>this.handleFeedbackChange("type", 'general')}>General</Button>	
                              <Button variant="outline-dark" onClick={()=>this.handleFeedbackChange("type", 'newIdea')}>New Idea</Button> */}
                              </div>
                              {this.state.type == 'productInfo' && (
                                <div>
                                  <div className="row">
                                    <div className="col-1">
                                      <div className="row">
                                        <div className="large-8 large-centered ml-4">
                                          <div className="wrapper">
                                            <div className="circle">
                                              <h5 className="total-issues">1</h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-11">
                                      <div className="mt-2"><a href="https://in.accenture.com/technologyforoperations/toolsnavigator/synops-core-experience/synops-hub/" target="_blank" style={{ textDecoration: "underline" }}>Click here to open the product information page</a></div>
											              </div>																																																											  
                                  </div>
                                </div>

                              )}
                              {this.state.type == 'general' && (
                                <div>
                                  <div className="row">
                                    <div className="col-1">
                                      <div className="row">
                                        <div className="large-8 large-centered ml-4">
                                          <div className="wrapper">
                                            <div className="circle">
                                              <h5 className="total-issues">1</h5>


                                            </div>
                                            <div className="vertical-line"></div>
                                          </div>
                                          <div className="wrapper">
                                            <div className="circle">
                                              <h5 className="total-issues">2</h5>
                                            </div>
                                            <div className="vertical-line"></div>
                                          </div>
                                          <div className="wrapper">
                                            <div className="circle">
                                              <h5 className="total-issues">3</h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-11">
                                      <div className="mt-2"><b>To report issues or new access, please raise a ticket </b></div>
                                      <div className="mt-5"><b>Know more about </b><a href="https://kxdocuments.accenture.com/contribution/a1efbc5a-1339-4898-9e62-4c33eeb415e8" target="_blank" style={{ textDecoration: "underline" }}>  how to raise a ticket</a></div>
                                      <div className="mt-5"><a href=" https://bps-portal.accenture.com/portal/pages/Accenture/home" target="_blank" style={{ textDecoration: "underline" }}> Click here to raise a ticket</a></div>
                                    </div>
                                  </div>
                                </div>

                              )}
                              {(this.state.type == 'newIdea' && !this.state.feedbackSubmitted) && (
                                <div>
                                  <div className="row feedbackradioButtons">
                                    <div className="ml-2">
                                      <Form.Check
                                        type="radio"
                                        name="app_name"
                                        label="Report an Issue"
                                        defaultChecked
                                        onChange={(e) =>
                                          this.handleMessageChange("Report an Issue", e.target.value)
                                        }
                                      />{" "}
                                      
                                    </div>
                                    <div className="ml-2">
                                      <Form.Check
                                        type="radio"
                                        name="app_name"
                                        label="Access"
                                        onChange={(e) =>
                                          this.handleMessageChange("Access", e.target.value)
                                        }
                                      />{" "}
                                      
                                    </div>
                                    <div className="ml-2">
                                      <Form.Check
                                        type="radio"
                                        name="app_name"
                                        label="Feedback"
                                        onChange={(e) =>
                                          this.handleMessageChange("Feedback", e.target.value)
                                        }
                                      />{" "}
                                      
                                    </div>
                                  </div>

                                  <Form.Control
                                    className='feedback-select'
                                    placeholder='Command Center'
                                    readOnly
                                  />
                                </div>)}
                            </Form.Group>
                            {(this.state.type == 'newIdea' && !this.state.feedbackSubmitted) && (
                              <div>
                                <Form.Group controlId="exampleForm.ControlTextarea1" style={{ marginLeft: '16px' }}>
                                  <Form.Control
                                    as="textarea"
                                    rows="3"
                                    //  maxLength={500}
                                    placeholder={this.state.commentText}
                                    onChange={(e) =>
                                      this.handleFeedbackChange("feedbackMessage", e.target.value)
                                    }
                                    value={this.state.feedbackBody}
                                    style={{ resize: "vertical", minHeight: "100px",width:"98%" }}
                                  >
                                  </Form.Control>
                                </Form.Group>
                                <div class="row">
                                  <div class="form-group col-md-12">

                                  <label for="filePicker" className="attachmentPosition" onChange={this.handleFile} >
                                      <FontAwesomeIcon className="plusButton" icon={faPlusCircle} />
                                      Add Attachment
                                      <input id="filePicker" style={{ visibility: "hidden",marginLeft:"-25rem"}} type="file" multiple />
                                      </label>
                                      <div className="fileColor filetypeFont">(.jpg, .png, .pdf, .docx)</div>
                                      <div className="fileColor attachmentFont">**(3 attachments upto 15 MB are permitted)</div>

                                    {(this.state.selectedFile.length > 0) &&
                                      // this.state.selectedFile.map(files => <p style={{float:"right"}}>{files[2].name}</p>)
                                      <p className="uploaded_file">

                                        {this.state.selectedFile.map((name, index) => <p style={{fontWeight:"bold",marginBottom:"0"}}>{name.name}
                                          <span key={index} onClick={() => this.deleteIcon(index)} className="ml-2 crossIcon" style={{cursor: "pointer" }}>x</span>
                                        </p>)}

                                      </p>
                                    }

                                  </div>
                                  {this.state.fileExtensionError && (
                                    <p className="fileUploadMessage">
                                      Please upload valid file.
                                    </p>
                                  )}
                                  {this.state.fileSizeError && (
                                    <p className="fileSizeMessage">
                                      The file you are attaching exceeds the specified limit.
                                    </p>
                                  )}
                                  {this.state.totalfileCountError && (
                                     <p className="morefilesErrorMessage">
                                     Maximum 3 files can be uploaded.
                                   </p>
                                  )}
                                  {this.state.duplicateFileErrorMsg && (
                                    <p className="fileErrorMessage">
                                    The file has already been uploaded.
                                  </p>
                                  )}
                                </div>
                              </div>

                            )}

                            {(this.state.type == 'newIdea' && this.state.feedbackSubmitted) && (
                              <div className="text-center">

                                <img src={feedbackSaved} height='24px' width='24px' className="feedback-submit"></img>
                                <span className="feedbackSubmittext">{this.state.submittedIssue} Submitted Successfully</span>
                                <div className="newFeedback">
                                  <span className="newFeedbackSubmitted" onClick={() => this.feedbackSubmitNew()}
                                  >Click here </span>
                                  to provide new {this.state.popUpText}.

                                </div>
                              </div>
                            )

                            }
                          </Form>
                          </Modal.Body>
                        {this.state.type == 'general' &&
                          (<Modal.Footer className="feedback-footer-general">
                          </Modal.Footer>)}
                        {this.state.type == 'newIdea' && !this.state.feedbackSubmitted &&
                          (<Modal.Footer className="feedback-footer">
                            <Button className='footer-btn submitFeedbackFocus' disabled={(this.state.feedbackBody.trim().length == 0)} onClick={() => this.generateNewIdeaScript()}>Submit</Button>
                          </Modal.Footer>)}
                      </Modal>
                      <Modal
                        show={this.state.openSubmit}
                        onHide={() => this.hideSubmit()}
                        dialogClassName="feedback-modal"
                        className="feedbackSubmitted-modal"
                        backdrop="static"
                      >
                        <Modal.Header closeButton className="feedback-modal-header-submit">
                          <Modal.Title className="feedback-modal-title"><img height='24px' width='24px' src={feedback1}></img> &nbsp;&nbsp; <span>Support and Feedback</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                          // className="feedback-modal"	
                          style={{ height: "32vh" }}
                        >
                          <div >

                            <div style={{ padding: "25px" }}>
                              Do you want to submit this {this.state.popUpText} ?
                              <div className="row">
                                <div className="col-12 pr-0 text-right mt-5">

                                  <Button
                                    className='footer-btn'
                                    onClick={() => this.feedbackSubmit()}>Yes</Button>
                                  <Button
                                    className='footer-btn ml-3'
                                    onClick={() => this.hideSubmit()}>No</Button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      <img onClick={() => this.showFeedbackModal()} className='helpIcon' hidden={!this.state.showFeedbackIcon} src={feedback}></img>

                    </section>
                  </body>
                  <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 55}
                    onIdle={this.handleOnIdle}
                    debounce={250}
                  />
                </div>
            ) : (!this.state.invalidUser && (<Connecting />))}
          {/* </Suspense> */}
          </div>
          <Modal
            show={this.state.showRequestAccessModal}
            onHide={() => this.hideRequestAccessModal()}
            // dialogClassName="feedback-modal"
            size="lg"
          >
             <Modal.Header closeButton style={{borderBottom: "none"}}>
             <div>
              <span style={{marginLeft: "0.8rem", fontSize: "1.1rem"}}>
                Request Access
              </span>
            </div>
            </Modal.Header>
            <Modal.Body
              // className="feedback-modal"	
              style={{ minHeight: "66vh" }}
            >
              <Form>
                <div>
                  <Form.Group>
                  <Form.Control
                        className='feedback-select'
                        placeholder='Command Center'
                        readOnly
                      />
                      
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1" style={{ marginLeft: '16px' }}>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        //  maxLength={500}
                        placeholder={this.state.requestAccessCommentText}
                        onChange={(e) =>
                          this.handleRequestAccessChange(e.target.value)
                        }
                        value={this.state.feedbackBody2}
                        style={{ resize: "vertical", minHeight: "100px",width:"98%" }}
                      >
                      </Form.Control>
                    </Form.Group>
                    <div class="row">
                      <div class="form-group col-md-12">

                      <label for="filePicker" className="attachmentPosition" onChange={this.handleRequestFile} >
                          <FontAwesomeIcon className="plusButton" icon={faPlusCircle} />
                          Add Attachment
                          <input id="filePicker" style={{ visibility: "hidden",marginLeft:"-25rem"}} type="file" multiple />
                          </label>
                          <div className="fileColor filetypeFont">(.jpg, .png, .pdf, .docx)</div>
                          <div className="fileColor attachmentFont">**(3 attachments upto 15 MB are permitted)</div>

                        {(this.state.selectedFile2.length > 0) &&
                          // this.state.selectedFile.map(files => <p style={{float:"right"}}>{files[2].name}</p>)
                          <p className="uploaded_file">

                            {this.state.selectedFile2.map((name, index) => <p style={{fontWeight:"bold",marginBottom:"0"}}>{name.name}
                              <span key={index} onClick={() => this.deleteRequestIcon(index)} className="ml-2 crossIcon" style={{cursor: "pointer" }}>x</span>
                            </p>)}

                          </p>
                        }

                      </div>
                      {this.state.fileExtensionError2 && (
                        <p className="fileUploadMessage">
                          Please upload valid file.
                        </p>
                      )}
                      {this.state.fileSizeError2 && (
                        <p className="fileSizeMessage">
                          The file you are attaching exceeds the specified limit.
                        </p>
                      )}
                      {this.state.totalfileCountError2 && (
                          <p className="morefilesErrorMessage">
                          Maximum 3 files can be uploaded.
                        </p>
                      )}
                      {this.state.duplicateFileErrorMsg2 && (
                        <p className="fileErrorMessage">
                        The file has already been uploaded.
                      </p>
                      )}
                    </div>
                  </div>
                  </Form>
                    </Modal.Body><Modal.Footer className="feedback-footer">
                      <Button className='footer-btn submitFeedbackFocus' disabled={(this.state.feedbackBody2.trim().length == 0)} onClick={() => this.submitReqAccess()}>Submit</Button>
                    </Modal.Footer>
                </Modal>
                
                <Modal
                   show={this.state.showSuccessPopup}
                   onHide={() => this.hideSuccessModal()}
                  dialogClassName=""
                  className="feedbackSubmitted-modal requestSuccess"
                  backdrop="static"
                >
                  <Modal.Header closeButton style={{borderBottom: "none"}}>
                    <Modal.Title style={{color: "none"}}>
                      <span style={{color: "black"}}>Request Access</span>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body
                    // className="feedback-modal"
                    style={{height: "32vh"}}
                  >
                    <div>
                      <div style={{padding: "25px"}}>
                        <img
                          src={feedbackSaved}
                          height="24px"
                          width="24px"
                          className="feedback-submit"
                          style={{marginTop:'0'}}
                        ></img>
                        <span className="feedbackSubmittext">
                          Access Request Submitted Successfully
                        </span>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
        </BrowserRouter>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.appReducer,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenuDisplay: (boolean) => {
      dispatch(toggleMenuDisplay(boolean));
    },
    setFilters: (obj) => {
      dispatch(setFilters(obj));
    },
    setClients: (arr) => {
      dispatch(setClients(arr));
    },
    setDemoClient: (bool, parentTenantID) => {
      dispatch(setDemoClient(bool, parentTenantID));
    },
    setUserPreferedLanguage: (code, name) => {
      dispatch(setUserPreferedLanguage(code, name));
    },
    setLanguageTranslations: (arr) => {
      dispatch(setLanguageTranslations(arr));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMsal(App));