import React from "react";
import { connect } from "react-redux";
import { toggleMenuDisplay, setFilters } from "../../actions/AppActions";
import { Modal, Row, Col, } from "react-bootstrap";
import { ENVIRONMENT } from "../../StringConstants";
import i18n from "i18next";
import Tabs from "@material-ui/core/Tabs";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import CssDashboard from "./CssDashboard";
import OtherTickets from "./OtherTickets"
import OtherDash from "./OtherDash";
import { monthInOpened } from "./FilterForMonths";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Connecting from "../../Connecting";
import Logo from "../../Images/Connecting.PNG";

let dashFlow = [
  {
    dash: "5fabb3655fd2f53f3861d479",
    wgt: [
      { id: "5fabb6c95fd2f53f3861d49f", ref: true },
      { id: "5fabb3655fd2f53f3861d47b", ref: true },
      { id: "5fabc1124be9354e24e573a9", ref: true },
      { id: "5fabb4635fd2f53f3861d491", ref: true },
    ]
  },
  {
    dash: "5f7d7214e841931bc03dee75",
    wgt: [
      { id: "5f7d7214e841931bc03dee85", ref: true },
      { id: "615aefc2f9cae9003651f853", ref: true },
      { id: "5f7d7214e841931bc03dee82", ref: true },
      { id: "6165901896ef650035143106", ref: true },
      { id: "5f7d7214e841931bc03dee87", ref: true },
      { id: "6165904796ef65003514310a", ref: true },
      { id: "5f7d7214e841931bc03dee81", ref: true },
      { id: "6165907a96ef650035143118", ref: true },
      { id: "5f7d7214e841931bc03dee88", ref: true },
      { id: "616590a296ef650035143120", ref: true },
      { id: "5f7d7214e841931bc03dee8b", ref: false },
    ]
  },
];
class Operations extends React.Component {
  state = {
    filterArray: [],
    filterArrayOtherTickets: [],
    filterPortfolio: {},
    sisenseFrames: [],
    isRendered: false,
    applyFiltersLater: false,
    showModal: false,
    showModal2: false,
    showLoader: false,
    showDashModal: false,
    activeTab: 1,
    cssPayload: {},
    timeframe: monthInOpened[2].data,
    timeFrameDetails: monthInOpened[2].data[5],
    timeVal: "Days",
    timeDim: "Last 180 Days",
    reloadDash: false,
    preWgt: [],
  }
  applyDbFilter = (db, dashType) => {
    const filterOptions = {
      save: true,
      refresh: true,
      unionIfSameDimensionAndSameType: false
    };
    let filters = [...this.state.filterArray];
    if (dashType == 'dbModal') {
      db.$$model.filters.update([filters[0], this.state.filterPortfolio], filterOptions);
    } else {
      if (db.id !== '5ee103515ae741084c1258a7')
        db.$$model.filters.update(filters[0], filterOptions);
    }
  }

  startFlow = (nextProps) => {
    if (this.props.currentApp) {
      this.setState({ showLoader: true });
      dashFlow.forEach(d => {
        this.props.currentApp.dashboards.load(d.dash).then(dash => {
          this.setState({ showLoader: false });
          this.applyDbFilter(dash);
          d.wgt.forEach(w => {
            const div = document.getElementById(w.id);
            div.innerHTML = "";
            switch (w.id) {
              case "5f7d7214e841931bc03dee85": //Foundation
                div.onclick = () => this.handleModal('Foundation'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              case "5f7d7214e841931bc03dee82": //Automated (Tools & Platform)
                div.onclick = () => this.handleModal('Finance and Accounting'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              case "5f7d7214e841931bc03dee87": //Automated (RPA)
                div.onclick = () => this.handleModal('Robotic Process Automation'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              case "5f7d7214e841931bc03dee81"://Insights Driven
                div.onclick = () => this.handleModal('Analytics'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              case "5f7d7214e841931bc03dee88": //Intelligent (AI Advisors)
                div.onclick = () => this.handleModal('Artificial Intelligence'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              default:
                break;
            }
            div.style.cursor = "pointer";
            const wgt = dash.widgets.get(w.id);
            wgt.container = div;
            wgt.refresh();
            const ww = this.state.preWgt;
            ww[w.id] = wgt;
            this.setState({ preWgt: ww });
          });
        });
      });
      setTimeout(this.setRefInterval, 10000);
    } else if(nextProps.currentApp) {
      this.setState({ showLoader: true });
      dashFlow.forEach(d => {
        nextProps.currentApp.dashboards.load(d.dash).then(dash => {
          this.setState({ showLoader: false });
          this.applyDbFilter(dash);
          d.wgt.forEach(w => {
            const div = document.getElementById(w.id);
            div.innerHTML = "";
            switch (w.id) {
              case "5f7d7214e841931bc03dee85": //Foundation
                div.onclick = () => this.handleModal('Foundation'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              case "5f7d7214e841931bc03dee82": //Automated (Tools & Platform)
                div.onclick = () => this.handleModal('Finance and Accounting'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              case "5f7d7214e841931bc03dee87": //Automated (RPA)
                div.onclick = () => this.handleModal('Robotic Process Automation'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              case "5f7d7214e841931bc03dee81"://Insights Driven
                div.onclick = () => this.handleModal('Analytics'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              case "5f7d7214e841931bc03dee88": //Intelligent (AI Advisors)
                div.onclick = () => this.handleModal('Artificial Intelligence'); //Modal dash id 5e8ae666ae212d3bb4b02058
                break;
              default:
                break;
            }
            div.style.cursor = "pointer";
            const wgt = dash.widgets.get(w.id);
            wgt.container = div;
            wgt.refresh();
            const ww = this.state.preWgt;
            ww[w.id] = wgt;
            this.setState({ preWgt: ww });
          });
        });
      });
      setTimeout(this.setRefInterval, 10000);
    }

  };

  setRefInterval = () => {
    dashFlow.forEach(d => {
      d.wgt.forEach(w => {
        if (w.ref) {
          const wgt = this.state.preWgt[w.id];
          setInterval(() => {
            if (wgt) wgt.$$model.refresh();
          }, 1800000); //1,800,000
        }
      })
    })
  }

  loadDash = (id, url, nextProps) => {
    if (this.props.currentApp) {
      // this.state.subTabs.map((db, i) => {
      //URL parser
      // let url = new URL(db.url);
      //Get dashbord IDs
      if (id != 'dbModal') {
        this.setState({ showLoader: true });
        if (url) {
          let dashboardId = url.split("/");
          this.props.currentApp.dashboards
            .load(dashboardId[dashboardId.length - 1])
            .then((dash) => this.afterLoadDash(dash, id));
        }
      } else {
        //detail dashboard(widget modal)
        this.props.currentApp.dashboards
          .load(url)
          .then((dash) => this.afterLoadDash(dash, id));
      }
    } else if (nextProps) {
      if (id != 'dbModal') {
        this.setState({ showLoader: true });
        if (url) {
          let dashboardId = url.split("/");
          nextProps.currentApp.dashboards
            .load(dashboardId[dashboardId.length - 1])
            .then((dash) => this.afterLoadDash(dash, id));
        }
      } else {
        //detail dashboard(widget modal)
        nextProps.currentApp.dashboards
          .load(url)
          .then((dash) => this.afterLoadDash(dash, id));
      }

    }

  };
  afterLoadDash = (db, divId) => {
    if (divId == 'dbModal') {
      this.applyDbFilter(db, 'dbModal');
    } else {
      this.applyDbFilter(db, 'mainDash');
    }
    let widgets = [];
    const layout = db.$$model.layout.columns[0].cells;
    const wRoot = document.getElementById(divId);
    wRoot.innerHTML = "";
    let i = 0;
    layout.forEach(col => {
      i++;
      const row = this.createRow(i);
      col.subcells.forEach(cell => {
        const width = cell.width ? cell.width : 100;
        row.appendChild(this.createLayout(i, cell.elements[0], width));
        widgets.push(cell.elements[0].widgetid);
      })
      wRoot.appendChild(row);
      if (i === layout.length) setTimeout(() => this.loadWgt(db, widgets), 1000);
    });
  };
  createRow = (i) => {
    const row = document.createElement('div');
    row.className = "row";
    row.style.marginLeft = "0px";
    row.style.marginRight = "0px";
    row.id = "row" + i;
    return row;
  };
  createLayout = (i, el, width) => {
    const headerDiv = document.createElement("div");
    headerDiv.id = "head" + el.widgetid;
    headerDiv.style.padding = "5px 20px";
    headerDiv.style.color = "white";
    headerDiv.style.backgroundImage = "linear-gradient(rgb(34, 145, 255), rgb(3, 48, 198))";

    const height = el.height === "" || el.height === 512 ? "100%" : el.height.toString().replace("px", "") + "px";
    const div = document.createElement('div');
    div.id = el.widgetid;
    div.style.height = height;
    div.style.backgroundColor = "rgb(0, 0, 68)";

    const pdiv = document.createElement('div');
    pdiv.className = "layout row" + i
    pdiv.style.width = "calc(" + width + "% - 4px)";
    pdiv.style.margin = "2px";
    pdiv.style.paddingLeft = "20px";
    pdiv.style.paddingRight = "20px";

    pdiv.appendChild(headerDiv);
    pdiv.appendChild(div);

    return pdiv;
  };


  loadWgt = (db, widgets) => {
    widgets.forEach(wgt => {
      const div = document.getElementById(wgt)
      const oWgt = db.widgets.get(wgt);
      if (oWgt) {
        const head = document.getElementById("head" + wgt);
        if (oWgt.title.length > 0 && oWgt.title !== "RICHTEXT_MAIN.TITLE")
          head.innerHTML = oWgt.title;
        else
          if (head) head.style.display = "none";

        if (!oWgt.container)
          oWgt.container = div;
        else {
          const clone = Object.assign(oWgt);
          clone.$$container = null;
          clone.container = div;
        }
        oWgt.refresh();
        const recurse = () => {
          if (oWgt.refreshing)
            setTimeout(recurse, 1000);
          else {
            if (oWgt.id == '5f7d7214e841931bc03dee85' || oWgt.id == '5f7d7214e841931bc03dee82' || oWgt.id == '5f7d7214e841931bc03dee87' || oWgt.id == '5f7d7214e841931bc03dee81' || oWgt.id == '5f7d7214e841931bc03dee88') {
              switch (oWgt.id) {
                case "5f7d7214e841931bc03dee85": //Foundation
                  div.onclick = () => this.handleModal('Foundation'); //Modal dash id 5e8ae666ae212d3bb4b02058
                  break;
                case "5f7d7214e841931bc03dee82": //Automated (Tools & Platform)
                  div.onclick = () => this.handleModal('Finance and Accounting'); //Modal dash id 5e8ae666ae212d3bb4b02058
                  break;
                case "5f7d7214e841931bc03dee87": //Automated (RPA)
                  div.onclick = () => this.handleModal('Robotic Process Automation'); //Modal dash id 5e8ae666ae212d3bb4b02058
                  break;
                case "5f7d7214e841931bc03dee81"://Insights Driven
                  div.onclick = () => this.handleModal('Analytics'); //Modal dash id 5e8ae666ae212d3bb4b02058
                  break;
                case "5f7d7214e841931bc03dee88": //Intelligent (AI Advisors)
                  div.onclick = () => this.handleModal('Artificial Intelligence'); //Modal dash id 5e8ae666ae212d3bb4b02058
                  break;
                default:
                  break;
              }
              div.style.cursor = "pointer";
            }

            if (div.style.height === "auto") div.style.height = "100%";

          }
        }
        recurse();
      }

    })
    this.setState({ showLoader: false }, ()=> {
      switch (this.props.drillDownID) {
        case "5f7d7214e841931bc03dee85": //Foundation
          this.handleModal('Foundation'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        case "5f7d7214e841931bc03dee82": //Automated (Tools & Platform)
          this.handleModal('Finance and Accounting'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        case "5f7d7214e841931bc03dee87": //Automated (RPA)
          this.handleModal('Robotic Process Automation'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        case "5f7d7214e841931bc03dee81"://Insights Driven
          this.handleModal('Analytics'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        case "5f7d7214e841931bc03dee88": //Intelligent (AI Advisors)
          this.handleModal('Artificial Intelligence'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        default:
          break;
      }
      this.props.clearDrillDownID();
    });
  };


  componentWillMount() {
    let clients;
    if (this.props.app.filterObject.tenant.length > 0) {
      clients = [...this.props.app.filterObject.tenant];
    } else {
      let tenants = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
      });
      clients = [...tenants];
    }
    let clientsFilterObj;
    if (clients.length > 0) {
      let parentTenantPseudo = clients;
      if (this.props.app.demoClient && this.props.app.clientOptions.length == 1) {
        parentTenantPseudo = [this.props.app.clientOptions[0].pseudoName];
      } else if (this.props.app.demoClient) {
        parentTenantPseudo = [(this.props.app.clientOptions.find(item => item.isDemo == true)).pseudoName];
      }
      clientsFilterObj = {
        explicit: true,
        multiSelection: true,
        members: parentTenantPseudo.map((a) => a.toUpperCase()),
      }
    } else {
      clientsFilterObj = {
        all: true,
      }
    }
    this.setState({
      filterArray: [
        {

          levels: [
            {
              dim: "[Ticket.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Pseudo"],
              },
            },
            {
              dim: "[Ticket.Client]",
              datatype: "text",
              title: "Client",
              filter: clientsFilterObj,
            },
          ],
        },
      ],
      filterArrayOtherTickets: [
        {

          levels: [
            {
              dim: "[Service Tickets.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Pseudo"],
              },
            },
            {
              dim: "[Service Tickets.Client]",
              datatype: "text",
              title: "Client",
              filter: clientsFilterObj,
            },
          ],
        },
      ],
      cssPayload: [
        {

          levels: [
            {
              dim: "[Client.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Pseudo"],
              },
            },
            {
              dim: "[Client.Client]",
              datatype: "text",
              title: "Client",
              filter: clientsFilterObj,
            },
          ],
        },
        {
          jaql: {
            dim: "[Ticket.Opened At (Calendar)]",
            datatype: "datetime",
            title: "Months in Opened At",
            level: "days",
            filter: {
              last: {
                count: 180,
                offset: 0
              }
            }
          }
        }
      ]
    });
  }
  componentDidMount() {
    // let dashBoard1 = this.props.config.find(element => element.position == 1);//Announcements
    let dashBoard2 = this.props.config.find(element => element.position == 2);//Open tickets
    let dashBoard3 = this.props.config.find(element => element.position == 3);//OPERATIONS DASHBOARD
    //Uncomment this before building - LOCAL
    if (ENVIRONMENT !== 'local' && this.props.userDetails.isSisenseEnabled) {
      // this.loadDash('opDashboard1', dashBoard1.url);
      // this.loadDash('opDashboard2', dashBoard2.url, null);
      // this.loadDash('opDashboard3', dashBoard3.url, null);
      this.startFlow(this.props)
    }
    switch(this.state.activeTab){
      case 1: 
          this.props.callUserEnagementSessionAPI('Operations', "BPS App Tickets", 0, true)
          break;
      case 2: 
          this.props.callUserEnagementSessionAPI('Operations', "CSS Dashboard", 0, true)
          break;
      case 3: 
          this.props.callUserEnagementSessionAPI('Operations', "Other Tickets", 0, true)
          break;
        default:
          break;
    }
  }
  applyFilters = (filters) => {
    this.state.sisenseFrames.map(frame => {
      frame.dashboard.applyFilters(filters).then(function (result) {
        //  console.log("filter applied")
      });
    })
    this.setState({ applyFiltersLater: false });

  }
  componentWillReceiveProps(nextProps) {
    if (this.props.redirectedFromAlert != nextProps.redirectedFromAlert) {
      this.setTab(1)
      switch (nextProps.drillDownID) {
        case "5f7d7214e841931bc03dee85": //Foundation
          this.handleModal('Foundation'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        case "5f7d7214e841931bc03dee82": //Automated (Tools & Platform)
          this.handleModal('Finance and Accounting'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        case "5f7d7214e841931bc03dee87": //Automated (RPA)
          this.handleModal('Robotic Process Automation'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        case "5f7d7214e841931bc03dee81"://Insights Driven
          this.handleModal('Analytics'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        case "5f7d7214e841931bc03dee88": //Intelligent (AI Advisors)
          this.handleModal('Artificial Intelligence'); //Modal dash id 5e8ae666ae212d3bb4b02058
          break;
        default:
          break;
      }
      this.props.clearDrillDownID();
    }
    if (this.props.app.filterObject.tenant !== nextProps.app.filterObject.tenant) {
      let filterArray = [];
      let filterArrayOtherTickets = [];
      let cssPayload = [];
      if (nextProps.app.filterObject.tenant.length == 0 && this.props.userDetails.tenant.length == 0) {
        filterArray = [
          {
            "levels": [
              {
                "dim": "[Ticket.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "title": "Client",
                "dim": "[Ticket.Client]",
                "datatype": "text",
                "filter": {
                  "all": true
                }
              }
            ],
          }
        ]

        filterArrayOtherTickets = [
          {
            "levels": [
              {
                "dim": "[Service Tickets.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "title": "Client",
                "dim": "[Service Tickets.Client]",
                "datatype": "text",
                "filter": {
                  "all": true
                }
              }
            ],
          }
        ]

        cssPayload = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "title": "Client",
                "dim": "[Client.Client]",
                "datatype": "text",
                "filter": {
                  "all": true
                }
              }
            ],
          },
          {
            "jaql": {
              "dim": "[Ticket.Opened At (Calendar)]",
              "datatype": "datetime",
              "title": "Months in Opened At",
              "level": "days",
              "filter": {
                "last": {
                  "count": 180,
                  "offset": 0
                }
              }
            }
          }
        ]

      } else if (nextProps.app.filterObject.tenant.length == 0 && this.props.userDetails.tenant.length != 0) {

        let tenants = [];
        this.props.userDetails.tenant.map((item) => {
          tenants.push(item.pseudoName);
        });
        filterArray = [
          {
            "levels": [
              {
                "dim": "[Ticket.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "dim": "[Ticket.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": tenants.map(a => a.toUpperCase()) },
              },
            ],
          }
        ]

        filterArrayOtherTickets = [
          {
            "levels": [
              {
                "dim": "[Service Tickets.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "dim": "[Service Tickets.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": tenants.map(a => a.toUpperCase()) },
              },
            ],
          }
        ]

        cssPayload = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "dim": "[Client.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": tenants.map(a => a.toUpperCase()) },
              },
            ],
          },
          {
            "jaql": {
              "dim": "[Ticket.Opened At (Calendar)]",
              "datatype": "datetime",
              "title": "Months in Opened At",
              "level": "days",
              "filter": {
                "last": {
                  "count": 180,
                  "offset": 0
                }
              }
            }
          }
        ]
      } else {
        let parentTenantPseudo = nextProps.app.filterObject.tenant;
        if (nextProps.app.demoClient && nextProps.app.clientOptions.length == 1) {
          parentTenantPseudo = [nextProps.app.clientOptions[0].pseudoName];
        } else if (nextProps.app.demoClient) {
          parentTenantPseudo = [(nextProps.app.clientOptions.find(item => item.isDemo == true)).pseudoName];
        }
        filterArray = [
          {
            "levels": [
              {
                "dim": "[Ticket.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "dim": "[Ticket.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": parentTenantPseudo.map(a => a.toUpperCase()) },
              },
            ],
          }
        ]

        filterArrayOtherTickets = [
          {
            "levels": [
              {
                "dim": "[Service Tickets.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "dim": "[Service Tickets.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": parentTenantPseudo.map(a => a.toUpperCase()) },
              },
            ],
          }
        ]

        cssPayload = [
          {
            "levels": [
              {
                "dim": "[Client.Type]",
                "datatype": "text",
                "title": "Type",
                "filter": {
                  "explicit": true,
                  "userMultiSelect": false,
                  "multiSelection": false,
                  "members": ["Pseudo"],
                },
              },
              {
                "dim": "[Client.Client]",
                "datatype": "text",
                "title": "Client",
                "filter": { "explicit": true, "multiSelection": true, "members": parentTenantPseudo.map(a => a.toUpperCase()) },
              },
            ],
          },
          {
            "jaql": {
              "dim": "[Ticket.Opened At (Calendar)]",
              "datatype": "datetime",
              "title": "Months in Opened At",
              "level": "days",
              "filter": {
                "last": {
                  "count": 180,
                  "offset": 0
                }
              }
            }
          }
        ]
      }
      this.setState({ filterArray, filterArrayOtherTickets, cssPayload });
      // let dashBoard1 = this.props.config.find(element => element.position == 1);//Announcements
      let dashBoard2 = this.props.config.find(element => element.position == 2);//Open tickets
      let dashBoard3 = this.props.config.find(element => element.position == 3);//OPERATIONS DASHBOARD
      if (ENVIRONMENT !== 'local' && this.props.userDetails.isSisenseEnabled) {
        // this.loadDash('opDashboard1', dashBoard1.url);
        // this.loadDash('opDashboard2', dashBoard2.url, null);
        // this.loadDash('opDashboard3', dashBoard3.url, null);
        this.startFlow(this.props)
      }
    }
    if (nextProps.isConnected !== this.props.isConnected) {
      if (nextProps.isConnected && ENVIRONMENT !== 'local' && this.props.userDetails.isSisenseEnabled) {
        let dashBoard2 = this.props.config.find(element => element.position == 2);//Open tickets
        let dashBoard3 = this.props.config.find(element => element.position == 3);//OPERATIONS DASHBOARD
        // this.loadDash('opDashboard2', dashBoard2.url, nextProps);
        // this.loadDash('opDashboard3', dashBoard3.url, nextProps);
        this.startFlow(nextProps);
      }
    }
  }


  handleModal = (portfolio) => {
    let filterPortfolio;

    let dashId = '5e8ae666ae212d3bb4b02058';
    this.setState({ showDashModal: true })
    switch (portfolio) {
      case "Foundation":
        filterPortfolio = {
          "jaql": {
            "dim": "[Ticket.Portfolio]",
            "datatype": "text",
            "title": "Portfolio",
            "filter": { "members": ["Foundation"] }
          }
        }
        break;
      case "Finance and Accounting":
        filterPortfolio = {
          "jaql": {
            "dim": "[Ticket.Portfolio]",
            "datatype": "text",
            "title": "Portfolio",
            "filter": { "members": ["Finance and Accounting"] }
          }
        }
        break;
      case "Robotic Process Automation":
        filterPortfolio = {
          "jaql": {
            "dim": "[Ticket.Portfolio]",
            "datatype": "text",
            "title": "Portfolio",
            "filter": { "members": ["Robotic Process Automation"] }
          }
        }
        break;
      case "Analytics":
        filterPortfolio = {
          "jaql": {
            "dim": "[Ticket.Portfolio]",
            "datatype": "text",
            "title": "Portfolio",
            "filter": { "members": ["Analytics"] }
          }
        }
        break;
      case "Artificial Intelligence":
        filterPortfolio = {
          "jaql": {
            "dim": "[Ticket.Portfolio]",
            "datatype": "text",
            "title": "Portfolio",
            "filter": { "members": ["Artificial Intelligence"] }
          }
        }
        break;
      default:
        break;
    }
    this.setState({ filterPortfolio })
    this.loadDash("dbModal", dashId, null);
  }

  setTab = (value) => {
    let eventcode = "";
      switch(value){
        case 1:
          eventcode="SH-OP-1";
          this.props.callUserEnagementSessionAPI('Operations', "BPS App Tickets", 0, true)
          break;
        case 2:
          eventcode="SH-OP-2";
          this.props.callUserEnagementSessionAPI('Operations', "CSS Dashboard", 0, true)
          break;
        case 3:
          eventcode="SH-OP-3";
          this.props.callUserEnagementSessionAPI('Operations', "Other Tickets", 0, true)
          break;
      }
          
    this.setState({ activeTab: value });
  }


  onTimeFrameChange(e, data) {
    this.setState({ timeFrameDetails: data, reloadDash: false });
  }

  ontimeDimChange(e, data) {
    var val = data.data;
    this.setState({ timeframe: val, timeFrameDetails: data.data[0], timeDim: data.data[0].label })
  }

  onCssApply() {
    let clients;
    if (this.props.app.filterObject.tenant.length > 0) {
      clients = [...this.props.app.filterObject.tenant];
    } else {
      let tenants = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
      });
      clients = [...tenants];
    }
    let clientsFilterObj;
    if (clients.length > 0) {
      let parentTenantPseudo = clients;
      if (this.props.app.demoClient && this.props.app.clientOptions.length == 1) {
        parentTenantPseudo = [this.props.app.clientOptions[0].pseudoName];
      } else if (this.props.app.demoClient) {
        parentTenantPseudo = [(this.props.app.clientOptions.find(item => item.isDemo == true)).pseudoName];
      }
      clientsFilterObj = {
        explicit: true,
        multiSelection: true,
        members: parentTenantPseudo.map((a) => a.toUpperCase()),
      }
    } else {
      clientsFilterObj = {
        all: true,
      }
    }
    const { timeFrameDetails } = this.state;
    let pay = this.state.cssPayload;
    pay = [
      {
        levels: [{
          dim: "[Client.Type]",
          datatype: "text",
          title: "Type",
          filter: {
            members: ["Pseudo"],
          }
        }, {
          dim: "[Client.Client]",
          datatype: "text",
          title: "Client",
          filter: clientsFilterObj
        }
        ]
      },
      {
        jaql: {
          dim: "[Ticket.Opened At (Calendar)]",
          datatype: "datetime",
          title: "Months in Opened At",
          level: timeFrameDetails.level,
          filter: {
            [timeFrameDetails.interval]: timeFrameDetails.value
          }
        }
      }]
    this.setState({ cssPayload: pay, reloadDash: true });
  }

  onCssReset() {
    let clients;
    if (this.props.app.filterObject.tenant.length > 0) {
      clients = [...this.props.app.filterObject.tenant];
    } else {
      let tenants = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
      });
      clients = [...tenants];
    }
    let clientsFilterObj;
    if (clients.length > 0) {
      let parentTenantPseudo = clients;
      if (this.props.app.demoClient && this.props.app.clientOptions.length == 1) {
        parentTenantPseudo = [this.props.app.clientOptions[0].pseudoName];
      } else if (this.props.app.demoClient) {
        parentTenantPseudo = [(this.props.app.clientOptions.find(item => item.isDemo == true)).pseudoName];
      }
      clientsFilterObj = {
        explicit: true,
        multiSelection: true,
        members: parentTenantPseudo.map((a) => a.toUpperCase()),
      }
    } else {
      clientsFilterObj = {
        all: true,
      }
    }
    var pay = this.state.cssPayload;
    pay = [
      {
        levels: [{
          dim: "[Client.Type]",
          datatype: "text",
          title: "Type",
          filter: {
            members: ["Pseudo"],
          }
        }, {
          dim: "[Client.Client]",
          datatype: "text",
          title: "Client",
          filter: clientsFilterObj
        }
        ]
      },
      {
        jaql: {
          dim: "[Ticket.Opened At (Calendar)]",
          datatype: "datetime",
          title: "Months in Opened At",
          level: "days",
          filter: {
            last: {
              count: 180,
              offset: 0
            }
          }
        }
      }]
    this.setState({
      cssPayload: pay, reloadDash: true, timeVal: "Days", timeFrameDetails: monthInOpened[2].data[5],
      timeDim: "Last 180 Days", reloadDash: true, timeframe: monthInOpened[2].data,
      activeOps: "opsA"
    });
  }

  // onCssClick() {
  //     const { timeFrameDetails } = this.state;
  //     const clientType = this.props.userData.curTenantDetails.isDemo ? ["Pseudo"] : ["Client"];
  //     const membersType = this.props.userData.curTenantDetails.isDemo ? [this.props.userData.pseudoClientName] :
  //         [this.props.userData.currentClient];
  //     var payload = [
  //         {
  //             levels: [{
  //                 dim: "[Client.Type]",
  //                 datatype: "text",
  //                 title: "Type",
  //                 filter: {
  //                     members: clientType
  //                 }
  //             }, {
  //                 dim: "[Client.Client]",
  //                 datatype: "text",
  //                 title: "Client",
  //                 filter: {
  //                     members: membersType
  //                 }
  //             }
  //             ]
  //         },
  //         {
  //             jaql: {
  //                 dim: "[Ticket.Opened At (Calendar)]",
  //                 datatype: "datetime",
  //                 title: "Months in Opened At",
  //                 level: timeFrameDetails.level,
  //                 filter: {
  //                     [timeFrameDetails.interval]: timeFrameDetails.value
  //                 }
  //             }
  //         }
  //     ]
  //     this.setState({ cssPayload: payload })
  // }

  render() {

    const { timeframe, reloadDash } = this.state;
    const handleClose = () => this.setState({ showDashModal: false });

    let showModal = () => {
      return (
        <Modal show={this.state.showDashModal} centered onHide={handleClose} dialogClassName="modal-90w">
          <ModalHeader style={{ borderBottom: "none" }} closeButton={true} />
          <Modal.Body><div id="dbModal" className="db-container"></div>
          </Modal.Body>
        </Modal>
      );
    }
    const options = {
      height: 200,
      width: 450,
      hideTabs: false,
      // All other vizCreate options are supported here, too
      // They are listed here: https://onlinehelp.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#ref_head_9
    };
    return (
      // this.props.isConnected ?
      <div data-test='operations-component'>
        <div className={this.state.showLoader ? "loading" : "hidden"}>
          <img
            className="loader-size"
            src={require("../../Images/WO-background.gif")}
            alt=""
          />
        </div>

        <section className=''>
          <div className='container-fluid px-4 tab-pane' >
            <div className="new-subTablist row" data-test='appStoreContainer'>
              <div className="col-md-10">
                <nav id="appTabs" className="">
                  <div className="row " >
                    <Tabs
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      style={{ cursor: "pointer" }}
                    >
                      <div className='d-inline-block'>
                        <div className="media">
                          <div
                            className={
                              this.state.activeTab == 1
                                ? "media-body newTab-text  newTab-text-active"
                                : "media-body newTab-text"
                            }
                            onClick={() => this.setTab(1)}
                          >
                            {i18n.t("BPS App Tickets")}
                          </div>
                        </div>
                      </div>

                      <div className='d-inline-block'>
                        <div className="media">
                          <div
                            className={
                              this.state.activeTab == 2
                                ? "media-body newTab-text  newTab-text-active"
                                : "media-body newTab-text"
                            }
                            onClick={() => this.setTab(2)}
                          >
                            {i18n.t("CSS Dashboard")}
                          </div>
                        </div>
                      </div>

                      {/*<div className='d-inline-block'>
                        <div className="media">
                          <div
                            className={
                              this.state.activeTab == 3
                                ? "media-body newTab-text  newTab-text-active"
                                : "media-body newTab-text"
                            }
                            onClick={() => this.setTab(3)}
                          >
                            {i18n.t("Other Tickets")}
                          </div>
                        </div>
                      </div>*/}

                    </Tabs>
                  </div>
                </nav>
              </div>
            </div>
            <div className='app-frame-container' style={{ padding: "0", display: this.state.activeTab == 1 ? "block" : "none" }}>
              <div className="container-fluid data-container2 operation-main-container">
                <div className="row inner-date2 text-white" style={{ overflowY: "hidden" }}>
                  <div className="container-fluid">
                    <div className="row">
                      {this.props.isConnected ?
                      <div className="data-sections2" style={{ width: '96%', padding: '0px' }}>
                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            {/* <div className="row" style={{ background: 'rgb(0, 0, 68)', borderRadius: '8px', marginBottom: '5px', marginLeft: '0px', marginRight: '0px' }} data-test='announcement-container'>
                                        <h5 className="dash-title col-2" style={{ position: 'relative', top: '2vh', paddingLeft: '20px' }}>
                                          {i18n.t((this.props.config.find(element => element.position == 1)).displayName)}
                                        </h5>
                                        <div id="opDashboard1" className="announcements-height col-9"></div>

                                      </div> */}
                            <div style={{ background: 'rgb(2, 66, 198)', borderRadius: '4px', marginBottom: '8px' }} data-test='open-tickets-container'>
                              <h5 className="dash-title" style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                                {i18n.t((this.props.config.find(element => element.position == 2)).displayName)}
                              </h5>

                              <div id="opDashboard2" className="live-notifications-height">
                              <Row>
                                <div id="5fabb6c95fd2f53f3861d49f" style={{width: "calc(50% - 4px)",margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "10px"}}></div>
                                <div id="5fabc1124be9354e24e573a9" style={{width: "calc(50% - 4px)",margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "10px"}}></div>
                              </Row>
                              <Row>
                                <div id="5fabb3655fd2f53f3861d47b" style={{width: "calc(50% - 4px)",margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "10px"}}></div>
                                <div id="5fabb4635fd2f53f3861d491" style={{width: "calc(50% - 4px)",margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "10px"}}></div>
                              </Row>
                                {/* {this.props.sisenceConnectionError &&(<h5 style={{textAlign:"center"}}>{i18n.t("There was an issue initializing Sisense dashboards. Please contact SynOpsHub support.")}</h5>)} */}
                              </div>

                            </div>
                            <div >
                              <h5 className="dash-title">
                                {i18n.t((this.props.config.find(element => element.position == 3)).displayName)}
                              </h5>

                              <div
                                id="opDashboard3"
                                className="operations-height"
                                style={{overflowX:"hidden"}}
                              >
                                <Row>
                                <div id="5f7d7214e841931bc03dee85" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent"}}></div>
                                <div id="5f7d7214e841931bc03dee82" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent"}}></div>
                                <div id="5f7d7214e841931bc03dee87" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent"}}></div>
                                <div id="5f7d7214e841931bc03dee81" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent"}}></div>
                                <div id="5f7d7214e841931bc03dee88" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent"}}></div>
                              </Row>
                              <Row>
                                <div id="615aefc2f9cae9003651f853" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent", height: "308px"}}></div>
                                <div id="6165901896ef650035143106" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "30px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent", height: "308px"}}></div>
                                <div id="6165904796ef65003514310a" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "30px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent", height: "308px"}}></div>
                                <div id="6165907a96ef650035143118" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent", height: "308px"}}></div>
                                <div id="616590a296ef650035143120" style={{width: "calc(20% - 4px)", margin: "2px", paddingLeft: "25px", paddingRight: "25px", borderRadius: "9px", backgroundColor:"transparent", height: "308px"}}></div>
                              </Row>
                              <Row style={{float:"right"}}>
                                <div id="5f7d7214e841931bc03dee8b" style={{margin: "2px", borderRadius: "9px"}}></div>
                              </Row>
                              </div>


                            </div> </div>
                        </div>
                      </div>:
                      <div className='d-flex flex-column'>
                <img
                    src={Logo}

                    alt="logo"
                    className="rotate align-self-center"
                    style={{ width: "137px" , marginLeft: "34rem"}}
                  />
                  <div className="ml-16 align-self-center" style={{marginLeft: "34rem"}}>Connecting...</div>
                </div>
              }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* CSS dashboard */}
            <div className='app-frame-container' style={{ padding: "0", display: this.state.activeTab == 2 ? "block" : "none" }}>
              {this.props.isConnected ?
              <div className="container-fluid data-container2 operation-main-container">
                <div className="row inner-date2 text-white" style={{ overflowY: "hidden" }}>
                  <div className="container-fluid">
                    <div className="css-filter-buttons align-right mt-20 mb-20">
                      <div style={{ marginLeft: "5px" }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          default="Months"
                          value={this.state.timeVal}
                          onChange={(e) => this.setState({ timeVal: e.target.value })}
                        >
                          {
                            monthInOpened.map((data) => {
                              return <MenuItem value={data.name}
                                onClick={(e) => this.ontimeDimChange(e, data)}>{data.name}</MenuItem>
                            })
                          }
                        </Select>
                      </div>
                      <div style={{ marginLeft: "5px" }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue="This Month"
                          value={this.state.timeDim}
                          onChange={(e) => this.setState({ timeDim: e.target.value })}
                        >
                          {
                            timeframe.length > 0 && timeframe.map((data) => {
                              return <MenuItem value={data.label}
                                onClick={(e) => this.onTimeFrameChange(e, data)}>{data.label}</MenuItem>
                            })
                          }
                        </Select>
                      </div>
                      <div style={{ marginLeft: "5px" }}>
                        <button style={{ cursor: "pointer", borderStyle: "none" }} className="hmcc-drop-btn applyBtn" onClick={() => this.onCssApply()}>{i18n.t("Apply")}</button>
                      </div>
                      <div >
                        <button style={{ cursor: "pointer", borderStyle: "none" }} className="hmcc-drop-btn applyBtn" onClick={() => this.onCssReset()} >{i18n.t("Reset")}</button>
                      </div>

                    </div>
                    <CssDashboard reloadDash={reloadDash} widgetReady={() => this.setState({ reloadDash: false })} operations={this.props} currentApp={this.props.currentApp} payload={this.state.cssPayload} />
                  </div>
                </div>
              </div> :
              <div className='d-flex flex-column'>
                  <img
                    src={Logo}

                    alt="logo"
                    className="rotate align-self-center"
                    style={{ width: "137px" }}
                  />
                  <div className="ml-10 align-self-center">Connecting...</div>
                </div>
              }
            </div>
            {/* Other tickets */}
            <div className='app-frame-container' style={{ padding: "0", display: this.state.activeTab == 3 ? "block" : "none" }}>
              {this.props.isConnected ?
                <div className="container-fluid data-container2 operation-main-container">
                  <div className="row inner-date2 text-white" style={{ overflowY: "hidden" }}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="data-sections2" style={{ width: '96%', padding: '0px' }}>
                          <div className="row">
                            <div className="col-md-12 col-lg-12">


                              <div style={{ background: 'rgb(2, 66, 198)', borderRadius: '4px', marginBottom: '8px' }} data-test='open-tickets-container'>
                                <h5 className="dash-title" style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                                  {i18n.t((this.props.config.find(element => element.position == 2)).displayName)}
                                </h5>
                                <OtherTickets payload={this.state.filterArrayOtherTickets[0]} showAll={true} currentApp={this.props.currentApp} />
                              </div>

                              <div>
                                <h5 className="dash-title">
                                  {i18n.t((this.props.config.find(element => element.position == 3)).displayName)}
                                </h5>
                                <OtherDash payload={this.state.filterArrayOtherTickets[0]} userDetails={this.props.userDetails} showAll={true} currentApp={this.props.currentApp} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className='d-flex flex-column'>
                  <img
                    src={Logo}

                    alt="logo"
                    className="rotate align-self-center"
                    style={{ width: "137px" }}
                  />
                  <div className="ml-10 align-self-center">Connecting...</div>
                </div>
              }
            </div>
          </div>
        </section>
        {showModal()}
      </div>
      //  : <Connecting />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.appReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenuDisplay: (boolean) => {
      dispatch(toggleMenuDisplay(boolean));
    },
    setFilters: (obj) => {
      dispatch(setFilters(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Operations);
