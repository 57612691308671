import React from "react";
import { connect } from "react-redux";
import {
  toggleMenuDisplay,
  setFilters,
  setClients,
  setClientOptions,
} from "../../actions/AppActions";
import { BASE_URL, ADMIN } from "../../StringConstants";
import * as api from "../../services/apiAction";
import AuthContext from "../../services/Auth";
import { Hidden } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import DateTimeClock from "../HumanAndMachine/DateTimeClock";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import map from "../../services/react-simple-map.json";
import i18n from "i18next";
import ReactTooltip from "react-tooltip";
import { Modal, Button, Table, Dropdown } from "react-bootstrap";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import paginationFactory from "react-bootstrap-table2-paginator";
import ClientFilterB9 from "./ClientFilterB9";
import BootstrapTable from "react-bootstrap-table-next";
/*changes*/
import hmccClientImage from "../../Images/hmcc-default-client.svg";

const rounded = (num) => {
  if (num > 1000000000) {
    return Math.round(num / 100000000) / 10 + "Bn";
  } else if (num > 1000000) {
    return Math.round(num / 100000) / 10 + "M";
  } else {
    return Math.round(num / 100) / 10 + "K";
  }
};
const geoUrl = map;
const override = `
position: absolute;
top: 40%;
left: 50%;
z-index: 1000;
`;
//define months array
let months = new Array();
months[0] = "January";
months[1] = "February";
months[2] = "March";
months[3] = "April";
months[4] = "May";
months[5] = "June";
months[6] = "July";
months[7] = "August";
months[8] = "September";
months[9] = "October";
months[10] = "November";
months[11] = "December";

class MapB9 extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      teamId: "",
      clientsServed: {
        overall: "",
        aapac: "",
        latinAmerica: "",
        northAmerica: "",
        europe: "",
        other: "",
      },
      markers: [],
      showHuman: true,
      showMachine: true,
      humans: {},
      machines: {},
      rawHumanCountObj: {}, //save human count for later use on Human and machine button switch
      rawMachineCountObj: {}, //save machine count for later use on Human and machine button switch

      offeringDropDown: [],
      marketDropDown: [],
      deliveryLocationDropDown: [],
      assetTypeDropDown: [],
      tenantDropDown: [],
      deliveryRegions: {
        HumanMachine: {},
        China: {},
        Europe: {},
        India: {},
        LatinAmerica: {},
        NorthAmerica: {},
        OtherRegions: {},
        Philippines: {},
      },
      filterObject: {
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: [],
        selectedClient: {},
      },

      tempFilterObject: {
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: [],
      },
      tempFilterObject1: {
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: [],
      },
      mapdatacountb9: [],
      pageOfItems: [],
      openedDropdown: "",
      time: new Date(),
      filteredList: [],
      unfilteredList: [],
      assetTypeFilteredList: [],
      assetTypeUnfilteredList: [],
      deliveryLocationfilteredList: [],
      deliveryLocationUnfilteredList: [],
      marketFilteredList: [],
      marketUnfilteredList: [],
      offeringFilteredList: [],
      offeringUnfilteredList: [],
      hideClientDiv: true,
      hideAssetTypeDiv: true,
      hideDeliveryLocDiv: true,
      hideMarketDiv: true,
      hideOfferingDiv: true,
      selectedClients: [],
      hideFilterHolderDiv: true,
      selectedAssetsTypesLabel: [],
      tempSelectedAssetsTypesLabel: [],
      content: "",
      hover: false,
      showPopUpMap: false,
      deliveryLocationDropDownb9: [],
      mapdatab9regiondetails: {},
      selectedCountry: {},
      dropdownname: "",
      indiaMapVisible: "hidden",
      PhilippinesMapVisible: "hidden",
      LatinAmericaMapVisible: "hidden",
      EuropeMapVisible: "hidden",
      OtherRegionsVisible: "hidden",
      NorthAmericaVisible: "hidden",
      ChinaMapVisible: "hidden",
      bannerHidden: false,
      isListOpen: false
    };
  }

  hideModal = () => {
    this.setState({
      showPopUpMap: false,
      mapdatacountb9: [],
      mapdatab9regiondetails: [],
      selectedCountry: {},
      isListOpen: false
    });
  };

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  };
  newWindow() {
    var height = 1;
    var width = 1;
    var t = window.innerHeight - height;
    var l = window.innerWidth - width;
    var win = window.open(
      "https://portal.accenture.com",
      "",
      "height=" +
      height +
      ", width=" +
      width +
      ", left=" +
      l +
      ", top=" +
      t +
      "location=no,status=no,menubar=no,toolbar=no,resizable=no,scrollbars=no"
    );
    if (win == null || typeof win == "undefined") {
      this.setState({ currentDashboardUrl: "", showModal: true });
    } else {
      this.closeWindow(win);
    }
  }

  closeWindow(win) {
    setTimeout(function () {
      win.close();
    }, 55000);
  }
  componentDidMount() {
    window.addEventListener("click", this.handleDropdownClick);
    // let userName = AuthContext.getCachedUser().userName;
    // let enterpriseId = userName.split("@")[0];
    let enterpriseId = this.props.userDetails.userId;
    //Get tenants data
        
        api.get(`tenantlist`).then((res) => {
            let tenant = [];
            this.props.setClientOptions(res)
             if(res.length>0){
                this.getFilters("");
            }
            
            res.map((item) => {
                tenant.push({ label: item.tenantName, value: item.pseudoName });
            });
            this.setState({
                tenantDropDown: tenant,
            });
        });

    //Get ClientServerd data
    api.get(`clients`).then((res) => {
      let clientsServed = {
        overall: "",
        growthMarkets: "",
        northAmerica: "",
        europe: "",
        other: "",
      };
      res.map((item) => {
        switch (item.region) {
          case "OverAll":
            {
              clientsServed.overall = item.clients;
            }
            break;
          case "Growth Markets":
            {
              clientsServed.growthMarkets = item.clients;
            }
            break;
          case "NA":
            {
              clientsServed.northAmerica = item.clients;
            }
            break;
          case "Europe":
            {
              clientsServed.europe = item.clients;
            }
            break;
          case "Other":
            {
              clientsServed.other = item.clients;
            }
            break;
          default:
            break;
        }
      });
      this.setState({ clientsServed });
    });

    //Get co-ordinates
    api.get(`regions`).then((res) => {
      let deliveryRegions = {
        HumanMachine: {},
        China: {},
        Europe: {},
        India: {},
        LatinAmerica: {},
        NorthAmerica: {},
        OtherRegions: {},
        Philippines: {},
      };
      res.map((item) => {
        switch (item.regionName) {
          case "Human+Machine":
            {
              deliveryRegions.HumanMachine = {
                longitude: item.longitude,
                latitude: item.latitude,
                timeZone: item.timeZone,
              };
            }
            break;
          case "China/Mainland":
            {
              deliveryRegions.China = {
                longitude: item.longitude,
                latitude: item.latitude,
                timeZone: item.timeZone,
              };
            }
            break;
          case "Europe":
            {
              deliveryRegions.Europe = {
                longitude: item.longitude,
                latitude: item.latitude,
                timeZone: item.timeZone,
              };
            }
            break;
          case "India":
            {
              deliveryRegions.India = {
                longitude: item.longitude,
                latitude: item.latitude,
                timeZone: item.timeZone,
              };
            }
            break;
          case "Latin America":
            {
              deliveryRegions.LatinAmerica = {
                longitude: item.longitude,
                latitude: item.latitude,
                timeZone: item.timeZone,
              };
            }
            break;
          case "North America":
            {
              deliveryRegions.NorthAmerica = {
                longitude: item.longitude,
                latitude: item.latitude,
                timeZone: item.timeZone,
              };
            }
            break;
          case "Other Regions":
            {
              deliveryRegions.OtherRegions = {
                longitude: item.longitude,
                latitude: item.latitude,
                timeZone: item.timeZone,
              };
            }
            break;
          case "Philippines":
            {
              deliveryRegions.Philippines = {
                longitude: item.longitude,
                latitude: item.latitude,
                timeZone: item.timeZone,
              };
            }
            break;
          default:
            break;
        }
      });
      this.setState({ deliveryRegions }, () => {
        this.getHumanMachineCounts(this.props.app.filterObject, this.props);
      });
    });
  }
  getFilters = (tenantID)=>{
         //Get Filters data
         api.get(`filters/${tenantID?tenantID:0}`).then((res) => {
            let offering = [];
            let market = [];
            let deliveryLocation = [];
            let assetType = [];
            // let tenant = [];
            // this.props.setClientOptions(res.tenant)
            //  if(res.tenant.length>0){
            //     this.props.onSelectClient(res.tenant[0]);
            // }
            res.offering.map((item) => {
                offering.push({ label: item, value: item });
            });
            res.market.map((item) => {
                market.push({ label: item, value: item });
            });
            res.deliveryLocation.map((item) => {
                deliveryLocation.push({ label: item, value: item });
            });
            res.assetType.map((item) => {
                assetType.push({ label: item.assetType, value: item.id });
            });
            // res.tenant.map((item) => {
            //     tenant.push({ label: item.tenantName, value: item.pseudoName });
            // });
            this.setState({
                offeringDropDown: offering,
                marketDropDown: market,
                deliveryLocationDropDown: deliveryLocation,
                assetTypeDropDown: assetType,
                // tenantDropDown: tenant,
            });
        });
    }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.app.filterObject.tenant !== nextProps.app.filterObject.tenant
    ) {
      // this.getHumanMachineCounts(nextProps.app.filterObject, nextProps);
      if(nextProps.app.filterObject.tenantId.length>0){
      this.getFilters(nextProps.app.filterObject.tenantId);
      }else{
      this.getFilters("");
      }
      if(nextProps.app.clients.length>0){
        this.setState({bannerHidden:true});
      }
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener("click", this.handleDropdownClick);
  }
  setFilters = () => {
    this.props.setFilters(this.props.app.filterObject);
  };
  handleDropdownClick = (event) => {
    if (
      document.getElementById("offering-dropdown").contains(event.target) ||
      document.getElementById("market-dropdown").contains(event.target) ||
      document.getElementById("dropdown3").contains(event.target) ||
      document.getElementById("dropdown4").contains(event.target) ||
      document.getElementById("dropdown5").contains(event.target)
    ) {
      // Clicked in box
    } else {
      // Clicked outside the box
      this.setState({ openedDropdown: "" });
    }
  };

  toggleList = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isListOpen: !prevState.isListOpen,
    }));
  };

  selectCountry = (e, country) => {
    this.toggleList(e);
    this.setState({ selectedCountry: country });

    this.getMapPopUpData(this.state.tempFilterObject1, country.regionName);

    this.handleGetCurrentPage();
  };
  handleConfidentialPopup = ()=>{
    let allowConfidentialInfo= this.props.userDetails.id;
    this.props.confidentialPopUp(false,allowConfidentialInfo);
}

  getImageSrc = () => {
    var appIcon = this.state.mapdatab9regiondetails.appIcon;
    return "data:image/png+xml;base64, " + appIcon;
  };

  getMapPopUpData = (data, region) => {
    let enterpriseId = this.props.userDetails.userId;
    api
      .post(`hmcountdetails`, {
        mapPopUpCount: [],
        offering: data.offering,
        market: data.market,
        deliveryLocation: data.deliveryLocation,
        assetCategory: data.assetType.map(String),
        pseudoName: data.tenant,
        region: region,
      })

      .then((res) => {
        this.setState({
          mapdatacountb9: res.hmCountDetails,
          mapdatab9regiondetails: res.regionDetails,
        });
      });
  };

  getMapDropDownData = (region) => {
    let enterpriseId = this.props.userDetails.userId;
    api
      .get(`regions`)
      .then((res) => {
        let deliveryLocations = res.filter(
          (item) => item.regionName !== "Human+Machine"
        );
        this.setState({
          deliveryLocationDropDownb9: deliveryLocations,
          selectedCountry: res.filter(
            (item) => item.regionName === region
          )[0],
        });
      });

  };

  getHumanMachineCounts = (data, props) => {
    this.setState({ loading: true });
    // let userName = AuthContext.getCachedUser().userName;
    // let enterpriseId = userName.split("@")[0];
    let enterpriseId = this.props.userDetails.userId;
    //Get Human machine count data
    api
      .post(`hmcounts`, {
        offering: data.offering,
        market: data.market,
        deliveryLocation: data.deliveryLocation,
        assetCategory: data.assetType.map(String),
        pseudoName: props.app.demoClient?[props.app.parentTenantID+""]:(data.tenantId.length>0?[data.tenantId[0]+""]:data.tenantId),
      })
      .then((res) => {
        let humans = {
          "Human+Machine": 0,
          "China/Mainland": 0,
          Europe: 0,
          India: 0,
          "Latin America": 0,
          "North America": 0,
          "Other Regions": 0,
          Philippines: 0,
        };
        let machines = {
          "Human+Machine": 0,
          "China/Mainland": 0,
          Europe: 0,
          India: 0,
          "Latin America": 0,
          "North America": 0,
          "Other Regions": 0,
          Philippines: 0,
        };
        let emptyObj = {
          "Human+Machine": 0,
          "China/Mainland": 0,
          Europe: 0,
          India: 0,
          "Latin America": 0,
          "North America": 0,
          "Other Regions": 0,
          Philippines: 0,
        };
        res.map((item) => {
          switch (item.deliveryRegion) {
            case "Human+Machine":
              {
                //Get total human and machine count for this region
                humans["Human+Machine"] = item.human;
                machines["Human+Machine"] = item.machine;
              }
              break;
            case "China/Mainland":
              {
                //Get total human and machine count for this region
                humans["China/Mainland"] = item.human;
                machines["China/Mainland"] = item.machine;
              }
              break;
            case "Europe":
              {
                //Get total human and machine count for this region
                humans["Europe"] = item.human;
                machines["Europe"] = item.machine;
              }
              break;
            case "India":
              {
                //Get total human and machine count for this region
                humans["India"] = item.human;
                machines["India"] = item.machine;
              }
              break;
            case "Latin America":
              {
                //Get total human and machine count for this region
                humans["Latin America"] = item.human;
                machines["Latin America"] = item.machine;
              }
              break;
            case "North America":
              {
                //Get total human and machine count for this region
                humans["North America"] = item.human;
                machines["North America"] = item.machine;
              }
              break;
            case "Other Regions":
              {
                //Get total human and machine count for this region
                humans["Other Regions"] = item.human;
                machines["Other Regions"] = item.machine;
              }
              break;
            case "Philippines":
              {
                //Get total human and machine count for this region
                humans["Philippines"] = item.human;
                machines["Philippines"] = item.machine;
              }
              break;
            default:
              break;
          }
        });
        this.setState(
          {
            rawHumanCountObj: humans,
            rawMachineCountObj: machines,
            loading: false,
          },
          () => {
            //Check if both buttons are enabled
            if (this.state.showHuman && this.state.showMachine) {
              this.setState({ humans, machines }, () => this.setMarkers());

              // Check only human button is enabled
            } else if (this.state.showHuman) {
              this.setState({ humans, machines: emptyObj }, () =>
                this.setMarkers()
              );

              //check only machine button is enabled
            } else if (this.state.showMachine) {
              this.setState({ humans: emptyObj, machines }, () =>
                this.setMarkers()
              );

              //Check if both the buttons disabled
            } else {
              this.setState({ humans: emptyObj, machines: emptyObj }, () =>
                this.setMarkers()
              );
            }
          }
        );
      });
  };

  mouseOver = (item) => {
    switch (item.latLng[0]) {
      case 77.35026:
        this.setState({
          EuropeMapVisible: "hidden",
          PhilippinesMapVisible: "hidden",
          NorthAmericaVisible: "hidden",
          OtherRegionsVisible: "hidden",
          ChinaMapVisible: "hidden",
          LatinAmericaMapVisible: "hidden",
          indiaMapVisible: "visible",
        });
        break;
      case 15.2551:
        this.setState({
          indiaMapVisible: "hidden",
          PhilippinesMapVisible: "hidden",
          NorthAmericaVisible: "hidden",
          OtherRegionsVisible: "hidden",
          ChinaMapVisible: "hidden",
          LatinAmericaMapVisible: "hidden",
          EuropeMapVisible: "visible",
        });
        break;
      case 125.323667:
        this.setState({
          indiaMapVisible: "hidden",
          EuropeMapVisible: "hidden",
          NorthAmericaVisible: "hidden",
          OtherRegionsVisible: "hidden",
          ChinaMapVisible: "hidden",
          LatinAmericaMapVisible: "hidden",
          PhilippinesMapVisible: "visible",
        });
        break;
      case -100.166666:
        this.setState({
          PhilippinesMapVisible: "hidden",
          indiaMapVisible: "hidden",
          EuropeMapVisible: "hidden",
          OtherRegionsVisible: "hidden",
          ChinaMapVisible: "hidden",
          LatinAmericaMapVisible: "hidden",
          NorthAmericaVisible: "visible",
        });
        break;
      case 67.759113:
        this.setState({
          NorthAmericaVisible: "hidden",
          PhilippinesMapVisible: "hidden",
          indiaMapVisible: "hidden",
          EuropeMapVisible: "hidden",
          ChinaMapVisible: "hidden",
          LatinAmericaMapVisible: "hidden",
          OtherRegionsVisible: "visible",
        });
        break;
      case 112.990463:
        this.setState({
          OtherRegionsVisible: "hidden",
          NorthAmericaVisible: "hidden",
          PhilippinesMapVisible: "hidden",
          indiaMapVisible: "hidden",
          EuropeMapVisible: "hidden",
          LatinAmericaMapVisible: "hidden",
          ChinaMapVisible: "visible",
        });
        break;
      case -56.053494:
        this.setState({
          ChinaMapVisible: "hidden",
          OtherRegionsVisible: "hidden",
          NorthAmericaVisible: "hidden",
          PhilippinesMapVisible: "hidden",
          indiaMapVisible: "hidden",
          EuropeMapVisible: "hidden",
          LatinAmericaMapVisible: "visible",
        });
        break;
      default:
        break;
    }
  };

  mouseOut = () => {
    this.setState({
      EuropeMapVisible: "hidden",
      PhilippinesMapVisible: "hidden",
      NorthAmericaVisible: "hidden",
      OtherRegionsVisible: "hidden",
      ChinaMapVisible: "hidden",
      LatinAmericaMapVisible: "hidden",
      indiaMapVisible: "hidden",
      regionName: "",
    });
  };

  markerclick = (region) => {
    if (region.latLng[0] === 77.35026) {
      this.setState(
        {
          region: "India",
        },
        () => {
          this.getMapPopUpData(this.state.tempFilterObject1, "India");
          this.getMapDropDownData("India");
        }
      );
    } else if (region.latLng[0] === 15.2551) {
      this.setState(
        {
          region: "Europe",
        },
        () => {
          this.getMapPopUpData(this.state.tempFilterObject1, "Europe");
          this.getMapDropDownData("Europe");
        }
      );
    } else if (region.latLng[0] === 125.323667) {
      this.setState(
        {
          region: "Philippines",
        },
        () => {
          this.getMapPopUpData(this.state.tempFilterObject1, "Philippines");
          this.getMapDropDownData("Philippines");
        }
      );
    } else if (region.latLng[0] === -100.166666) {
      this.setState(
        {
          region: "North America",
        },
        () => {
          this.getMapPopUpData(this.state.tempFilterObject1, "North America");
          this.getMapDropDownData("North America");
        }
      );
    } else if (region.latLng[0] === 67.759113) {
      this.setState(
        {
          region: "Other Regions",
        },
        () => {
          this.getMapPopUpData(this.state.tempFilterObject1, "Other Regions");
          this.getMapDropDownData("Other Regions");
        }
      );
    } else if (region.latLng[0] === 112.990463) {
      this.setState(
        {
          region: "China/Mainland",
        },
        () => {
          this.getMapPopUpData(this.state.tempFilterObject1, "China/Mainland");
          this.getMapDropDownData("China/Mainland");
        }
      );
    } else if (region.latLng[0] === -56.053494) {
      this.setState(
        {
          region: "Latin America",
        },
        () => {
          this.getMapPopUpData(this.state.tempFilterObject1, "Latin America");
          this.getMapDropDownData("Latin America");
        }
      );
    }
    this.setState({
      showPopUpMap: !this.state.showPopUpMap,
    });
  };

  handleHumanMachineCount = (type) => {
    if (!this.state.loading) {
      let humans = {
        "Human+Machine": 0,
        "China/Mainland": 0,
        Europe: 0,
        India: 0,
        "Latin America": 0,
        "North America": 0,
        "Other Regions": 0,
        Philippines: 0,
      };
      let machines = {
        "Human+Machine": 0,
        "China/Mainland": 0,
        Europe: 0,
        India: 0,
        "Latin America": 0,
        "North America": 0,
        "Other Regions": 0,
        Philippines: 0,
      };
      switch (type) {
        case "human":
          {
            //check previous state of human button and toggle the value
            if (this.state.showHuman) {
              this.setState({ humans, showHuman: false }, () =>
                this.setMarkers()
              );
            } else {
              this.setState(
                { humans: this.state.rawHumanCountObj, showHuman: true },
                () => this.setMarkers()
              );
            }
          }
          break;
        case "machine":
          {
            //check previous state of machine button and toggle the value
            if (this.state.showMachine) {
              this.setState({ machines, showMachine: false }, () =>
                this.setMarkers()
              );
            } else {
              this.setState(
                { machines: this.state.rawMachineCountObj, showMachine: true },
                () => this.setMarkers()
              );
            }
          }
          break;
        default:
          break;
      }
    }
  };
  setMarkers = () => {
    let markers = [];
    for (let [key, value] of Object.entries(this.state.humans)) {
      //exclude Human+Machine
      if (key !== "Human+Machine") {
        let node = {};
        let name = key;
        if (this.state.showHuman) name = name;
        if (this.state.showMachine) name = name;
        node["name"] = name;
        node["HumanCount"] = value;
        node["MachineCount"] = this.state.machines[key];
        node["latLng"] = this.getCoords(key);
        node["latLng"] = node["latLng"].reverse();
        markers.push(node);
      }
    }
    this.setState({ markers });
  };

  getCoords = (Cont) => {
    var coords = [0, 0];
    switch (Cont) {
      case "China/Mainland":
        coords = [
          this.state.deliveryRegions.China.longitude,
          this.state.deliveryRegions.China.latitude,
        ];
        break;
      case "Europe":
        coords = [
          this.state.deliveryRegions.Europe.longitude,
          this.state.deliveryRegions.Europe.latitude,
        ];
        break;
      case "India":
        coords = [
          this.state.deliveryRegions.India.longitude,
          this.state.deliveryRegions.India.latitude,
        ];
        break;
      case "Latin America":
        coords = [
          this.state.deliveryRegions.LatinAmerica.longitude,
          this.state.deliveryRegions.LatinAmerica.latitude,
        ];
        break;
      case "North America":
        coords = [
          this.state.deliveryRegions.NorthAmerica.longitude,
          this.state.deliveryRegions.NorthAmerica.latitude,
        ];
        break;
      case "Other Regions":
        coords = [
          this.state.deliveryRegions.OtherRegions.longitude,
          this.state.deliveryRegions.OtherRegions.latitude,
        ];
        break;
      case "Philippines":
        coords = [
          this.state.deliveryRegions.Philippines.longitude,
          this.state.deliveryRegions.Philippines.latitude,
        ];
        break;
    }
    return coords;
  };

  toggleDropdown = (value) => {
    if (!this.state.loading) {
      if (this.state.openedDropdown == value) {
        this.setState({ openedDropdown: "" });
      } else {
        switch (value) {
          case "offering":
            {
              this.setState({
                openedDropdown: "offering",
                hideClientDiv: true,
                hideAssetTypeDiv: true,
                hideDeliveryLocDiv: true,
                hideMarketDiv: true,
                hideOfferingDiv: false,
                offeringFilteredList: this.state.offeringDropDown,
              });
            }
            break;
          case "market":
            {
              this.setState({
                openedDropdown: "market",
                hideClientDiv: true,
                hideAssetTypeDiv: true,
                hideDeliveryLocDiv: true,
                hideMarketDiv: false,
                hideOfferingDiv: true,
                marketFilteredList: this.state.marketDropDown,
              });
            }
            break;
          case "deliveryLocation":
            {
              this.setState({
                openedDropdown: "deliveryLocation",
                hideClientDiv: true,
                hideAssetTypeDiv: true,
                hideDeliveryLocDiv: false,
                hideMarketDiv: true,
                hideOfferingDiv: true,
                deliveryLocationfilteredList:
                  this.state.deliveryLocationDropDown,
              });
            }
            break;
          case "assetType":
            {
              this.setState({
                openedDropdown: "assetType",
                hideClientDiv: true,
                hideAssetTypeDiv: false,
                hideDeliveryLocDiv: true,
                hideMarketDiv: true,
                hideOfferingDiv: true,
                assetTypeFilteredList: this.state.assetTypeDropDown,
              });
            }
            break;
          case "tenant":
            {
              this.setState({
                openedDropdown: "tenant",
                hideClientDiv: false,
                hideAssetTypeDiv: true,
                hideDeliveryLocDiv: true,
                hideMarketDiv: true,
                hideOfferingDiv: true,
                filteredList: this.state.tenantDropDown,
              });
            }
            break;
          default:
            break;
        }
      }
    }
  };
  handleFilterSelect = (type, element) => {
    if (!this.state.loading) {
      let filterArray = [];
      let clientArray = [];
      switch (type) {
        case "offering":
          {
            filterArray = [...this.state.filterObject.offering];
            if (filterArray.some((item) => item == element.value)) {
              let index = filterArray.indexOf(element.value);
              if (index > -1) {
                filterArray.splice(index, 1);
              }
            } else {
              filterArray.push(element.value);
            }
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                offering: filterArray,
              },
            });
          }
          break;
        case "market":
          {
            filterArray = [...this.state.filterObject.market];
            if (filterArray.some((item) => item == element.value)) {
              let index = filterArray.indexOf(element.value);
              if (index > -1) {
                filterArray.splice(index, 1);
              }
            } else {
              filterArray.push(element.value);
            }
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                market: filterArray,
              },
            });
          }
          break;
        case "deliveryLocation":
          {
            filterArray = [...this.state.filterObject.deliveryLocation];
            if (filterArray.some((item) => item == element.value)) {
              let index = filterArray.indexOf(element.value);
              if (index > -1) {
                filterArray.splice(index, 1);
              }
            } else {
              filterArray.push(element.value);
            }
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                deliveryLocation: filterArray,
              },
            });
          }
          break;
        case "assetType":
          {
            filterArray = [...this.state.filterObject.assetType];
            let selectedAssetsTypesLabel = [
              ...this.state.selectedAssetsTypesLabel,
            ];
            if (filterArray.some((item) => item == element.value)) {
              let index = filterArray.indexOf(element.value);
              if (index > -1) {
                filterArray.splice(index, 1);
                selectedAssetsTypesLabel.splice(index, 1);
              }
            } else {
              filterArray.push(element.value);
              selectedAssetsTypesLabel.push(element);
            }
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                assetType: filterArray,
              },
              selectedAssetsTypesLabel: selectedAssetsTypesLabel,
            });
          }
          break;
        case "tenant":
          {
            filterArray = [...this.state.filterObject.tenant];
            if (filterArray.some((item) => item == element.value)) {
              let index = filterArray.indexOf(element.value);
              if (index > -1) {
                filterArray.splice(index, 1);
              }
            } else {
              filterArray.push(element.value);
            }

            //client list(Not pseudo)
            clientArray = [...this.state.selectedClients];
            if (clientArray.some((item) => item == element.label)) {
              let index = clientArray.indexOf(element.label);
              if (index > -1) {
                clientArray.splice(index, 1);
              }
            } else {
              clientArray.push(element.label);
            }

            this.setState({
              filterObject: {
                ...this.state.filterObject,
                tenant: filterArray,
              },
              selectedClients: clientArray,
            });
          }
          break;
        default:
          break;
      }
    }
  };
  applyMainFilters = () => {
    if (this.props.isLoggingEnabled) {
      let previousSelectedLocations = [
        ...this.state.tempFilterObject.deliveryLocation,
      ];
      let selectedLocations = [...this.state.filterObject.deliveryLocation];
      this.getChangedLocationFilters(
        previousSelectedLocations,
        selectedLocations
      );
    }
    let filterObject = {
      ...this.state.filterObject,
      tenant: this.props.app.filterObject.tenant,
      tenantId: this.props.app.filterObject.tenantId

    };
    this.props.setFilters(filterObject);
    this.getHumanMachineCounts(filterObject, this.props);
    this.setState({
      hideFilterHolderDiv: true,
      bannerHidden: this.props.app.clients.length > 0,
      tempFilterObject1: filterObject
    });
  };

  getChangedLocationFilters = (
    previousSelectedLocations,
    selectedLocations
  ) => {
    let difference = previousSelectedLocations
      .filter((x) => !selectedLocations.includes(x))
      .concat(
        selectedLocations.filter((x) => !previousSelectedLocations.includes(x))
      );

    this.props.onChangeDeliveryLocations(difference);
  };

  applyFilter = (filterType) => {
    this.toggleDropdown(filterType);
  };
  clearMainFilters = () => {
    if (this.state.tenantDropDown.length == "1" && this.props.userDetails.dashboardRole !== ADMIN) {
    } else {
      this.props.setClients([]); //clear selected client
    }

    this.setState({
      filterObject: {
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: [],
      },
      hideFilterHolderDiv: true,
      tempFilterObject: {
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: [],
      },
      tempFilterObject1: {
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: [],
      },
      selectedClients: [],
      selectedAssetsTypesLabel: [],
      tempSelectedAssetsTypesLabel: [],
    });
    if (this.state.tenantDropDown.length =="1" && this.props.userDetails.dashboardRole !== ADMIN) {
      this.props.setFilters({
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: this.props.app.filterObject.tenant,
        tenantId:this.props.app.filterObject.tenantId
      });
    } else {
      this.props.setFilters({
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: [],
        tenantId:[]
      });
    }
    if (this.state.tenantDropDown.length =="1" && this.props.userDetails.dashboardRole !== ADMIN) {
      this.getHumanMachineCounts({
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: this.props.app.filterObject.tenant,
      }, this.props);
    } else {
      this.getHumanMachineCounts({
        offering: [],
        market: [],
        deliveryLocation: [],
        assetType: [],
        tenant: [],
      }, this.props);
    }

  };

  clearFilter = (filterType) => {
    let filterObject = { ...this.state.filterObject };
    filterObject[filterType] = [];
    let selectedAssetsTypesLabel = [...this.state.selectedAssetsTypesLabel];
    if (filterType == "assetType") {
      selectedAssetsTypesLabel = [];
    }
    this.setState({
      selectedAssetsTypesLabel: selectedAssetsTypesLabel,
      filterObject: filterObject,
    });
  };

  searchFunction = (element) => {
    let input, filter, a, i;
    let modified_list = [];
    let unmodified_list = [];
    switch (element) {
      case "clintDropdown":
        {
          input = document.getElementById("myInput4");
          filter = input.value.toUpperCase();
          a = this.state.tenantDropDown;
          if (filter.length > 0) {
            for (i = 0; i < a.length; i++) {
              let txtValue = a[i].label;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                a.map((item) => {
                  if (txtValue == item.label) {
                    modified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              } else {
                a.map((item) => {
                  if (txtValue == item.label) {
                    unmodified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              }
            }
            this.setState({
              filteredList: modified_list,
              unfilteredList: unmodified_list,
            });
          } else {
            this.setState({ filteredList: a });
          }
        }
        break;
      case "assetTypeDropdown":
        {
          input = document.getElementById("myInput3");
          filter = input.value.toUpperCase();
          a = this.state.assetTypeDropDown;
          if (filter.length > 0) {
            for (i = 0; i < a.length; i++) {
              let txtValue = a[i].label;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                a.map((item) => {
                  if (txtValue == item.label) {
                    modified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              } else {
                a.map((item) => {
                  if (txtValue == item.label) {
                    unmodified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              }
            }
            this.setState({
              assetTypeFilteredList: modified_list,
              assetTypeUnfilteredList: unmodified_list,
            });
          } else {
            this.setState({ assetTypeFilteredList: a });
          }
        }
        break;
      case "deliveryLocDropdown":
        {
          input = document.getElementById("myInput2");
          filter = input.value.toUpperCase();
          a = this.state.deliveryLocationDropDown;
          if (filter.length > 0) {
            for (i = 0; i < a.length; i++) {
              let txtValue = a[i].label;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                a.map((item) => {
                  if (txtValue == item.label) {
                    modified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              } else {
                a.map((item) => {
                  if (txtValue == item.label) {
                    unmodified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              }
            }
            this.setState({
              deliveryLocationfilteredList: modified_list,
              deliveryLocationUnfilteredList: unmodified_list,
            });
          } else {
            this.setState({ deliveryLocationfilteredList: a });
          }
        }
        break;
      case "marketDropdown":
        {
          input = document.getElementById("myInput1");
          filter = input.value.toUpperCase();
          a = this.state.marketDropDown;
          if (filter.length > 0) {
            for (i = 0; i < a.length; i++) {
              let txtValue = a[i].label;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                a.map((item) => {
                  if (txtValue == item.label) {
                    modified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              } else {
                a.map((item) => {
                  if (txtValue == item.label) {
                    unmodified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              }
            }
            this.setState({
              marketFilteredList: modified_list,
              marketUnfilteredList: unmodified_list,
            });
          } else {
            this.setState({ marketFilteredList: a });
          }
        }
        break;
      case "offeringDropdown":
        {
          input = document.getElementById("myInput");
          filter = input.value.toUpperCase();
          a = this.state.offeringDropDown;
          if (filter.length > 0) {
            for (i = 0; i < a.length; i++) {
              let txtValue = a[i].label;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                a.map((item) => {
                  if (txtValue == item.label) {
                    modified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              } else {
                a.map((item) => {
                  if (txtValue == item.label) {
                    unmodified_list.push({
                      label: item.label,
                      value: item.value,
                    });
                  }
                });
              }
            }
            this.setState({
              offeringFilteredList: modified_list,
              offeringUnfilteredList: unmodified_list,
            });
          } else {
            this.setState({ offeringFilteredList: a });
          }
        }
        break;
      default:
        break;
    }
  };

  handleFilterBtnClick = () => {
    this.setState({
      hideFilterHolderDiv: !this.state.hideFilterHolderDiv,
      tempFilterObject: { ...this.state.filterObject },
      tempSelectedAssetsTypesLabel: [...this.state.selectedAssetsTypesLabel],
    });
  };
  confidentialMail = () => {
    let to = "confidentiality@accenture.com";
    let message = '';
    message = 'mailto:' + to;
    window.open(message);
  }

  handleCloseFilterClick = () => {
    this.setState({
      hideFilterHolderDiv: true,
      filterObject: { ...this.state.tempFilterObject },
      selectedAssetsTypesLabel: [...this.state.tempSelectedAssetsTypesLabel],
    });
  };
  removeSelectedFilter = (name, element) => {
    let data = { value: element };
    this.handleFilterSelect(name, data);
  };

  handleGetCurrentPage = () => {
    this.node.paginationContext.currPage = 1;
  };

  render() {
    //Construct dropdown options for filters
    let offeringDropDownList = [];
    if (this.state.offeringFilteredList.length > 0) {
      offeringDropDownList = this.state.offeringFilteredList.map((item) => (
        <div
          className="checkbox-dropdown-items"
          onClick={() => this.handleFilterSelect("offering", item)}
        >
          <input
            type="checkbox"
            checked={
              this.state.filterObject.offering.some((v) => v == item.value)
                ? true
                : false
            }
            className=""
            value={item.value}
            name="optradio"
          />
          <label className="drop-label-b9">{item.label}</label>
        </div>
      ));
    } else {
      if (this.state.offeringFilteredList.length <= 0) {
        offeringDropDownList = this.state.offeringDropDown.map((item) => (
          <div
            className="checkbox-dropdown-items"
            onClick={() => this.handleFilterSelect("offering", item)}
          >
            <input
              type="checkbox"
              checked={
                this.state.filterObject.offering.some((v) => v == item.value)
                  ? true
                  : false
              }
              className=""
              value={item.value}
              name="optradio"
            />
            <label className="drop-label-b9">{item.label}</label>
          </div>
        ));
      }
    }

    let marketDropDownList = [];
    if (this.state.marketFilteredList.length > 0) {
      marketDropDownList = this.state.marketFilteredList.map((item) => (
        <div
          className="checkbox-dropdown-items"
          onClick={() => this.handleFilterSelect("market", item)}
        >
          <input
            type="checkbox"
            checked={
              this.state.filterObject.market.some((v) => v == item.value)
                ? true
                : false
            }
            className=""
            value={item.value}
            name="optradio"
          />
          <label className="drop-label-b9">{item.label}</label>
        </div>
      ));
    } else {
      if (this.state.marketFilteredList.length <= 0) {
        marketDropDownList = this.state.marketDropDown.map((item) => (
          <div
            className="checkbox-dropdown-items"
            onClick={() => this.handleFilterSelect("market", item)}
          >
            <input
              type="checkbox"
              checked={
                this.state.filterObject.market.some((v) => v == item.value)
                  ? true
                  : false
              }
              className=""
              value={item.value}
              name="optradio"
            />
            <label className="drop-label-b9">{item.label}</label>
          </div>
        ));
      }
    }

    let deliveryLocationDropDownList = [];
    if (this.state.deliveryLocationfilteredList.length > 0) {
      deliveryLocationDropDownList =
        this.state.deliveryLocationfilteredList.map((item) => (
          <div
            className="checkbox-dropdown-items b9-filter-label"
            onClick={() => this.handleFilterSelect("deliveryLocation", item)}
          >
            <input
              type="checkbox"
              checked={
                this.state.filterObject.deliveryLocation.some(
                  (v) => v == item.value
                )
                  ? true
                  : false
              }
              className=""
              value={item.value}
              name="optradio"
            />
            <label className="drop-label-b9">{item.label}</label>
          </div>
        ));
    } else {
      if (this.state.deliveryLocationfilteredList.length <= 0) {
        deliveryLocationDropDownList = this.state.deliveryLocationDropDown.map(
          (item) => (
            <div
              className="checkbox-dropdown-items b9-filter-label"
              onClick={() => this.handleFilterSelect("deliveryLocation", item)}
            >
              <input
                type="checkbox"
                checked={
                  this.state.filterObject.deliveryLocation.some(
                    (v) => v == item.value
                  )
                    ? true
                    : false
                }
                className=""
                value={item.value}
                name="optradio"
              />
              <label className="drop-label-b9">{item.label}</label>
            </div>
          )
        );
      }
    }

    let assetTypeDropDownList = [];
    if (this.state.assetTypeFilteredList.length > 0) {
      assetTypeDropDownList = this.state.assetTypeFilteredList.map((item) => (
        <div
          className="checkbox-dropdown-items b9-filter-label"
          onClick={() => this.handleFilterSelect("assetType", item)}
        >
          <input
            type="checkbox"
            checked={
              this.state.filterObject.assetType.some((v) => v == item.value)
                ? true
                : false
            }
            className=""
            value={item.value}
            name="optradio"
          />
          <label className="drop-label-b9">{item.label}</label>
        </div>
      ));
    } else {
      if (this.state.assetTypeFilteredList.length <= 0) {
        assetTypeDropDownList = this.state.assetTypeDropDown.map((item) => (
          <div
            className="checkbox-dropdown-items b9-filter-label"
            onClick={() => this.handleFilterSelect("assetType", item)}
          >
            <input
              type="checkbox"
              checked={
                this.state.filterObject.assetType.some((v) => v == item.value)
                  ? true
                  : false
              }
              className=""
              value={item.value}
              name="optradio"
            />
            <label className="drop-label-b9">{item.label}</label>
          </div>
        ));
      }
    }

    let tenantDropDownList = [];
    if (this.state.filteredList.length > 0) {
      tenantDropDownList = this.state.filteredList.map((item) => (
        <div
          className="checkbox-dropdown-items b9-filter-label"
          onClick={() => this.handleFilterSelect("tenant", item)}
        >
          <input
            type="radio"
            checked={
              this.state.filterObject.tenant.some((v) => v == item.value)
                ? true
                : false
            }
            className=""
            value={item.value}
            name="optradio"
          />
          <label className="drop-label-b9">{item.label}</label>
        </div>
      ));
    } else {
      if (this.state.unfilteredList.length <= 0) {
        tenantDropDownList = this.state.tenantDropDown.map((item) => (
          <div
            className="checkbox-dropdown-items b9-filter-label"
            onClick={() => this.handleFilterSelect("tenant", item)}
          >
            <input
              type="radio"
              checked={
                this.state.filterObject.tenant.some((v) => v == item.value)
                  ? true
                  : false
              }
              className=""
              value={item.value}
              name="optradio"
            />
            <label className="drop-label-b9">{item.label}</label>
          </div>
        ));
      }
    }
    const columns = [
      {
        dataField: "offering",
        text: "Offering",
      },
      {
        dataField: "assetType",
        text: "Asset Type",
      },
      {
        dataField: "numberOfAssets",
        text: "Number of Assets",
      },
      {
        dataField: "assetGroup",
        text: "Asset Group",
      },
      {
        dataField: "assetCategory",
        text: "Asset Category",
      },
    ];
    let products = [];

    this.state.mapdatacountb9.map((item) => {
      products.push({
        offering: item.offering,
        numberOfAssets: item.count,
        assetGroup: item.assetGroupName,
        assetCategory: item.assetCategoryName,
        assetType: item.assetTypeName,
      });
    });
    const optionsPag = {
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "15",
          value: 15,
        },
      ],
      paginationSize: 1, // the pagination bar size.
      prePage: "<", // Previous page button text
      nextPage: ">",
      withFirstAndLast: false,
      hideSizePerPage: true,
    };

    return (
      <div>
        <Modal
          //backdrop={false}
          show={this.state.showPopUpMap}
          onHide={this.hideModal}
          className="mapb9Position"
          dialogClassName="modal-90w-mapb9 map-popupb9"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-3">
                <div className="image-panel">
                  {this.state.mapdatab9regiondetails &&
                    this.state.mapdatab9regiondetails.appIcon ? (
                    <img src={this.getImageSrc()} className="img-responsive" />
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    className="mapb9-dropdown"
                    onClick={(e) => this.toggleList(e)}
                  >
                    <div className="row">
                      <div className="col-2">
                        <img
                          width="30"
                          height="40"
                          src={require("../../Images/mapmarker.svg")}
                        />
                      </div>
                      <div className="col-10">
                        {this.state.selectedCountry
                          ? this.state.selectedCountry.regionName
                          : ""}
                        {this.state.isListOpen ? (
                          <FontAwesomeIcon
                            icon={faAngleUp}
                            className="ml-3 fa-width"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faAngleDown}
                            className="ml-3 fa-width"
                          />
                        )}
                      </div>
                    </div>
                  </button>
                  {this.state.isListOpen && (
                    <div role="list" className="dd-list">
                      {this.state.deliveryLocationDropDownb9.map((item) => (
                        <button
                          type="button"
                          className="dd-list-item"
                          key={item.id}
                          onClick={(e) => this.selectCountry(e, item)}
                        >
                          {item.regionName} {item.selected}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-9 pagination-b9">
                <BootstrapTable
                  wrapperClasses="mapb9table responsive"
                  bordered={false}
                  keyField="id"
                  data={products}
                  columns={columns}
                  ref={(n) => (this.node = n)}
                  pagination={paginationFactory(optionsPag)}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/*  HUMAN + MACHINE Start */}
        <section className="second-nav" style={{ position: "inherit" }}>
          <div className="container b9-bg-top" style={{ position: "relative" }}>

            <div className="row text-center">
              <div className="col-md-12 col-lg-12">
                <div>
                  {((this.state.bannerHidden &&
                    this.props.app.clients.length > 0) || (this.props.userDetails.dashboardRole != ADMIN && this.props.userDetails.tenant_Id != 'All' && this.props.app.clients.length > 0)) ? (
                    <div className="hmcc-d-flex box-margin text-white">
                      <div className="ml-4 hmcc-flex-child hmcc-client-selected">
                        <img height="35px" width="35px" src={
                          this.props.showImage(this.props.selectedClient) ? this.props.getImageSrc(this.props.selectedClient) : require("../../Images/hmcc-default-client.svg")} className="mr-2 rounded-circle" style={{ position: 'relative', top: '0.65rem' }} />
                        <span
                          className="selected-client-text"
                          style={{ color: "white" }}
                        >
                          {this.props.app.clients[0]}
                        </span>
                      </div>
                      <div
                        className=" hmcc-flex-child HMFilter-btn-holderb9"
                        onClick={() => this.handleFilterBtnClick()}
                        data-test="filter-icon"
                      >
                        <img
                          className=""
                          src={
                            this.props.app.filterObject.offering.length > 0 ||
                              this.props.app.filterObject.market.length > 0 ||
                              this.props.app.filterObject.deliveryLocation
                                .length > 0 ||
                              this.props.app.filterObject.assetType.length > 0
                              ? require("../../Images/color-filter.svg")
                              : require("../../Images/hmcc-filter.svg")
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className=" hmcc-d-flex box-margin text-white">
                      <div className="hmcc-flex-child">
                        <h6 className="hmcc-text media">
                          {" "}
                          <img
                            className="mr-2 mt-2"
                            src={require("../../Images/ClientServed_icon.svg")}
                          />{" "}
                          <span className="clientServed media-body">
                            {i18n.t(this.props.config[0].displayName)}
                          </span>
                        </h6>
                      </div>

                      <div className="hmcc-flex-child">
                        <h6>{i18n.t("Overall")}</h6>
                        <span className="info-text b9-number-font">
                          {this.state.clientsServed.overall}
                        </span>
                      </div>
                      <div className="hmcc-flex-child">
                        <h6>{i18n.t("Growth Markets")}</h6>
                        <span className="info-text b9-number-font">
                          {this.state.clientsServed.growthMarkets}
                        </span>
                      </div>
                      <div className="hmcc-flex-child">
                        <h6>{i18n.t("Europe")}</h6>
                        <span className="info-text b9-number-font">
                          {this.state.clientsServed.europe}
                        </span>
                      </div>
                      <div className="hmcc-flex-child">
                        <h6> {i18n.t("North America")}</h6>
                        <span className="info-text b9-number-font">
                          {this.state.clientsServed.northAmerica}
                        </span>
                      </div>
                      <div
                        className="hmcc-flex-child HMFilter-btn-holderb9"
                        onClick={() => this.handleFilterBtnClick()}
                        data-test="filter-icon"
                      >
                        <img
                          className=""
                          src={
                            this.props.app.filterObject.offering.length > 0 ||
                              this.props.app.filterObject.market.length > 0 ||
                              this.props.app.filterObject.deliveryLocation
                                .length > 0 ||
                              this.props.app.filterObject.assetType.length > 0
                              ? require("../../Images/color-filter.svg")
                              : require("../../Images/hmcc-filter.svg")
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container"></div>
          <div className="container hmcc-dd-section mapb9width">
            <div className="row">
              <div id="listclose" className="col-lg-12 mobile-view">
                <div>
                  <div
                    className="dropbtn-parent  filter-dropbtn-parent "
                    hidden={this.state.hideFilterHolderDiv}
                    data-test="filter-div"
                    style={{
                      // maxWidth: "73em",
                      minWidth: "86.25em",
                      height: this.state.openedDropdown || !this.props.selectClientHolderDiv ? "40vh" : "21vh",
                      justifyContent: "start",
                      overflow: "hidden",
                      zIndex: 1,
                    }}
                  >
                    <div className="dropdown clientDropDown" id="dropdown5">
                      <button
                        onClick={() => this.toggleDropdown("tenant")}
                        className={
                          this.state.openedDropdown == "tenant"
                            ? "hmcc-drop-btn map-filter map-filter-appliedFilters hmcc-button-heightb9 "
                            : "hmcc-drop-btn map-filter map-filter-appliedFilters default-btn-bg hmcc-button-heightb9"
                        }
                      >
                        {" "}
                        <div className="filter-placeholder-b9">{i18n.t("Client")} </div>
                        <img
                          className={
                            this.state.openedDropdown != "tenant" &&
                              this.state.filterObject.tenant.length > 0
                              ? "mt-2"
                              : ""
                          }
                          onClick={() => this.toggleDropdown("tenant")}
                          src={require("../../Images/drop-down-arrow.png")}
                        />{" "}
                        <div
                          hidden={
                            this.state.openedDropdown === "tenant" || !this.props.selectClientHolderDiv ||
                            !this.props.app.clients.length > 0
                          }
                          className="row m-0 mt-2"
                        >
                          <span className="hmcc-drop-selectedItems" style={{fontSize:"13px"}}>
                            {this.props.app.clients[0]}
                            {this.state.tenantDropDown.length > 1 ? <img
                              id="deleteIcon"
                              onClick={() => this.props.onSelectClient({})}
                              src={require("../../Images/deleteSelected.svg")}
                            />: ""}
                          </span>
                          {this.state.filterObject.tenant.length > 3
                            ? "+" + (this.state.filterObject.tenant.length - 3)
                            : null}
                        </div>
                      </button>
                      <ClientFilterB9
                        onSelectClient={this.props.onSelectClient}
                        selectClientHolderDiv={this.props.selectClientHolderDiv}
                        userDetails={this.props.userDetails}
                        toggleDropdown={this.toggleDropdown}
                        showImage={this.props.showImage}
                        getImageSrc={this.props.getImageSrc}
                        openedDropdown={this.state.openedDropdown}
                      />
                    </div>

                    <div className="dropdown" id="offering-dropdown">
                      <button
                        onClick={() => this.toggleDropdown("offering")}
                        className={
                          this.state.openedDropdown == "offering"
                            ? "hmcc-drop-btn map-filter map-filter-appliedFilters hmcc-button-heightb9 "
                            : "hmcc-drop-btn map-filter map-filter-appliedFilters default-btn-bg hmcc-button-heightb9"
                        }
                      >
                        {" "}
                        <div
                          className={
                            this.state.openedDropdown != "offering" &&
                              this.state.filterObject.offering.length > 0
                              ? "FilterType filter-placeholder-b9"
                              : "filter-placeholder-b9"
                          }
                        >
                          {this.props.app.filterObject.offering.length > 0
                            ? `${"Offering (" +
                            this.props.app.filterObject.offering.length +
                            ")"
                            }`
                            : i18n.t("Offering")}{" "}
                        </div>
                        <img
                          className={
                            this.state.openedDropdown != "offering" &&
                              this.state.filterObject.offering.length > 0
                              ? ""
                              : ""
                          }
                          src={require("../../Images/drop-down-arrow.png")}
                        />{" "}
                        <div
                          hidden={
                            this.state.openedDropdown === "offering" ||
                            !this.state.filterObject.offering.length > 0
                          }
                          className="row m-0 mt-2"
                        >
                          {this.state.filterObject.offering
                            .slice(0, 3)
                            .map((data, index) => {
                              return (
                                <span className="hmcc-drop-selectedItems"  style={{fontSize:"13px"}}>
                                  {data}
                                  <img
                                    id="deleteIcon"
                                    onClick={() =>
                                      this.removeSelectedFilter(
                                        "offering",
                                        data
                                      )
                                    }
                                    src={require("../../Images/deleteSelected.svg")}
                                  />
                                </span>
                              );
                            })}
                          {this.state.filterObject.offering.length > 3
                            ? "+" +
                            (this.state.filterObject.offering.length - 3)
                            : null}
                        </div>
                      </button>
                      <div
                        className="hmcc-dd-options dropdown-width"
                        hidden={
                          this.state.openedDropdown == "offering"
                            ? this.state.hideOfferingDiv
                            : true
                        }
                      >
                        <div
                          id="searchbox"
                          className="hmcc-searchbox"
                          hidden={
                            this.state.openedDropdown == "offering"
                              ? this.state.hideOfferingDiv
                              : true
                          }
                        >
                          {this.state.openedDropdown == "offering" ? (
                            <input
                              style={{fontSize:"1rem"}}
                              type="text"
                              className="checkbox-dropdown-items"
                              placeholder="Search.."
                              id="myInput"
                              onChange={() =>
                                this.searchFunction("offeringDropdown")
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          id="myDropdown"
                          className={
                            this.state.openedDropdown == "offering"
                              ? "dropdown-content show dropdown-content-B9 "
                              : "dropdown-content"
                          }
                        >
                          {offeringDropDownList}
                        </div>
                        <div
                          id="myDropdownFooter"
                          className="hmcc-dd-options-footer"
                        >
                          <button
                            style={{fontSize:"1rem"}}
                            className="hmcc-drop-btn clearBtn"
                            onClick={() => this.clearFilter("offering")}
                          >
                            Clear{" "}
                          </button>
                          <button
                            style={{fontSize:"1rem"}}
                            className="hmcc-drop-btn applyBtn"
                            onClick={() => this.applyFilter("offering")}
                          >
                            Apply{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Hidden this tab */}
                    {/* <div className="dropdown" id="market-dropdown">
                      <button
                        onClick={() => this.toggleDropdown("market")}
                        className={
                          this.state.openedDropdown == "market"
                            ? "hmcc-drop-btn map-filter map-filter-appliedFilters hmcc-button-heightb9"
                            : "hmcc-drop-btn map-filter map-filter-appliedFilters default-btn-bg hmcc-button-heightb9"
                        }
                      >
                        {" "}
                        <div
                          className={
                            this.state.openedDropdown != "market" &&
                              this.state.filterObject.market.length > 0
                              ? "FilterType filter-placeholder-b9"
                              : "filter-placeholder-b9"
                          }
                        >
                          {this.props.app.filterObject.market.length > 0
                            ? `${"Market (" +
                            this.props.app.filterObject.market.length +
                            ")"
                            }`
                            : i18n.t("Market")}{" "}
                        </div>
                        <img
                          className={
                            this.state.openedDropdown != "market" &&
                              this.state.filterObject.market.length > 0
                              ? ""
                              : ""
                          }
                          src={require("../../Images/drop-down-arrow.png")}
                        />{" "}
                        <div
                          hidden={
                            this.state.openedDropdown === "market" ||
                            !this.state.filterObject.market.length > 0
                          }
                          className="row m-0 mt-2"
                        >
                          {this.state.filterObject.market
                            .slice(0, 3)
                            .map((data, index) => {
                              return (
                                <span className="hmcc-drop-selectedItems"  style={{fontSize:"13px"}}>
                                  {data}
                                  <img
                                    id="deleteIcon"
                                    onClick={() =>
                                      this.removeSelectedFilter("market", data)
                                    }
                                    src={require("../../Images/deleteSelected.svg")}
                                  />
                                </span>
                              );
                            })}
                          {this.state.filterObject.market.length > 3
                            ? "+" + (this.state.filterObject.market.length - 3)
                            : null}
                        </div>
                      </button>
                      <div
                        className="hmcc-dd-options dropdown-width"
                        hidden={
                          this.state.openedDropdown == "market"
                            ? this.state.hideMarketDiv
                            : true
                        }
                      >
                        <div
                          id="searchbox1"
                          className="hmcc-searchbox"
                          hidden={
                            this.state.openedDropdown == "market"
                              ? this.state.hideMarketDiv
                              : true
                          }
                        >
                          {this.state.openedDropdown == "market" ? (
                            <input
                              style={{fontSize:"1rem"}}
                              type="text"
                              className="checkbox-dropdown-items"
                              placeholder="Search.."
                              id="myInput1"
                              onKeyUp={() =>
                                this.searchFunction("marketDropdown")
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          id="myDropdown1"
                          className={
                            this.state.openedDropdown == "market"
                              ? "dropdown-content dropdown-content-B9 show"
                              : "dropdown-content"
                          }
                        >
                          {marketDropDownList}
                        </div>
                        <div
                          id="myDropdownFooter"
                          className="hmcc-dd-options-footer"
                        >
                          <button
                            style={{fontSize:"1rem"}}
                            className="hmcc-drop-btn clearBtn"
                            onClick={() => this.clearFilter("market")}
                          >
                            Clear{" "}
                          </button>
                          <button
                            style={{fontSize:"1rem"}}
                            className="hmcc-drop-btn applyBtn"
                            onClick={() => this.applyFilter("market")}
                          >
                            Apply{" "}
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div className="dropdown" id="dropdown3">
                      <button
                        onClick={() => this.toggleDropdown("deliveryLocation")}
                        className={
                          this.state.openedDropdown == "deliveryLocation"
                            ? "hmcc-drop-btn map-filter map-filter-appliedFilters hmcc-button-heightb9 "
                            : "hmcc-drop-btn map-filter map-filter-appliedFilters default-btn-bg hmcc-button-heightb9"
                        }
                      >
                        {" "}
                        <div
                          className={
                            this.state.openedDropdown != "deliveryLocation" &&
                              this.state.filterObject.deliveryLocation.length > 0
                              ? "FilterType filter-placeholder-b9"
                              : "filter-placeholder-b9"
                          }
                        >
                          {this.props.app.filterObject.deliveryLocation.length >
                            0
                            ? `${"Delivery Loc. (" +
                            this.props.app.filterObject.deliveryLocation
                              .length +
                            ")"
                            }`
                            : i18n.t("Delivery Loc.")}{" "}
                        </div>
                        <img
                          className={
                            this.state.openedDropdown != "deliveryLocation" &&
                              this.state.filterObject.deliveryLocation.length > 0
                              ? ""
                              : ""
                          }
                          src={require("../../Images/drop-down-arrow.png")}
                        />{" "}
                        <div
                          hidden={
                            this.state.openedDropdown === "deliveryLocation" ||
                            !this.state.filterObject.deliveryLocation.length > 0
                          }
                          className="row m-0 mt-2"
                        >
                          {this.state.filterObject.deliveryLocation
                            .slice(0, 3)
                            .map((data, index) => {
                              return (
                                <span className="hmcc-drop-selectedItems"  style={{fontSize:"13px"}}>
                                  {data}
                                  <img
                                    id="deleteIcon"
                                    onClick={() =>
                                      this.removeSelectedFilter(
                                        "deliveryLocation",
                                        data
                                      )
                                    }
                                    src={require("../../Images/deleteSelected.svg")}
                                  />
                                </span>
                              );
                            })}
                          {this.state.filterObject.deliveryLocation.length > 3
                            ? "+" +
                            (this.state.filterObject.deliveryLocation.length -
                              3)
                            : null}
                        </div>
                      </button>
                      <div
                        className="hmcc-dd-options dropdown-width"
                        hidden={
                          this.state.openedDropdown == "deliveryLocation"
                            ? this.state.hideDeliveryLocDiv
                            : true
                        }
                      >
                        <div
                          id="searchbox2"
                          className="hmcc-searchbox"
                          hidden={
                            this.state.openedDropdown == "deliveryLocation"
                              ? this.state.hideDeliveryLocDiv
                              : true
                          }
                        >
                          {this.state.openedDropdown == "deliveryLocation" ? (
                            <input
                              style={{fontSize:"1rem"}}
                              type="text"
                              className="checkbox-dropdown-items"
                              placeholder="Search.."
                              id="myInput2"
                              onKeyUp={() =>
                                this.searchFunction("deliveryLocDropdown")
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          id="myDropdown2"
                          className={
                            this.state.openedDropdown == "deliveryLocation"
                              ? "dropdown-content dropdown-content-B9 show"
                              : "dropdown-content"
                          }
                        >
                          {deliveryLocationDropDownList}
                        </div>
                        <div
                          id="myDropdownFooter"
                          className="hmcc-dd-options-footer"
                        >
                          <button
                            style={{fontSize:"1rem"}}
                            className="hmcc-drop-btn clearBtn"
                            onClick={() => this.clearFilter("deliveryLocation")}
                          >
                            Clear{" "}
                          </button>
                          <button
                            style={{fontSize:"1rem"}}
                            className="hmcc-drop-btn applyBtn"
                            onClick={() => this.applyFilter("deliveryLocation")}
                          >
                            Apply{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  {/* Hidden this tab */}
                    {/* <div
                      className="dropdown"
                      id="dropdown4"
                      style={{ height: "66px" }}
                    >
                      <button
                        onClick={() => this.toggleDropdown("assetType")}
                        className={
                          this.state.openedDropdown == "assetType"
                            ? "hmcc-drop-btn map-filter map-filter-appliedFilters hmcc-button-heightb9"
                            : "hmcc-drop-btn map-filter map-filter-appliedFilters default-btn-bg hmcc-button-heightb9"
                        }
                      >
                        {" "}
                        <div
                          className={
                            this.state.openedDropdown != "assetType" &&
                              this.state.filterObject.assetType.length > 0
                              ? "FilterType filter-placeholder-b9"
                              : "filter-placeholder-b9"
                          }
                        >
                          {this.props.app.filterObject.assetType.length > 0
                            ? `${"Asset Type (" +
                            this.props.app.filterObject.assetType.length +
                            ")"
                            }`
                            : i18n.t("Asset Type")}{" "}
                        </div>
                        <img
                          className={
                            this.state.openedDropdown != "assetType" &&
                              this.state.filterObject.assetType.length > 0
                              ? ""
                              : ""
                          }
                          src={require("../../Images/drop-down-arrow.png")}
                        />{" "}
                        <div
                          hidden={
                            this.state.openedDropdown === "assetType" ||
                            !this.state.filterObject.assetType.length > 0
                          }
                          className="row m-0 mt-2"
                        >
                          {this.state.selectedAssetsTypesLabel
                            .slice(0, 3)
                            .map((data, index) => {
                              return (
                                <span className="hmcc-drop-selectedItems"  style={{fontSize:"13px"}}>
                                  {data.label}
                                  <img
                                    id="deleteIcon"
                                    onClick={() =>
                                      this.removeSelectedFilter(
                                        "assetType",
                                        data.value
                                      )
                                    }
                                    src={require("../../Images/deleteSelected.svg")}
                                  />
                                </span>
                              );
                            })}
                          {this.state.filterObject.assetType.length > 3
                            ? "+" +
                            (this.state.filterObject.assetType.length - 3)
                            : null}
                        </div>
                      </button>
                      <div
                        className="hmcc-dd-options dropdown-width"
                        hidden={
                          this.state.openedDropdown == "assetType"
                            ? this.state.hideAssetTypeDiv
                            : true
                        }
                      >
                        <div
                          id="searchbox3"
                          className="hmcc-searchbox"
                          hidden={
                            this.state.openedDropdown == "assetType"
                              ? this.state.hideAssetTypeDiv
                              : true
                          }
                        >
                          {this.state.openedDropdown == "assetType" ? (
                            <input
                              style={{fontSize:"1rem"}}
                              type="text"
                              className="checkbox-dropdown-items"
                              placeholder="Search.."
                              id="myInput3"
                              onKeyUp={() =>
                                this.searchFunction("assetTypeDropdown")
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          id="myDropdown3"
                          className={
                            this.state.openedDropdown == "assetType"
                              ? "dropdown-content dropdown-content-B9-Asset show"
                              : "dropdown-content"
                          }
                        >
                          {assetTypeDropDownList}
                        </div>
                        <div
                          id="myDropdownFooter"
                          className="hmcc-dd-options-footer"
                        >
                          <button
                            style={{fontSize:"1rem"}}
                            className="hmcc-drop-btn clearBtn"
                            onClick={() => this.clearFilter("assetType")}
                          >
                            Clear{" "}
                          </button>
                          <button
                            style={{fontSize:"1rem"}}
                            className="hmcc-drop-btn applyBtn"
                            onClick={() => this.applyFilter("assetType")}
                          >
                            Apply{" "}
                          </button>
                        </div>
                      </div>
                    </div> */}

                    <div className="dropdown" id="dropdown6">
                      <button
                        onClick="myFunctionclose()"
                        className="hmcc-drop-btn-close"
                      >
                        CLOSE
                      </button>
                    </div>
                    <div
                      className="row hmcc-new-drop-btn-row filterfooterb9 "
                      style={{ marginTop: "auto" }}
                    >
                      <div className="hmcc-new-drop-btn">
                        <button
                          style={{width:"7rem",fontSize:"1.25rem"}}
                          className="hmcc-drop-btn clearBtn"
                          onClick={() => this.clearMainFilters()}
                        >
                          Clear{" "}
                        </button>
                      </div>

                      <div className="hmcc-new-drop-btn">
                        <button
                          style={{width:"7rem",fontSize:"1.25rem"}}
                          className="hmcc-drop-btn applyBtn"
                          onClick={() => this.applyMainFilters()}
                        >
                          Apply{" "}
                        </button>
                      </div>
                    </div>
                    <div className="remove-extraspace"></div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Drop down selection End */}
          </div>
        </section>
        {/*  Map code Here */}
        <section
          className="world-map"
          style={{
            height: `calc(100vh - ${this.props.app.showMenu ? "200px" : "158px"
              })`,
          }}
        >
          <div className="worldMapSvg-b9">
            <ComposableMap fill="#3366ff" viewBox="0 40 1100 550" className='reactsimplemaps-b9'>
              <Geographies geography={geoUrl} >
                {({ geographies }) =>
                  geographies.map(geo => <Geography key={geo.rsmKey} geography={geo}


                    style={(geo.properties.NAME === 'India') || (geo.properties.NAME === 'Philippines') ? {
                      hover: {
                        fill: "",
                        outline: "none"
                      },
                      pressed: {
                        fill: "#E42",
                        outline: "none"
                      }
                    }
                      : {}}



                  />)
                }
              </Geographies>
              {this.state.markers.map(item => (
                <Marker key={item.name} coordinates={item.latLng}
                  // onClick={() => this.markerclick(item)} 
                  onMouseOver={() => this.mouseOver(item)} onMouseOut={() => this.mouseOut()}>

                  <g

                    fill="none"
                    stroke="#F9AA63"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform="translate(-12, -24)"

                  >
                    {item.latLng[0] === 77.350260 ? <image x="-5" y="-6" width="36" height="45" style={{ transform: "rotate(0deg) scaleX(2) scaleY(2.5)" }} href={window.Header.India} visibility={this.state.indiaMapVisible} /> : ''}
                    {item.latLng[0] === 125.323667 ? <image x="0" y="0" width="30" height="40" style={{ transform: "rotate(0deg) scaleX(2) scaleY(2.5)" }} href={window.Header.Philippines} visibility={this.state.PhilippinesMapVisible} /> : ''}
                    {item.latLng[0] === 15.255100 ? <image x="-10" y="-2" width="30" height="40" style={{ transform: "rotate(20deg) scaleX(2) scaleY(2.5)" }} href={window.Header.Europe} visibility={this.state.EuropeMapVisible} /> : ''}
                    {item.latLng[0] === -56.053494 ? <image x="-27" y="-18" width="50" height="61" style={{ transform: "rotate(-12deg) scaleX(3) scaleY(3.5)" }} href={window.Header.LATAM} visibility={this.state.LatinAmericaMapVisible} /> : ''}
                    {item.latLng[0] === -100.166666 ? <image x="-7" y="-13" width="30" height="40" style={{ transform: "rotate(15deg) scaleX(4) scaleY(5.5)" }} href={window.Header.NorthAmerica} visibility={this.state.NorthAmericaVisible} /> : ''}
                    {item.latLng[0] === 67.759113 ? <image x="0" y="0" width="30" height="40" style={{ transform: "rotate(0deg) scaleX(2) scaleY(2.5)" }} href={window.Header.OtherRegions} visibility={this.state.OtherRegionsVisible} /> : ''}
                    {item.latLng[0] === 112.990463 ? <image x="-7" y="-11" width="30" height="40" style={{ transform: "rotate(0deg) scaleX(2) scaleY(2.5)" }} href={window.Header.China} visibility={this.state.ChinaMapVisible} /> : ''}
                    <image x="0" y={item.name === 'China/Mainland' ? "-10" : "0"} width="30" height="40" href={require("../../Images/mapmarker.svg")} />
                  </g>
                  <rect className="rectMap" width="120px" height="52px" y={item.name === 'China/Mainland' ? '-80' : '-70'} x={-60} rx="8" ry="8" />
                  {(() => {
                    switch (item.name) {
                      case 'India':
                        return (
                          <text textAnchor="middle" y={-52} fontSize={'0.85rem'} style={{ fontFamily: 'GraphikMedium, GraphikMediumIE, Arial, sans-serif', fontWeight: '300' }} fill="white" >
                            {item.name}
                          </text>
                        )
                      case 'Europe':
                        return (
                          <text textAnchor="middle" y={-52} fontSize={'0.85rem'} style={{ fontFamily: 'GraphikMedium, GraphikMediumIE, Arial, sans-serif', fontWeight: '300' }} fill="white" >
                            {item.name}
                          </text>
                        )
                      case 'China/Mainland':
                        return (
                          <text textAnchor="middle" y={-60} fontSize={'0.85rem'} style={{ fontFamily: 'GraphikMedium, GraphikMediumIE, Arial, sans-serif', fontWeight: '300' }} fill="white" >
                            {i18n.t("China Mainland")}
                          </text>
                        )
                      case 'Latin America':
                        return (
                          <text textAnchor="middle" y={-52} fontSize={'0.85rem'} style={{ fontFamily: 'GraphikMedium, GraphikMediumIE, Arial, sans-serif', fontWeight: '300' }} fill="white" >
                            {i18n.t(item.name)}
                          </text>
                        )
                      case 'North America':
                        return (
                          <text textAnchor="middle" y={-52} fontSize={'0.85rem'} style={{ fontFamily: 'GraphikMedium, GraphikMediumIE, Arial, sans-serif', fontWeight: '300' }} fill="white" >
                            {i18n.t(item.name)}
                          </text>
                        )
                      case 'Other Regions':
                        return (
                          <text textAnchor="middle" y={-52} fontSize={'0.85rem'} style={{ fontFamily: 'GraphikMedium, GraphikMediumIE, Arial, sans-serif', fontWeight: '300' }} fill="white" >
                            {i18n.t(item.name)}
                          </text>
                        )
                      case 'Philippines':
                        return (
                          <text textAnchor="middle" y={-52} fontSize={'0.85rem'} style={{ fontFamily: 'GraphikMedium, GraphikMediumIE, Arial, sans-serif', fontWeight: '300' }} fill="white" >
                            {i18n.t(item.name)}
                          </text>
                        )
                      default:
                        return (
                          <div></div>
                        )
                    }

                  })()}

                  <image href={require("../../Images/Human.svg")} style={{ width: "13px", height: "13px" }} y={item.name === 'China/Mainland' ? '-48' : '-38'} x={-55} />
                  <image href={require("../../Images/Machine.svg")} style={{ minWidth: "13px", height: "13px" }} y={item.name === 'China/Mainland' ? '-48' : '-38'} />
                  {(() => {
                    var lbl = ''
                    switch (item.name) {
                      case 'India': {
                        lbl = <text textAnchor="middle" y={-26} fontSize={10} fill="white"><tspan x={-20} style={{ fill: '#ffcc33', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}>{item.HumanCount}</tspan> <tspan x={36} style={{ fill: 'rgb(126, 195, 68)', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}> {item.MachineCount}  </tspan> </text>
                      }
                        break;
                      case 'Europe': {
                        lbl = <text textAnchor="middle" y={-26} fontSize={10} fill="white"> <tspan x={-20} style={{ fill: '#ffcc33', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}>{item.HumanCount}</tspan> <tspan x={36} style={{ fill: 'rgb(126, 195, 68)', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}> {item.MachineCount}  </tspan> </text>
                      }
                        break;
                      case 'China/Mainland': {
                        lbl = <text textAnchor="middle" y={-36} fontSize={10} fill="white"><tspan x={-20} style={{ fill: '#ffcc33', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}>{item.HumanCount}</tspan> <tspan x={36} style={{ fill: 'rgb(126, 195, 68)', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}> {item.MachineCount}  </tspan> </text>
                      }
                        break;
                      case 'Latin America': {
                        lbl = <text textAnchor="middle" y={-26} fontSize={10} fill="white"><tspan x={-20} style={{ fill: '#ffcc33', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}>{item.HumanCount}</tspan> <tspan x={36} style={{ fill: 'rgb(126, 195, 68)', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}> {item.MachineCount}  </tspan> </text>
                      }
                        break;
                      case 'North America': {
                        lbl = <text textAnchor="middle" y={-26} fontSize={10} fill="white"><tspan x={-20} style={{ fill: '#ffcc33', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}>{item.HumanCount}</tspan> <tspan x={36} style={{ fill: 'rgb(126, 195, 68)', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}> {item.MachineCount}  </tspan> </text>
                      }
                        break;
                      case 'Other Regions': {
                        lbl = <text textAnchor="middle" y={-26} fontSize={10} fill="white"><tspan x={-20} style={{ fill: '#ffcc33', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}>{item.HumanCount}</tspan> <tspan x={36} style={{ fill: 'rgb(126, 195, 68)', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}> {item.MachineCount}  </tspan> </text>
                      }
                        break;
                      case 'Philippines': {
                        lbl = <text textAnchor="middle" y={-26} fontSize={10} fill="white"><tspan x={-20} style={{ fill: '#ffcc33', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}>{item.HumanCount}</tspan> <tspan x={36} style={{ fill: 'rgb(126, 195, 68)', fontSize: '0.75rem', fontFamily: 'GraphikSemibold, GraphikSemiboldIE, Arial, sans-serif', textShadow: '0 0 1px black, 0 0 1px black' }}> {item.MachineCount}  </tspan> </text>
                      }
                        break;
                      default:
                        break;
                    }
                    return lbl;

                  })()}

                </Marker>


              ))}
            </ComposableMap>
          </div>
        </section>
        {/*  Map code End Here */}

        {/*  HUMAN + MACHINE End */}
        {/* footer start */}
        <section className="main-hmcc-footer-b9">
          <section className="hmcc-footer">
            <div className="container1 transparent-bg-bottom-b9 ">
              <div className="row text-center text-white">
                <DateTimeClock langCode={this.props.app.languageCode} />
                <div className="col-sm-6 ">
                  <div className="center-bottonsB9">
                    <div className="toggle-positions">
                      <div className="toggletext">{i18n.t("Human")}</div>
                      <div className="toggle">
                        <input
                          checked={this.state.showHuman}
                          onClick={() => this.handleHumanMachineCount("human")}
                          type="checkbox"
                          className="check"
                        />
                        <div className="b switch switch-B9">
                          <div style={{ marginTop: '3px' }}>
                            <img height="16px" width="16px" src={require("../../Images/human_switch.svg")} />
                          </div>
                          <small className="couting-textB9">
                            {" "}
                            {this.state.humans["Human+Machine"]}
                          </small>
                        </div>
                        <div className="b track">
                          <p> </p>
                        </div>
                      </div>
                      <div className="toggletext toggletext2">
                        {i18n.t("Machine")}
                      </div>
                      <div className="toggle toggle2">
                        <input
                          checked={this.state.showMachine}
                          onClick={() =>
                            this.handleHumanMachineCount("machine")
                          }
                          type="checkbox"
                          className="check"
                        />
                        <div className="b switch switch2 switch2-B9">
                          <div style={{ marginTop: '3px' }}>
                            <img height="16px" width="16px" src={require("../../Images/bot_switch.svg")} />
                          </div>
                          <small className="couting-textB9">
                            {" "}
                            {this.state.machines["Human+Machine"]}
                          </small>
                        </div>
                        <div className="b track">
                          <p> </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 align-self-center pt-3">
                  <img style={{visibility:"hidden"}} width="135px" src={require("../../Images/logo.png")} alt="" />
                </div>
              </div>
            </div>
          </section>
        </section>

        {/*  Footer End */}

        <section className="hmcc-footer2 text-center">
          <h6 className="text-white">displayName</h6>
          <div className="d-flex">
            <div className="box1">
              <h4>
                <img src={require("../../Images/m-button-01.png")} />
                {this.state.humans["Human+Machine"]} <small>Off</small>{" "}
              </h4>
            </div>
            <div className="box2 box2-active">
              <h4>
                <img src={require("../../Images/m-button-02.png")} />
                {this.state.machines["Human+Machine"]} <small>On</small>{" "}
              </h4>
            </div>
          </div>
        </section>
        { this.props.showConfidentialModal &&  
               ((this.props.userDetails.featureIds 
                             ) || this.props.userDetails.dashboardRole == ADMIN) && (<Modal show={this.props.showConfPopup} onHide={this.handleConfidentialPopup} backdrop="static"
                    
                    dialogClassName="confidentialB9Modal"
                    backdropClassName="header-modal"
                    aria-labelledby="example-custom-modal-styling-title"
                    className="background-blur-Confidential"
                    >
                <Modal.Header
                // closeButton={true}
                className="dashboard-modal-header"
            ></Modal.Header>
                <Modal.Body>
                <div className="row mb-3">
                            <div className="col-sm-12 graphikmedium confidentiallabel">{i18n.t("Confidential Information - Reminder")}</div>
                            
                </div>
                <div className="announcementCntnrPopupDiv confidentialB9Popup" style={{maxHeight:"24.5vh"}}>
                <div className={"mt-20"}>
                <span style={{ opacity: 0.7, marginRight: "3px" }}> You are using an Accenture proprietary application (“SynOps Hub - Command Centre”).
                  You understand that this application may contain</span>
                ACCENTURE, CLIENT, OR VENDOR / PARTNER CONFIDENTIAL INFORMATION.
                <span style={{ opacity: 0.7, margin: "3px" }}> You are obligated to adhere to applicable confidentiality obligations and
                  Accenture's policies, including Policy 69 Confidentiality,
                  when using the application and information in the application.
                Information in the application can only be shared with those
                  authorized to receive it.If you are downloading/exporting
                  Confidential Information to another file or report,
                  you must label that file or report as Accenture Confidential.
                  If you have any questions, please email your question to</span>
                <span style={{cursor:"pointer",color:"deepskyblue"}} onClick={() => this.confidentialMail()} > confidentiality@accenture.com </span>
                <span style={{ opacity: 0.7, marginRight: "3px" }}> or contact your Leadership. Thank you!</span>
              </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{background:"rgb(33,53,110)", borderTop:"none",borderRadius:"0px 0px 16px 16px",height:"56px"}}><button style={{borderStyle:"none",background:"rgb(1,142,255)",borderRadius:"16px",cursor:"pointer"}} className="hmcc-drop-btn" onClick={this.handleConfidentialPopup}>I understand</button></Modal.Footer>
                </Modal>)}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.appReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenuDisplay: (boolean) => {
      dispatch(toggleMenuDisplay(boolean));
    },
    setFilters: (obj) => {
      dispatch(setFilters(obj));
    },
    setClients: (arr) => {
      dispatch(setClients(arr));
    },
    setClientOptions: (arr) => {
      dispatch(setClientOptions(arr));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapB9);
