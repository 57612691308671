export const cssDashData = [
    {
        dashboardId: '6188bb7e622ce30035b79d61',
        dashboardName: 'CSS Dashboard',
        widgets: [
            { index: 1, widgetId: '6188bb7e622ce30035b79d63', },
            { index: 2, widgetId: '6188bb7e622ce30035b79d64', },
            { index: 3, widgetId: '6188bb7e622ce30035b79d66', },
            { index: 4, widgetId: '6188bb7e622ce30035b79d65', },
            { index: 5, widgetId: '6188bb7e622ce30035b79d67', },
            { index: 6, widgetId: '6188bb7e622ce30035b79d62', },

        ],
    }]

export const otherDashTickets = [
    {
        dashboardId: '61bc56f5e404220036c7187c',
        dashboardName: 'Other Tickets',
        widgets: [
            { index: 1, widgetId: '61bc574ce404220036c71894', },
            { index: 2, widgetId: '61bc5751e404220036c71896', },
            { index: 3, widgetId: '61bc59b0e404220036c718b7', },
            { index: 4, widgetId: '61c1b94f55a5390036c6b008', }
        ],
    }
]

export const otherDash = [
    {
        dashboardId: '61bc56f5e404220036c7187c',
        dashboardName: 'Other Tickets',
        widgets: [
            { index: 1, widgetId: '61bc5af3e404220036c718e4', },
            { index: 2, widgetId: '61bc5b75e404220036c718fc', },
            { index: 3, widgetId: '61bc5d98e404220036c719d2', },
            { index: 4, widgetId: '61bc5aeae404220036c718e1', },
            { index: 5, widgetId: '61bc5b89e404220036c71907', },
            { index: 6, widgetId: '61bc5d9be404220036c719d4', },
            { index: 7, widgetId: '61c42cdd55a5390036c6d7b9' }

        ],
    }
]
export const SurveyDash = [
    {
        dashboardId: '5ec25cc4f6b3f4358483f711',
        dashboardName: 'Survey',
        widgets: [
            { index: 1, widgetId: '5ec25cc4f6b3f4358483f716', },
            { index: 2, widgetId: '5ec25cc4f6b3f4358483f71d', },
            { index: 3, widgetId: '5ec25cc4f6b3f4358483f71b', },
            { index: 4, widgetId: '5ec25cc4f6b3f4358483f71a', },
            { index: 5, widgetId: '5ec25cc4f6b3f4358483f712', },
            { index: 6, widgetId: '5ec25cc4f6b3f4358483f718', },
            { index: 7, widgetId: '5ec25cc4f6b3f4358483f715' },
            { index: 8, widgetId: '5ec25cc4f6b3f4358483f71c', },
            { index: 9, widgetId: '5ec25cc4f6b3f4358483f713', },
            { index: 10, widgetId: '5ec25cc4f6b3f4358483f717', },
            { index: 11, widgetId: '5ec25cc4f6b3f4358483f719', },
            { index: 12, widgetId: '5ec25cc4f6b3f4358483f714' }

        ],
    }
]

export const allGphExclude = [
]
export const iwGphExclude = [
            { index: 1, dashboardId: '60ec3f52b1929e002d1cb062', },//gph stats
            { index: 2, dashboardId: '606debd2ecbae9002d953c9d', }, //gph 4.0
]
export const gph4Exclude = [
            { index: 1, dashboardId: '60ec3f52b1929e002d1cb062', },//gph stats
            { index: 2, dashboardId: '606debd2ecbae9002d953c9d', },//gph 4.0 button - find 61e6ac4169f6ec003698d2fb and replace with this id later in code
            // { index: 3, dashboardId: '61f2795b69f6ec00369a6452', }, //iwgph overview on button
        ]
export const tenantIdDashboards = [
    //GPH dashboards
    { index: 1, dashboardId: '61e6ac4169f6ec003698d2fb', },//iwgph
    { index: 2, dashboardId: '60ec3f52b1929e002d1cb062', }, //gph stats
    { index: 3, dashboardId: '606c97d5ecbae9002d951b71', },//overview
    { index: 4, dashboardId: '606debd2ecbae9002d953c9d', },//gph 4.0
    { index: 5, dashboardId: '61f2795b69f6ec00369a6452', },//iwgph overview on button
]
// commented IWGPH widgets for now
export const iwGPHwidgets = [
    { index: 1, dashboardId: '63c50636a1a8a90036477de8', },
    { index: 2, dashboardId: '63c5063ba1a8a90036477dea', },
    // { index: 1, dashboardId: '61fd0402012a0b0036125f8a', },
    // { index: 2, dashboardId: '61fd0383012a0b0036125f71', },
    // { index: 3, dashboardId: '61fd0388012a0b0036125f73', },
    // { index: 4, dashboardId: '61fd038e012a0b0036125f81', },
    // { index: 5, dashboardId: '61fd0396012a0b0036125f85', },
]
export const gph4Widgets = [
    { index: 1, dashboardId: '63c5063ea1a8a90036477dec', },
    { index: 2, dashboardId: '63c50642a1a8a90036477dee', },
    // { index: 1, dashboardId: '606c97d5ecbae9002d951b76', },
    // { index: 2, dashboardId: '606c97d5ecbae9002d951b75', },
    // { index: 3, dashboardId: '606c97d5ecbae9002d951b8d', },
    // { index: 4, dashboardId: '606c97d5ecbae9002d951b72', },
    // { index: 5, dashboardId: '606c97d5ecbae9002d951b7e', },
]

export const drillToDashWithParentFilter = [
    //Drill to dahboards with parent filter
    { index: 1, dashboardId: '5fad0bb84be9354e24e578a0', },
    { index: 2, dashboardId: '5fad0cb75fd2f53f3861db2d', }, 
    { index: 3, dashboardId: '5fad0b8a4be9354e24e57676', },
    { index: 4, dashboardId: '60c8b6edb1929e002d181bf5', },
    { index: 5, dashboardId: '60c8b6edb1929e002d181bf6', },
    { index: 6, dashboardId: '60c8b6edb1929e002d181bf7', },
    { index: 7, dashboardId: '60c8b6edb1929e002d181bf8', }, 
    { index: 8, dashboardId: '60c8b6edb1929e002d181bf9', },
   // { index: 9, dashboardId: '5edde5321b0bea07501ae32a', },
   // { index: 10, dashboardId: '5edde5421b0bea07501ae357', },
    { index: 9, dashboardId: '641971a18f18790037d31e83', },
    { index: 10, dashboardId: '5eec72c45ae741084c12af4c', },
    { index: 11, dashboardId: '5eec75cb5ae741084c12b2b0', },
    { index: 12, dashboardId: '5eec74cf5ae741084c12b248', },
    { index: 13, dashboardId: '5eec75365ae741084c12b295', },
    { index: 14, dashboardId: '5eec74905ae741084c12b219', },
    { index: 15, dashboardId: '5eec74295ae741084c12b1ea', },
    { index: 16, dashboardId: '5eec75035ae741084c12b278', },
    { index: 17, dashboardId: '602d4298f4f89c2bdcbf4998', },
]

export const tenantIdWithClient = [
    //Drill to dahboards with parent filter
    { index: 1, dashboardId: '5fad0bb84be9354e24e578a0', },
    { index: 2, dashboardId: '5fad0cb75fd2f53f3861db2d', }, 
    { index: 3, dashboardId: '5fad0b8a4be9354e24e57676', },
    { index: 4, dashboardId: '60c8b6edb1929e002d181bf5', },
    { index: 5, dashboardId: '60c8b6edb1929e002d181bf6', },
    { index: 6, dashboardId: '60c8b6edb1929e002d181bf7', },
    { index: 7, dashboardId: '60c8b6edb1929e002d181bf8', }, 
    { index: 8, dashboardId: '60c8b6edb1929e002d181bf9', },
    // { index: 9, dashboardId: '5edde5321b0bea07501ae32a', },
    // { index: 10, dashboardId: '5edde5421b0bea07501ae357', },
    { index: 11, dashboardId: '641971a18f18790037d31e83', },
    { index: 12, dashboardId: '5eec72c45ae741084c12af4c', },
    { index: 13, dashboardId: '5eec75cb5ae741084c12b2b0', },
    { index: 14, dashboardId: '5eec74cf5ae741084c12b248', },
    { index: 15, dashboardId: '5eec75365ae741084c12b295', },
    { index: 16, dashboardId: '5eec74905ae741084c12b219', },
    { index: 17, dashboardId: '5eec74295ae741084c12b1ea', },
    { index: 18, dashboardId: '5eec75035ae741084c12b278', },
    { index: 19, dashboardId: '602d4298f4f89c2bdcbf4998', },
]