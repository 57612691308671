import { loginRequest } from '../services/authProvider'
import { ENVIRONMENT, BASE_URL } from "../StringConstants";

import { msalInstance } from '../index'

import { InteractionRequiredAuthError } from '@azure/msal-browser'


export const get = (url, body,isEmail) => {
  return callAPI(url, 'GET', body,isEmail)
}

export const post = (url, body,isEmail) => {
  return callAPI(url, 'POST', body,isEmail)
}

const callAPI = async (url, req, body,isEmail) => {
  if (ENVIRONMENT !== 'local') {
  const account = msalInstance.getAllAccounts()[0];
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  //always call the acquiretokensilent first before calling any API
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  }).catch(err => {
    //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
    //always check if the error is something that requires auth login interaction 
    if (err instanceof InteractionRequiredAuthError) {
      return msalInstance.acquireTokenPopup({
        ...loginRequest,
        account: account
      }).catch(err => {
     //   console.log('Error getting popup token: ' + JSON.stringify(err))
      });
    }
  });

 // console.log(response.accessToken, 'token');
 localStorage.setItem("access_token", response.accessToken);
  
  if(isEmail){
    const rawResponse = await fetch(BASE_URL + url, {
      method: req,
      headers: {
        Authorization: "Bearer " + response.accessToken,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Accept': 'application/json'
										   
							
      },
      body: body,
    });
     return rawResponse.json();
  }
   else{
    const rawResponse = await fetch(BASE_URL + url, {
      method: req,
      headers: {
        Authorization: "Bearer " + response.accessToken,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Pragma': 'no-cache'
      },
      body: JSON.stringify(body),
    });
   
    return await rawResponse.json();
  }
  }
  else {
    if(isEmail){
      const rawResponse = await fetch(BASE_URL + url, {
        method: req,
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Accept': 'application/json',
          'Pragma': 'no-cache'
    
        },
        
        body: body
      })
      return rawResponse;
    }
    else{
      const rawResponse = await fetch(BASE_URL + url, {
        method: req,
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Pragma': 'no-cache'
        },
        body: JSON.stringify(body)
      })
    
   
    return await rawResponse.json();
    }
  }

}
						  
	
		   


